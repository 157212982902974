import * as yup from "yup";
import { TFunction } from "react-i18next";

export type InformationInputs = {
  name: string;
  sku: string;
  description: string;
  other_description: string;
  dimension: string;
  weight: number;
  country_id: any;
  category_id: any;
  sub_category_id: any;
  unit_id: any;
  material_id: any;
  color_id: any;
  brand: string;
  has_sub_category: boolean;
};

export const informationBaseModel: InformationInputs = {
  name: "",
  sku: "",
  description: "",
  other_description: "",
  dimension: "",
  unit_id: null,
  weight: 0,
  category_id: null,
  sub_category_id: null,
  material_id: null,
  country_id: null,
  color_id: null,
  brand: "",
  has_sub_category: false,
};

export const modelValidationSchema = (t: TFunction) => {
  let shapes = {
    has_sub_category: yup.boolean(),
    name: yup.string().required().label(t("form.name.label")),
    sku: yup.string().required().label(t("form.sku.label")),
    price: yup.string().required().label(t("form.price.label")),
    category_id: yup.mixed().required().label(t("form.category.label")),
    sub_category_id: yup
      .mixed()
      .when("has_sub_category", {
        is: (hasCategory: boolean) => hasCategory,
        then: yup.mixed().required(),
        otherwise: yup.mixed(),
      })
      .label(t("form.subCategory.label")),
    unit_id: yup.mixed().required().label(t("form.unit.label")),
    material_ids: yup.array().min(1).required().label(t("form.material.label")),
    country_id: yup.mixed().required().label(t("form.madeIn.label")),
    color_ids: yup.array().min(1).required().label(t("form.color.label")),
    brand: yup.string().required().label(t("form.brand.label")),
  };

  return yup.object().shape(shapes);
};
