import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

export interface ITitleHeader {
  title: string;
  withBackUrl: boolean;
  component?: any;
}

const TitleHeader: React.FC<ITitleHeader> = ({
  title,
  withBackUrl = false,
  component,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={clsx({
        "page-header mb-4": true,
        "type-form": withBackUrl,
        "w-100": withBackUrl && component,
      })}
    >
      {withBackUrl && (
        <div className={"icon-wrapper icon-back"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>
      )}

      <h4 className={"title"}>{title}</h4>

      {component && component}
    </div>
  );
};

export default TitleHeader;
