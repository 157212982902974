import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    type: yup.string().required().label(t("form.type.label")),
    name: yup.string().required().label(t("form.name.label")),
    parent_id: yup
      .mixed()
      .when("type", {
        is: (type: string) => type === "Sub Category",
        then: yup.mixed().required(),
        otherwise: yup.mixed(),
      })
      .label(t("form.category.label")),
  };

  return yup.object().shape(shapes);
};
