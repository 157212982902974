import { CSSProperties, FC } from "react";
import clsx from "clsx";

interface ICardContent {
  className?: string;
  styles?: CSSProperties;
}

const CardContent: FC<ICardContent> = ({
  children,
  styles,
  className = "",
}) => {
  return (
    <div className={"card-paper"} style={styles}>
      <div className={clsx(["card-content", className])}>{children}</div>
    </div>
  );
};

export default CardContent;
