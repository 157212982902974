/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { validationSchema } from "./validation";
import {
  formatFormData,
  handleSaveResponseError,
  handleSaveResponseSuccess,
} from "../../../../../vodea/utilities/helpers/form";
import ColorRepository from "../../../../../repositories/ColorRepository";
import { showToast } from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { AxiosError, AxiosResponse } from "axios";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { IAddress } from "../../../../../interfaces/Address";
import { IResource } from "../../../../../interfaces/Resource";
import AddressRepository from "../../../../../repositories/AddressRepository";

const defaultValues = {
  name: "",
};

const SettingAddressForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<IAddress>(defaultValues);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await AddressRepository.show(id)
      .then((response: AxiosResponse<IResource<IAddress>>) => {
        const { data: responseData } = response.data;
        if (isMounted.current) {
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError)
          showToast(error?.response?.data.message, "error");
      });
  }, []);

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    reset: informationReset,
    setError: informationSetError,
  } = useForm<IAddress>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id, loadData]),
  });

  useEffect(() => {
    informationReset(data);
  }, [data]);

  const onSubmit = async (data: IAddress) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    if (id) {
      await AddressRepository.update(id, formData)
        .then((response: AxiosResponse<IResource<IAddress>>) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/address");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    } else {
      await AddressRepository.create(formData)
        .then((response: AxiosResponse<IResource<IAddress>>) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/address");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    }
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("setting.section.masterData.address.edit.metaTitle")
            : t("setting.section.masterData.address.create.metaTitle")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/setting/address")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>
          {id
            ? t("setting.section.masterData.address.edit.title")
            : t("setting.section.masterData.address.create.title")}
        </h4>
      </div>
      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>{t("form.name.label")}</div>
                    <textarea
                      className="form-control"
                      rows={4}
                      {...informationRegister("name")}
                    />
                    {informationErrors.name && (
                      <span className={"text-danger"}>
                        {informationErrors.name?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createAddressWithMark")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <div className={"button-group"}>
                        <div
                          className={"btn btn-light btn-sm"}
                          onClick={() => navigate("/setting/address")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingAddressForm;
