import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    username: yup.string().required().label(t("form.email.label")),
    password: yup.string().required().label(t("form.password.label")),
  };

  return yup.object().shape(shapes);
};
