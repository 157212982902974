/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useState } from "@hookstate/core/dist";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import moment from "moment";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import EditIcon from "../../../../assets/icon/icons8-edit.svg";
import { ReactComponent as VuiStockIcon } from "../../../../assets/icon/icons8-product.svg";
import VuiCardIcon from "../../../../vodea/@vodea-ui/components/VuiCardIcon";
import BootstrapTable from "react-bootstrap-table-next";
import OrderRepository from "../../../../repositories/OrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import _ from "lodash";
import { ProductTrackingInterface } from "./interface";
import ModalImage from "../../../../components/ModalImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useTranslation } from "react-i18next";

const productTrackingDefaultValues: ProductTrackingInterface = {
  id: 0,
  sku: "",
  name: "",
  description: "",
  photo_id: 0,
  photo_url: "",
  total_order: 0,
  total_finish: 0,
  total_process: 0,
  total_delivery: 0,
  total_available: 0,
  tracking: [],
};

const OrderDetail: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = React.useState<boolean>(false);
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const [imageURL, setImageURL] = React.useState<string>("");
  const [productTrackingData, setProductTrackingData] =
    React.useState<ProductTrackingInterface>(productTrackingDefaultValues);
  const data = useState<any>({});

  const handleClose = () => {
    setShow(false);
    setProductTrackingData(productTrackingDefaultValues);
  };

  const handleShow = (idProduct: number | string) => {
    OrderRepository.showTracking(id, idProduct).then(
      (response: AxiosResponse) => {
        const data = response.data.data;
        setProductTrackingData(data);
      }
    );
    setShow(true);
  };

  const tableColumns = [
    {
      dataField: "date",
      text: t("table.header.date"),
      formatter: (cell: any) => {
        return moment(cell).format("DD MMM YYYY");
      },
    },
    {
      dataField: "number",
      text: t("table.header.transactionNumber"),
      formatter: (cell: any, row: any) => {
        const statusLowerCase = row?.status?.toLowerCase();
        return (
          <div className={"display-flex align-items-center"}>
            <div className={"mr-2"}>{cell}</div>
            {row?.status ? (
              <div
                className={
                  statusLowerCase === "sampai tujuan"
                    ? "table-btn-status success"
                    : statusLowerCase === "dalam pengiriman"
                    ? "table-btn-status processing"
                    : "table-btn-status failed"
                }
              >
                {row?.status}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      dataField: "quantity",
      text: t("table.header.qty"),
      formatter: (cell: any) => {
        return cell > 0 ? (
          <NumberFormat
            className={"text-plus-stock"}
            prefix={"+"}
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        ) : cell == 0 ? (
          <NumberFormat
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        ) : (
          <NumberFormat
            className={"text-minus-stock"}
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        );
      },
    },
  ];

  const loadData = useCallback(async () => {
    await OrderRepository.show(id, {
      with: [
        "brand.logo",
        "orderDetails.photo",
        "orderDetails.unit",
        "orderDetails.product.unit",
        "marketingName",
      ],
    })
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          data.set(responseData);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  }, [false]);

  useMemo(() => {
    if (id) {
      loadData().then();
    }
  }, [id]);

  const openImage = (url: string) => {
    setImageURL(url);
    setShowImage(true);
  };

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("detailOrder.metaTitle")}</title>
      </Helmet>

      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header>
          <Offcanvas.Title>
            <div className={"icon-wrapper icon-back"} onClick={handleClose}>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={"icon icon-prefix"}
              />
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <div id={"stock-canvas-wrapper"}>
          <Row className={"stock-canvas-body"}>
            <Col md={7} xs={12}>
              <div className={"display-flex"}>
                {productTrackingData.photo_url && (
                  <img
                    className="image-hover-cursor"
                    src={productTrackingData.photo_url}
                    alt={productTrackingData.photo_url}
                    onClick={() => openImage(productTrackingData.photo_url)}
                  />
                )}

                <div>
                  <p className={"title"}>{productTrackingData.sku}</p>
                  <h4 className={"product-name"}>{productTrackingData.name}</h4>
                  <p className={"subtitle"}>
                    {productTrackingData.description}
                  </p>
                </div>
              </div>
            </Col>
            <Col md={5} xs={12}>
              <div className={"stock-canvas-items-wrapper"}>
                <div className={"stock-canvas-items"}>
                  <div className={"stock-canvas-title"}>
                    {t("detailOrder.orderQty")}
                  </div>
                  <div className={"stock-canvas-title"}>
                    {t("detailOrder.inProcess")}
                  </div>
                  <div className={"stock-canvas-title"}>
                    {t("detailOrder.completed")}
                  </div>
                  <div className={"stock-canvas-title"}>
                    {t("detailOrder.sent")}
                  </div>
                </div>
                <div className={"stock-canvas-items"}>
                  <VuiNumberFormat
                    data={productTrackingData.total_order}
                    value={productTrackingData.total_order}
                  />
                  <VuiNumberFormat
                    data={productTrackingData.total_process}
                    value={productTrackingData.total_process}
                  />
                  <VuiNumberFormat
                    data={productTrackingData.total_finish}
                    value={productTrackingData.total_finish}
                  />
                  <VuiNumberFormat
                    data={productTrackingData.total_delivery}
                    value={productTrackingData.total_delivery}
                  />
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} xs={12} />
            <Col md={6} lg={6} xs={12}>
              <div className={"stock-canvas-available"}>
                <div className={"stock-canvas-available-title"}>
                  {t("detailOrder.availableStock")}
                </div>
                <VuiNumberFormat
                  className={"stock-canvas-available-title"}
                  data={productTrackingData.total_available}
                  value={productTrackingData.total_available}
                />
              </div>
            </Col>
          </Row>
          <div className={"mt-4"}>
            <BootstrapTable
              headerClasses={"table-header-custom for-stock-detail"}
              wrapperClasses={"table-responsive"}
              keyField={"id"}
              bordered={false}
              data={productTrackingData.tracking}
              columns={tableColumns}
            />
          </div>
        </div>
      </Offcanvas>

      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/order")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id ? t("detailOrder.order") : t("detailOrder.createDelivery")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{_.get(data.get(), "number", "-")}</span>}
        </h4>
        {id && (
          <div
            className={`table-btn-status ${
              _.get(data.get(), "status") === "Dalam Proses"
                ? "failed"
                : _.get(data.get(), "status") === "Selesai"
                ? "success"
                : "processing"
            }`}
          >
            {_.get(data.get(), "status", "-")}
          </div>
        )}

        {user.permissions.includes("order.update") && (
          <div className={"ml-auto d-flex align-items-center"}>
            <Link to={"edit"}>
              <VuiCardIcon icon={EditIcon} primary={false} />
            </Link>
          </div>
        )}
      </div>

      <div className="page-content">
        <div className={"card-paper"}>
          <div className={"card-content"}>
            <div className={"default-filter-section align-items-unset"}>
              <div className={"filter-item type-input-select mb-unset"}>
                <div className={"form-group"}>
                  <div className={"form-label"}>{t("form.brand.label")}</div>
                  <div className={"display-flex align-items-center"}>
                    {_.get(data.get(), "brand.logo.url", null) ? (
                      <img
                        className={"image-wrap image-hover-cursor mr-2"}
                        onClick={() =>
                          openImage(_.get(data.get(), "brand.logo.url"))
                        }
                        src={_.get(data.get(), "brand.logo.url")}
                        alt="eeee"
                      />
                    ) : null}
                    <strong>{_.get(data.get(), "brand.name", "-")}</strong>
                  </div>
                </div>
              </div>

              <div className={"filter-item type-half-full mb-unset"}>
                <Row>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.purchaseOrderNumber.label")}
                      </label>
                      <div>
                        <strong>{_.get(data.get(), "number", "-")}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.purchaseOrderDate.label")}
                      </label>
                      <div>
                        <strong>
                          {moment(_.get(data.get(), "date")).format(
                            "DD MMMM YYYY"
                          )}
                        </strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group mb-unset"}>
                      <label className={"form-label"}>
                        {t("form.marketing.label")}
                      </label>
                      <div>
                        <strong>
                          {_.get(data.get(), "marketing_name.name", "-")}
                        </strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className={"card-paper mt-4"}>
          <div id={"table-custom-wrapper"}>
            <table className={"table table-custom"}>
              <thead>
                <tr>
                  <th>{t("form.img.label")}</th>
                  <th>{t("form.sku.label")}</th>
                  <th>{t("form.product.label")}</th>
                  <th>{t("form.description.label")}</th>
                  <th>{t("form.qty.label")}</th>
                  <th>{t("form.unit.label")}</th>
                  <th>{t("form.price.label")}</th>
                  <th>{t("form.total.label")}</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {_.get(data.get(), "order_details", []).map(
                  (item: any, index: number) => {
                    return (
                      <tr key={index}>
                        <td>
                          {_.get(item, "photo.url", null) && (
                            <img
                              onClick={() =>
                                openImage(_.get(item, "photo.url"))
                              }
                              className={
                                "table-product-image image-hover-cursor"
                              }
                              src={_.get(item, "photo.url", null)}
                              alt={_.get(item, "photo.url", null)}
                            />
                          )}
                        </td>
                        <td>{_.get(item, "product.sku", "-")}</td>
                        <td>{_.get(item, "product.name", "-")}</td>
                        <td>{_.get(item, "description", "-")}</td>
                        <td>
                          <VuiNumberFormat
                            hasPrefix={false}
                            data={_.get(item, "quantity", 0)}
                            value={_.get(item, "quantity", 0)}
                          />
                        </td>
                        <td>{_.get(item, "product.unit.name", "-")}</td>
                        <td>
                          <VuiNumberFormat
                            prefix={"Rp"}
                            data={_.get(item, "price", 0)}
                            value={_.get(item, "price", 0)}
                          />
                        </td>
                        <td>
                          <VuiNumberFormat
                            prefix={"Rp"}
                            data={
                              _.get(item, "quantity", 0) *
                              _.get(item, "price", 0)
                            }
                            value={
                              _.get(item, "quantity", 0) *
                              _.get(item, "price", 0)
                            }
                          />
                        </td>
                        <td>
                          {user.permissions.includes(
                            "order.order-tracking"
                          ) && (
                            <button
                              className={"btn card-icon"}
                              onClick={() => handleShow(item.product_id)}
                            >
                              <VuiStockIcon />
                            </button>
                          )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>

          <div className={"card-content pr-12"}>
            <Row>
              <Col md={8} lg={8} xs={12} />
              <Col md={4} lg={4} xs={12}>
                <table className="table-summary type-two">
                  <tbody>
                    <tr>
                      <td>{t("helper.global.subtotal")}</td>
                      <td>
                        <VuiNumberFormat
                          className={"display-flex justify-content-between"}
                          value={_.get(data.get(), "total", 0)}
                          data={_.get(data.get(), "total", 0)}
                          prefix={"Rp"}
                        />
                      </td>
                    </tr>
                    {_.get(data.get(), "ppn", null) ? (
                      <tr>
                        <td>{t("helper.global.ppn")}</td>
                        <td>
                          <VuiNumberFormat
                            className={"display-flex justify-content-between"}
                            value={_.get(data.get(), "ppn_amount", 0)}
                            data={_.get(data.get(), "ppn_amount", 0)}
                            prefix={"Rp"}
                          />
                        </td>
                      </tr>
                    ) : null}
                    <tr>
                      <td>{t("helper.global.total")}</td>
                      <td>
                        <VuiNumberFormat
                          className={"display-flex justify-content-between"}
                          value={_.get(data.get(), "grand_total", 0)}
                          data={_.get(data.get(), "grand_total", 0)}
                          prefix={"Rp"}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
