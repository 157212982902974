import React, { useEffect, useRef } from "react";
import _ from "lodash";
import { useState } from "@hookstate/core/dist";
import { useTranslation } from "react-i18next";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDropzone } from "react-dropzone";
import clsx from "clsx";
import { BaseModelMedia, MediaProps } from "../../../interfaces/media";
import { fileTypes } from "./types";
import useMediaService from "../../../utilities/hooks/useMediaService";
import useIsMounted from "../../../utilities/hooks/useIsMounted";
import VuiLoadingScreen from "../VuiLoadingScreen";

interface VuiUploadImageProps {
  className?: string;
  defaultValue: MediaProps | null;
  accept?: fileTypes;
  onChange: (val: any) => void;
  onDelete?: () => void;
  disabled?: boolean;
  height?: number;
  width?: number | string;
}

const VuiUploadImage: React.FC<VuiUploadImageProps> = ({
  className = "",
  defaultValue = BaseModelMedia,
  accept = "image/*",
  onChange,
  onDelete,
  disabled = false,
  height = 40,
  width = 40,
}) => {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const cardHeight = useState<number>(height);
  const cardWidth = useState<number | string>(width);
  const scopedDefaultValue = useState<any>(defaultValue);
  const { storeMedia } = useMediaService();
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleOnDrop = (acceptedFiles: any) => {
    setLoading(true);
    const formData = {
      files: acceptedFiles,
      path: "file",
      disk: "public",
    };

    storeMedia(formData).then((response: any) => {
      setLoading(false);
      scopedDefaultValue.set({
        ...BaseModelMedia,
        ...response,
      });

      onChange(response);
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: accept,
    onDrop: handleOnDrop,
    onDropRejected: (event: any) => {},
    multiple: false,
    disabled: disabled,
  });

  const handleDelete = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    onChange(null);
    scopedDefaultValue.set(null);

    if (onDelete) {
      onDelete();
    }
  };

  // const updateSize = () => {
  //   cardHeight.set(_.get(ref, "current.offsetWidth", 0));
  // };

  useEffect(() => {
    if (isMounted.current) {
      scopedDefaultValue.set(defaultValue);
      // updateSize();
    }
  }, [defaultValue]);

  // useLayoutEffect(() => {
  //   if (isMounted.current) {
  //     window.addEventListener("resize", _.debounce(updateSize, 500));
  //     updateSize();
  //     return () => window.removeEventListener("resize", updateSize);
  //   }
  // }, []);

  return (
    <div className={`card-files-section single-file ${className}`}>
      <div
        className={clsx(["card-file"], {
          filled: _.get(scopedDefaultValue.value, "url", ""),
        })}
        ref={ref}
        style={{
          height: cardHeight.get(),
          width: cardWidth.get(),
        }}
      >
        {loading ? (
          <div className="card-inner" {...getRootProps()}>
            <VuiLoadingScreen
              className={"height-unset"}
              style={{
                width: `50%`,
              }}
            />
          </div>
        ) : (
          <div className="card-inner" {...getRootProps()}>
            <input {...getInputProps()} />
            {_.get(scopedDefaultValue.value, "url", "") ? (
              <div className="card-filled-wrapper">
                <img
                  className="image"
                  src={_.get(scopedDefaultValue.value, "url")}
                  alt="media"
                />
                {!disabled ? (
                  <div className="action-wrapper">
                    <FontAwesomeIcon
                      className={"icon-delete-file"}
                      icon={faTrash}
                      onClick={handleDelete}
                    />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                {/*<p className="card-label">{t("Upload an Image")}</p>*/}
                <FontAwesomeIcon className={"icon"} icon={faPlus} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VuiUploadImage;
