import moment from "moment";
import * as yup from "yup";
import { TFunction } from "react-i18next";

export type InformationInputs = {
  brand_id: any;
  date: any;
  order_id: any;
  receipt_items: any[];
};

export const informationBaseModel: InformationInputs = {
  brand_id: null,
  date: moment(),
  order_id: null,
  receipt_items: [],
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    date: yup.mixed().required().label(t("form.receiptDate.label")),
    brand_id: yup.mixed().required().label(t("form.brand.label")),
    order_id: yup.mixed().required().label(t("form.orderNumber.label")),
  };
  return yup.object().shape(shapes);
};
