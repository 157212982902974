/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { validationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formatFormData,
  handleSaveResponseError,
  handleSaveResponseSuccess,
} from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { handleResponseError } from "../../../../../vodea/utilities/helpers/global";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import { ICategoryInputs } from "../../../../../interfaces/Category";
import TitleHeader from "../../../../../components/TitleHeader";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const informationBaseModel: ICategoryInputs = {
  name: "",
  type: "Category",
  parent_id: null,
};

const SettingCategoryForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const title = id
    ? t("setting.section.masterData.category.edit.title")
    : t("setting.section.masterData.category.create.title");
  const metaTitle = id
    ? t("setting.section.masterData.category.edit.metaTitle")
    : t("setting.section.masterData.category.create.metaTitle");
  const isMounted = useIsMounted();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    setValue: informationSetValue,
    watch: informationWatch,
    setError: informationSetError,
  } = useForm<ICategoryInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const watchCategoryType = informationWatch("type");

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    if (formData.category_id) {
      formData.category_id = data.category_id?.type_id;
    }

    if (id) {
      await CategoryRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/category");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    } else {
      await CategoryRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/category");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    }
  };

  const loadData = async () => {
    await CategoryRepository.show(id, {
      with: ["parent"],
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        if (isMounted.current) {
          informationSetValue("name", data.name);
          informationSetValue("parent_id", data.parent);
          informationSetValue("type", data.type);
        }
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
      });
  };

  useMemo(() => {
    if (id) loadData();
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <TitleHeader title={title} withBackUrl={true} />

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <Row>
                    <Col lg={12} xs={12} md={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.type,
                        })}
                      >
                        <div className={"form-label"}>
                          {t("form.type.label")}
                        </div>
                        <Controller
                          render={({ field }) => {
                            return (
                              <div className={"form-check"}>
                                <div className={"d-flex align-items-center"}>
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Category"
                                    name={field.name}
                                    className="form-check-input"
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                    }}
                                    checked={field.value == "Category"}
                                  />
                                  <label className={"form-check-label"}>
                                    {t("form.type.forRadio.category")}
                                  </label>
                                </div>
                                <div className={"d-flex align-items-center"}>
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Sub Category"
                                    name={field.name}
                                    className="form-check-input"
                                    onChange={(e) => {
                                      field.onChange(e.target.value);
                                    }}
                                    checked={field.value == "Sub Category"}
                                  />
                                  <label className={"form-check-label"}>
                                    {t("form.type.forRadio.subCategory")}
                                  </label>
                                </div>
                              </div>
                            );
                          }}
                          name={"type"}
                          control={informationControl}
                        />
                        {informationErrors.type && (
                          <span className={"text-danger"}>
                            {informationErrors.type?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    {watchCategoryType === "Category" && (
                      <Col md={12} xs={12} lg={12}>
                        <div
                          className={clsx({
                            "form-group mb-unset": true,
                            error: informationErrors.name,
                          })}
                        >
                          <div className={"form-label"}>
                            {t("form.name.label")}
                          </div>
                          <input
                            type={"text"}
                            className={"form-control"}
                            {...informationRegister("name")}
                          />
                          {informationErrors.name && (
                            <span className={"text-danger"}>
                              {informationErrors.name?.message}
                            </span>
                          )}
                        </div>
                      </Col>
                    )}

                    {watchCategoryType === "Sub Category" && (
                      <>
                        <Col md={6} lg={6} xs={12}>
                          <div
                            className={clsx({
                              "form-group mb-unset": true,
                              error: informationErrors.parent_id,
                            })}
                          >
                            <div className={"form-label"}>
                              {t("form.category.label")}
                            </div>
                            <Controller
                              name={"parent_id"}
                              control={informationControl}
                              render={({ field }) => {
                                return (
                                  <VuiSelect
                                    selectRepository={CategoryRepository}
                                    selectParams={{
                                      type: "category",
                                    }}
                                    defaultValue={field.value}
                                    onChange={field.onChange}
                                  />
                                );
                              }}
                            />
                            {informationErrors.parent_id && (
                              <span className={"text-danger"}>
                                {informationErrors.parent_id?.message}
                              </span>
                            )}
                          </div>
                        </Col>

                        <Col md={6} xs={6} lg={6}>
                          <div
                            className={clsx({
                              "form-group mb-unset": true,
                              error: informationErrors.name,
                            })}
                          >
                            <div className={"form-label"}>
                              {t("form.name.label")}
                            </div>
                            <input
                              type={"text"}
                              className={"form-control"}
                              {...informationRegister("name")}
                            />
                            {informationErrors.name && (
                              <span className={"text-danger"}>
                                {informationErrors.name?.message}
                              </span>
                            )}
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createCategoryWithMark")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <div className={"button-group"}>
                        <div
                          className={"btn btn-light btn-sm"}
                          onClick={() => navigate("/setting/category")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingCategoryForm;
