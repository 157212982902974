import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  cardOrderFinish: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-finish`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.cardOrderFinish.name
        ].handleRequestCancellation().token,
    });
  },
  cardOrderHalfFinish: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-half-finish`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.cardOrderHalfFinish.name
        ].handleRequestCancellation().token,
    });
  },
  cardOrderProcess: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-process`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.cardOrderProcess.name
        ].handleRequestCancellation().token,
    });
  },
  cardOrderDelivery: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/order-delivery`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.cardOrderDelivery.name
        ].handleRequestCancellation().token,
    });
  },
  productMovement: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/product-summary`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.productMovement.name
        ].handleRequestCancellation().token,
    });
  },
  mostStockOrder: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/most-stock-order`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.mostStockOrder.name
        ].handleRequestCancellation().token,
    });
  },
  mostStockConsignment: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/most-stock-consignment`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.mostStockConsignment.name
        ].handleRequestCancellation().token,
    });
  },
  productInProcess: function (params: any = null) {
    return api.get(`${endPoint()}/api/widget/product-in-process`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.productInProcess.name
        ].handleRequestCancellation().token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
