import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import { HeaderAuth } from "../../../layouts/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { validationSchema } from "./validation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import { IForgotPassword } from "../../../interfaces/ForgotPassword";

const host = () => window._env_.REACT_APP_HOST_APP;

const ForgotPassword: React.FC<any> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>({
    resolver: yupResolver(validationSchema(t)),
  });

  const onSubmit = handleSubmit((data) => {
    if (isMounted.current) setLoading(true);
    const formData = {
      email: data.email,
      url: `${host()}/auth/reset-password`,
    };

    AuthRepository.forgotPassword(formData)
      .then((response: AxiosResponse) => {
        showToast(response.data.message, "success");
        navigate("/auth/forgot-password/success");
      })
      .catch((error: AxiosError) => {
        showToast(error.message, "error");
      });
  });

  return (
    <>
      <Helmet>
        <title>{t("forgotPassword.metaTitle")}</title>
      </Helmet>
      <HeaderAuth
        title={t("forgotPassword.title")}
        subtitle={t("forgotPassword.subtitle")}
        logo={false}
      />
      <form className={"form-wrapper"} onSubmit={onSubmit}>
        <div
          className={clsx({
            "form-group": true,
            error: errors.email,
          })}
        >
          <label className={"form-label"}>{t("form.email.label")}</label>
          <input
            type="email"
            className="form-control"
            {...register("email")}
            placeholder={t("form.email.placeholder")}
          />
          <label className={"label-help"}>{errors.email?.message}</label>
        </div>

        <div className={"d-flex align-items-center justify-content-between"}>
          <Link to={"/auth/login"}>{t("forgotPassword.login")}</Link>
          <VuiButton
            label={t("button.resetPassword")}
            loading={loading}
            forSubmit={true}
          />
        </div>
      </form>
      <div className={"copyright"}>© 2021 PT Laksmana Mulia Sentosa</div>
    </>
  );
};

export default ForgotPassword;
