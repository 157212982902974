/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import VuiDataTable from "../../../vodea/@vodea-ui/components/VuiDataTable";
import moment, { Moment } from "moment";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { useState } from "@hookstate/core/dist";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import _ from "lodash";
import { $clone } from "../../../vodea/utilities";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import { AxiosError, AxiosResponse } from "axios";
import VuiSelect from "../../../vodea/@vodea-ui/components/VuiSelect";
import { sortOptions } from "../../../constants";
import { handleResponseError } from "../../../vodea/utilities/helpers/global";
import OrderRepository from "../../../repositories/OrderRepository";
import { Date } from "../../../components";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import ModalDeleteItem from "../../../components/ModalDeleteItem";
import { ReactComponent as TrashIcon } from "../../../assets/icon/icons8-trash.svg";
import { useDeleteResource } from "../../../vodea/utilities/hooks/useDeleteResource";
import { useTranslation } from "react-i18next";
import StatusLabel from "../../../components/StatusLabel";
import TitleHeader from "../../../components/TitleHeader";
import VuiSearch from "../../../vodea/@vodea-ui/components/VuiSearch";

const Order: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const [tableIdSelected, setTableIdSelected] = React.useState<number>(0);
  const { handleDelete } = useDeleteResource(OrderRepository);
  const [selectedSortBy, setSelectedSortBy] = React.useState<any>(null);
  const tableData = useState<any[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);

  const handleIsOpenDeleteModal = (isOpen: boolean = true) => {
    if (isMounted.current) {
      setOpenDeleteModal(isOpen);
    }
  };

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "desc",
    order_by: "date",
    total: 0,
    loading: false,

    // additional
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableColumns = [
    {
      dataField: "date",
      text: t("table.header.date"),
      formatter: (cell: any) => <Date data={cell} />,
    },
    {
      dataField: "brand_name",
      text: t("table.header.brand"),
    },
    {
      dataField: "number",
      text: t("table.header.orderNumber"),
    },
    {
      dataField: "items",
      text: t("table.header.item"),
      style: {
        width: "11vw",
      },
    },
    {
      dataField: "status",
      text: t("table.header.status"),
      formatter: (cell: any) => {
        return <StatusLabel status={cell} />;
      },
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell: any, row: any) => (
        <div className={"table-btn-wrapper"}>
          <Link
            className={"btn btn-primary for-table mr-unset"}
            to={`${row.id}`}
          >
            {t("button.detail")}
          </Link>
          {user.permissions.includes("order.destroy") && (
            <TrashIcon
              className="delete-icon ml-4"
              onClick={() => handleClickDelete(row.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const handleClickDelete = (rowId: number) => {
    setTableIdSelected(rowId);
    handleIsOpenDeleteModal(true);
  };

  const changeTableLoading = (value: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(value);
    }
  };

  const getTableData = useCallback(async (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);
    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), ["loading", "total"]);

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) tableData.set([]);

    await OrderRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
          tableConfig.page.set(response.data.meta.current_page);
        }
        changeTableLoading(false);
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
        changeTableLoading(false);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await getTableData();
    })();
  }, []);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortOrder, sortField }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.sorted_by.set(
      sortOrder ? sortOrder : tableConfig.sorted_by.get()
    );
    tableConfig.order_by.set(
      sortField ? sortField : tableConfig.order_by.get()
    );

    getTableData().then();
  };

  const handleTableSearch = useCallback((value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true).then();
    }
  }, []);

  const handleTableDateRange = useCallback((start: Moment, end: Moment) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData().then();
  }, []);

  const handleDeleteResource = () => {
    if (isMounted.current) setIsDeleteLoading(true);
    handleDelete(tableIdSelected)
      .then(() => {
        if (isMounted.current) setIsDeleteLoading(false);
        handleIsOpenDeleteModal(false);
        getTableData().then();
      })
      .catch(() => {
        if (isMounted.current) setIsDeleteLoading(false);
      });
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("order.metaTitle")}</title>
      </Helmet>

      <ModalDeleteItem
        onClose={() => handleIsOpenDeleteModal(false)}
        open={openDeleteModal}
        itemName={t("helper.forItemNameDelete.order")}
        isLoading={isDeleteLoading}
        callback={handleDeleteResource}
      />

      <TitleHeader
        title={t("order.title")}
        withBackUrl={false}
        component={
          user.permissions.includes("order.store") && (
            <div className={"btn-wrapper"}>
              <Link to={"/order/sub-order/create"}>
                <button type="button" className="btn btn-primary">
                  {t("button.createOrder")}
                </button>
              </Link>
            </div>
          )
        }
      />

      <div className={"page-content"}>
        <div className="card-paper">
          <div className="card-content has-table">
            <div className={"default-filter-section type-mb-unset"}>
              <div className={"filter-item filter-special"}>
                <VuiSearch
                  value={tableConfig.search.get()}
                  callback={handleTableSearch}
                />
              </div>

              <div className={"filter-item"}>
                <div className="form-select-wrapper">
                  <VuiSelect
                    placeholder={t("form.sort.placeholder")}
                    defaultValue={selectedSortBy}
                    options={sortOptions}
                    onChange={(val) => {
                      setSelectedSortBy(val);
                      tableConfig.sorted_by.set(val.id);
                      getTableData().then();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={"default-filter-section"}>
              <div className={"filter-item type-date"}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges
                />
              </div>
            </div>
          </div>

          {user.permissions.includes("order.show") && (
            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={tableColumns}
              data={tableData.get()}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Order;
