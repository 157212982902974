import React from "react";

interface VuiCardIconProps {
  icon: string;
  primary?: boolean;
}

const VuiCardIcon: React.FC<VuiCardIconProps> = ({ icon, primary = true }) => {
  return (
    <div className={primary ? "card-icon" : "card-icon white"}>
      <img src={icon} alt={icon} />
    </div>
  );
};

export default VuiCardIcon;
