/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { AxiosError, AxiosResponse } from "axios";
import {
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import moment from "moment";
import VuiDateRangePicker from "../../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import VuiUploadImage from "../../../../../vodea/@vodea-ui/components/VuiUploadImage";
import VuiNumberFormat from "../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import {
  StockReceiveProductKonsinyasiBaseModel,
  StockReceiveProductKonsinyasiInputs,
  validationSchema,
} from "./validation";
import BrandRepository from "../../../../../repositories/BrandRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import UnitRepository from "../../../../../repositories/UnitRepository";
import GoodReceiptRepository from "../../../../../repositories/GoodReceiptRepository";

const StockReceiveProductConsignmentForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const [shipmentNumber, setShipmentNumber] = React.useState<string>("");
  const loading = useState<boolean>(false);
  const [uniq, setUniq] = React.useState<number>(0);

  const summaryData = useState({
    totalItems: 0,
    totalQuantity: 0,
  });

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
    control: informationControl,
    watch: informationWatch,
    getValues: informationGetValues,
    setError: informationSetErrors,
  } = useForm<StockReceiveProductKonsinyasiInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: {
      brand_id: null,
      description: "",
      date: moment(),
    },
  });

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control: informationControl,
    name: "receipt_items",
  });

  const watchBrand = informationWatch("brand_id");
  const receiptItems = informationGetValues("receipt_items");

  useEffect(() => {
    if (productFields.length == 0) {
      productAppend(StockReceiveProductKonsinyasiBaseModel);
    }
  }, [productFields]);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  useEffect(() => {
    calculateSummaryData();
  }, [receiptItems?.length]);

  const loadData = async () => {
    const params = {
      with: [
        "brand.logo",
        "order",
        "goodsReceiptDetails.product",
        "goodsReceiptDetails.photo",
        "goodsReceiptDetails.unit",
      ],
    };
    await GoodReceiptRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          informationSetValue("brand_id", responseData.brand);
          informationSetValue("description", responseData.description || "");
          informationSetValue("date", moment(responseData.date));
          setShipmentNumber(responseData.number);
          setReceiptItemData(responseData?.goods_receipt_details);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const setReceiptItemData = (data: any[] = []) => {
    const formattedData = data.map((item: any) => {
      return {
        photo_id: item.photo,
        sku: item.product,
        product_id: item.product,
        description: item.description,
        quantity: item.quantity,
        unit_id: item.unit_id,
      };
    });

    informationSetValue("receipt_items", formattedData);
  };

  const onInformationSubmit = informationHandleSubmit(async (data: any) => {
    if (isMounted.current) loading.set(true);
    const formData = formatFormData(data);
    formData?.receipt_items.splice(-1);

    formData.receipt_items = formData.receipt_items.map((item: any) => {
      return {
        photo_id: _.get(item, "photo_id.id"),
        product_id: _.get(item, "product_id.id"),
        description: _.get(item, "description"),
        quantity: _.get(item, "quantity"),
        unit_id: _.get(item, "unit_id.id"),
      };
    });

    if (id) {
      await GoodReceiptRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/stock/receive-product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    } else {
      await GoodReceiptRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/stock/receive-product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    }
  });

  const calculateSummaryData = () => {
    setTimeout(() => {
      if (productFields.length) {
        const tempSummaryData: any = {
          totalQuantity: 0,
          totalItems: 0,
        };

        const receiptItemsValue = informationGetValues("receipt_items");

        receiptItemsValue?.map((item: any, i: number) => {
          const quantity = item.quantity || 0;
          tempSummaryData.totalQuantity += Number(quantity);
          tempSummaryData.totalItems += 1;
        });

        tempSummaryData.totalItems = tempSummaryData.totalItems - 1;
        summaryData.set(tempSummaryData);
      }
    }, 200);
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("stock.goodsReceipt.edit.metaTitle")
            : t("stock.goodsReceipt.create.metaTitle")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div className={"icon-wrapper icon-back"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("stock.goodsReceipt.receipt")
              : t("stock.goodsReceipt.create.title")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>{t("helper.global.consignment")}</span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{shipmentNumber}</span>}
        </h4>
      </div>

      <form onSubmit={onInformationSubmit}>
        <div className="page-content">
          <div className={"card-paper"}>
            <div className={"card-content"}>
              <div className={"default-filter-section align-items-unset"}>
                <div className={"filter-item type-input-select mb-unset"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={BrandRepository}
                            defaultValue={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              setUniq((prevState) => prevState + 1);
                              informationSetValue("receipt_items", []);
                            }}
                            clearable
                          />
                        );
                      }}
                    />
                    {_.has(informationErrors, `brand_id`) && (
                      <span className={"text-danger"}>
                        {_.get(informationErrors, `brand_id.message`)}
                      </span>
                    )}
                  </div>
                </div>

                <div className={"filter-item type-half-full mb-unset"}>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryNumber.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={
                            Boolean(id) ? shipmentNumber : "[Auto Generate]"
                          }
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryDate.label")}
                        </label>
                        <Controller
                          name={"date"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                single={true}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group mb-unset"}>
                        <label className={"form-label"}>
                          {t("form.note.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("description")}
                        />
                        {_.has(informationErrors, `description`) && (
                          <span className={"text-danger"}>
                            {_.get(informationErrors, `description.message`)}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className={"card-paper mt-4"}>
            <div id={"table-custom-wrapper"}>
              <table className={"table table-custom"}>
                <thead>
                  <tr>
                    <th style={{ width: "6.5vw" }}>{t("form.img.label")}</th>
                    <th style={{ width: "12vw" }}>{t("form.sku.label")}</th>
                    <th style={{ width: "12vw" }}>{t("form.product.label")}</th>
                    <th style={{ width: "12vw" }}>
                      {t("form.description.label")}
                    </th>
                    <th style={{ width: "12vw" }}>{t("form.unit.label")}</th>
                    <th style={{ width: "12vw" }}>{t("form.qty.label")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productFields.map((item: any, index: number) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Controller
                            name={`receipt_items.${index}.photo_id`}
                            control={informationControl}
                            defaultValue={item.image}
                            render={({ field }) => {
                              return (
                                <VuiUploadImage
                                  defaultValue={field.value}
                                  onChange={field.onChange}
                                />
                              );
                            }}
                          />
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.photo_id`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.photo_id.message`
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <Controller
                            control={informationControl}
                            name={`receipt_items.${index}.sku`}
                            defaultValue={item.product_id}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    with: ["unit"],
                                    type: "consignment",
                                    brand: _.get(watchBrand, "id"),
                                  }}
                                  cacheUniq={uniq}
                                  defaultValue={field.value}
                                  isDisabled={!watchBrand}
                                  hideDropdownIcon={true}
                                  labelKey={"sku"}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `receipt_items.${index}.product_id`,
                                      value
                                    );
                                    if (productFields.length - 1 == index) {
                                      productAppend(
                                        StockReceiveProductKonsinyasiBaseModel
                                      );
                                    }
                                  }}
                                  placeholder={""}
                                />
                              );
                            }}
                          />
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.sku`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.sku.message`
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <Controller
                            control={informationControl}
                            name={`receipt_items.${index}.product_id`}
                            defaultValue={item.product_id}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    with: ["unit"],
                                    type: "consignment",
                                    brand: _.get(watchBrand, "id"),
                                  }}
                                  cacheUniq={uniq}
                                  isDisabled={!watchBrand}
                                  defaultValue={field.value}
                                  hideDropdownIcon={true}
                                  labelKey={"name"}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `receipt_items.${index}.sku`,
                                      value
                                    );
                                    if (productFields.length - 1 == index) {
                                      productAppend(
                                        StockReceiveProductKonsinyasiBaseModel
                                      );
                                    }
                                  }}
                                  placeholder={""}
                                />
                              );
                            }}
                          />
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.product_id`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.product_id.message`
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <Controller
                            control={informationControl}
                            name={`receipt_items.${index}.description`}
                            defaultValue={item.description}
                            render={({ field }) => {
                              return (
                                <input
                                  type="text"
                                  className="form-control"
                                  name={field.name}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    if (productFields.length - 1 == index) {
                                      productAppend(
                                        StockReceiveProductKonsinyasiBaseModel
                                      );
                                    }
                                  }}
                                  value={field.value}
                                />
                              );
                            }}
                          />
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.description`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.description.message`
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <Controller
                            name={`receipt_items.${index}.unit_id`}
                            defaultValue={item.unit_id}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={UnitRepository}
                                  defaultValue={field.value}
                                  onChange={field.onChange}
                                  clearable={true}
                                />
                              );
                            }}
                          />
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.unit_id`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.unit_id.message`
                              )}
                            </span>
                          )}
                        </td>
                        <td>
                          <div
                            className={
                              "display-flex align-items-center justify-content-between"
                            }
                          >
                            <Controller
                              render={({ field }) => {
                                return (
                                  <NumberFormat
                                    className={"form-control mr-4"}
                                    allowNegative={false}
                                    thousandSeparator={","}
                                    decimalSeparator={"."}
                                    decimalScale={0}
                                    placeholder={"0"}
                                    defaultValue={field.value}
                                    onValueChange={({ value }) => {
                                      field.onChange(value);
                                      calculateSummaryData();
                                      if (productFields.length - 1 == index) {
                                        productAppend(
                                          StockReceiveProductKonsinyasiBaseModel
                                        );
                                      }
                                    }}
                                  />
                                );
                              }}
                              name={`receipt_items.${index}.quantity`}
                              control={informationControl}
                              defaultValue={item.quantity}
                            />
                            {index != productFields.length - 1 && (
                              <FontAwesomeIcon
                                icon={faMinus}
                                onClick={() => {
                                  productRemove(index);
                                }}
                              />
                            )}
                          </div>
                          {_.has(
                            informationErrors,
                            `receipt_items.${index}.quantity`
                          ) && (
                            <span className={"text-danger"}>
                              {_.get(
                                informationErrors,
                                `receipt_items.${index}.quantity.message`
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className={"card-content pr-12 mt-4"}>
              <Row>
                <Col md={9} lg={9} xs={12} />
                <Col md={3} lg={3} xs={12}>
                  <table className="table-summary d-flex justify-content-around">
                    <tbody>
                      <tr>
                        <td>{t("helper.global.totalItem")}</td>
                        <td>
                          <VuiNumberFormat
                            value={summaryData.totalItems.get()}
                            data={summaryData.totalItems.get()}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("helper.global.totalQuantity")}</td>
                        <td>
                          <VuiNumberFormat
                            value={summaryData.totalQuantity.get()}
                            data={summaryData.totalQuantity.get()}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createReceiptWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/order/sub-order")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <div className={"button-group"}>
                    <VuiButton
                      forSubmit={true}
                      label={t("button.save")}
                      loading={loading.get()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StockReceiveProductConsignmentForm;
