/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import {
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import moment from "moment";
import VuiDateRangePicker from "../../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import VuiNumberFormat from "../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import {
  productBaseModel,
  SendOrderKonsinyasiInputs,
  validationSchema,
} from "./validation";
import BrandRepository from "../../../../../repositories/BrandRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import SendOrderRepository from "../../../../../repositories/SendOrderRepository";
import _ from "lodash";
import AddressRepository from "../../../../../repositories/AddressRepository";

const SendOrderConsignmentForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const loading = useState(false);
  const [shipmentNumber, setShipmentNumber] = React.useState<string>("");
  const [uniq, setUniq] = React.useState<number>(0);

  const summaryData = useState({
    total_item: 0,
    total_quantity: 0,
  });

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    getValues: informationGetValues,
    setValue: informationSetValue,
    watch: informationWatch,
    setError: informationSetErrors,
  } = useForm<SendOrderKonsinyasiInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: {
      brand_id: null,
      address: "",
      date: moment(),
      products: [],
      sender: "",
      note: "",
    },
  });

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control: informationControl,
    name: "products",
  });

  const products = informationWatch("products");
  const watchBrand = informationWatch("brand_id");

  useEffect(() => {
    productAppend(productBaseModel);
  }, []);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  useEffect(() => {
    calculateSummaryData();
  }, [JSON.stringify(products)]);

  const loadData = async () => {
    const params = {
      with: [
        "photo",
        "brand.logo",
        "orderShipmentDetails.orderDetail",
        "orderShipmentDetails.product.viewStock",
        "orderShipmentDetails.unit",
        "shipmentAddress",
      ],
    };
    await SendOrderRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          informationSetValue("brand_id", responseData.brand);
          informationSetValue(
            "address_id",
            responseData.shipment_address || null
          );
          informationSetValue("address", responseData.address || null);
          informationSetValue("date", moment(responseData.date));
          informationSetValue("sender", responseData.sender);
          informationSetValue("note", responseData.note);
          setShipmentNumber(responseData.number);
          setShipmentItems(responseData?.order_shipment_details);
          summaryData.batch((s) => {
            s.total_item.set(responseData.total_items);
            s.total_quantity.set(responseData.total_quantity);
          });
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const setShipmentItems = (data: any[] = []) => {
    const formattedData: any[] = data.map((item: any) => {
      item.product.unit = item.unit;
      return {
        sku: item.product,
        product_id: item.product,
        quantity: item.quantity,
      };
    });

    informationSetValue("products", formattedData);
  };

  const calculateSummaryData = () => {
    setTimeout(() => {
      if (productFields.length) {
        const tempSummaryData = {
          total_quantity: 0,
          total_item: 0,
        };

        const orderProductsValue = informationGetValues("products");
        orderProductsValue.forEach((item: any, index: number) => {
          if (item.quantity) {
            tempSummaryData.total_quantity += parseInt(item.quantity);
          }
          if (
            parseInt(item.quantity) > 0 &&
            _.get(products, `${index}.quantity`, 0) > 0
          ) {
            tempSummaryData.total_item += 1;
          }
        });

        if (Number.isNaN(tempSummaryData.total_quantity)) {
          tempSummaryData.total_quantity = 0;
        }

        summaryData.set(tempSummaryData);
      }
    }, 200);
  };

  const onInformationSubmit = informationHandleSubmit(async (data) => {
    if (isMounted.current) loading.set(true);
    const formData = formatFormData(data);

    const shipmentItems: any[] = [];
    data.products.forEach((item: any, index: number) => {
      if (item.product_id) {
        shipmentItems.push({
          product_id: item.product_id.id,
          unit_id: item.product_id.unit_id,
          quantity:
            typeof item.quantity === "string"
              ? parseInt(item.quantity)
              : item.quantity
              ? item.quantity
              : 0,
        });
      }
    });

    formData.shipment_items = shipmentItems;

    delete formData.products;

    if (id) {
      await SendOrderRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/order/send-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    } else {
      await SendOrderRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/order/send-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    }
  });

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("deliveryOrder.editDelivery.metaTitle")
            : t("deliveryOrder.createDelivery")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div className={"icon-wrapper icon-back"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("deliveryOrder.delivery")
              : t("deliveryOrder.createDelivery")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("deliveryOrder.consignment.title")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{shipmentNumber}</span>}
        </h4>
      </div>

      <form onSubmit={onInformationSubmit}>
        <div className="page-content">
          <div className={"card-paper"}>
            <div className={"card-content"}>
              <div className={"default-filter-section align-items-unset"}>
                <div className={"filter-item type-input-select mb-unset"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={BrandRepository}
                            selectParams={{ with: ["logo"] }}
                            defaultValue={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              setUniq((prevState) => prevState + 1);
                            }}
                          />
                        );
                      }}
                    />
                    {informationErrors.brand_id && (
                      <span className={"text-danger"}>
                        {informationErrors.brand_id?.message}
                      </span>
                    )}
                  </div>
                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>
                      {t("form.deliveryAddress.label")}
                    </div>
                    <Controller
                      name={"address_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={AddressRepository}
                            defaultValue={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                    {informationErrors.address_id && (
                      <span className={"text-danger"}>
                        {informationErrors.address_id?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className={"filter-item type-half-full mb-unset"}>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryNumber.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={id ? shipmentNumber : "[Auto Generate]"}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryDate.label")}
                        </label>
                        <Controller
                          name={"date"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                single={true}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group mb-unset"}>
                        <label className={"form-label"}>
                          {t("form.sender.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("sender")}
                        />
                      </div>
                    </Col>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group mb-unset"}>
                        <label className={"form-label"}>
                          {t("form.note.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("note")}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            <div id={"table-custom-wrapper"}>
              <table className={"table table-custom"}>
                <thead>
                  <tr>
                    <th style={{ width: "14vw" }}>{t("form.sku.label")}</th>
                    <th style={{ width: "14vw" }}>{t("form.product.label")}</th>
                    <th style={{ width: "14vw" }}>
                      {t("form.description.label")}
                    </th>
                    <th>{t("form.img.label")}</th>
                    <th>{t("form.unit.label")}</th>
                    <th>{t("form.available.label")}</th>
                    <th style={{ width: "18vw" }}>{t("form.qty.label")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productFields.map((item: any, index: number) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Controller
                            name={`products.${index}.sku`}
                            defaultValue={item.sku}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  labelKey="sku"
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    type: "consignment",
                                    with: ["photo", "viewStock", "unit"],
                                    brand: _.get(watchBrand, "id"),
                                  }}
                                  cacheUniq={uniq}
                                  defaultValue={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `products.${index}.product_id`,
                                      value
                                    );
                                    informationSetValue(
                                      `products.${index}.description`,
                                      value
                                    );
                                    if (index == productFields.length - 1) {
                                      productAppend(productBaseModel);
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`products.${index}.product_id`}
                            defaultValue={item.product_id}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    type: "consignment",
                                    with: ["photo", "viewStock", "unit"],
                                    brand: _.get(watchBrand, "id"),
                                  }}
                                  cacheUniq={uniq}
                                  defaultValue={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `products.${index}.description`,
                                      value
                                    );
                                    informationSetValue(
                                      `products.${index}.sku`,
                                      value
                                    );
                                    if (index == productFields.length - 1) {
                                      productAppend(productBaseModel);
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`products.${index}.description`}
                            defaultValue={item.product_id}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  labelKey="description"
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    type: "consignment",
                                    with: ["photo", "viewStock", "unit"],
                                    brand: _.get(watchBrand, "id"),
                                  }}
                                  cacheUniq={uniq}
                                  defaultValue={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `products.${index}.sku`,
                                      value
                                    );
                                    informationSetValue(
                                      `products.${index}.product_id`,
                                      value
                                    );
                                    if (index == productFields.length - 1) {
                                      productAppend(productBaseModel);
                                    }
                                  }}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          {item?.product_id?.photo?.url && (
                            <img
                              className={"table-product-image"}
                              src={item?.product_id?.photo?.url}
                              alt={item?.product_id?.photo?.name}
                            />
                          )}
                        </td>
                        <td>{item?.product_id?.unit?.name}</td>
                        <td>{item?.product_id?.view_stock?.total_available}</td>
                        <td>
                          <div className={"display-flex align-items-center"}>
                            <Controller
                              render={({ field }) => {
                                return (
                                  <NumberFormat
                                    className={
                                      index == productFields.length - 1
                                        ? "form-control mr-7"
                                        : "form-control mr-4"
                                    }
                                    allowNegative={false}
                                    thousandSeparator={","}
                                    decimalSeparator={"."}
                                    decimalScale={0}
                                    placeholder={"0"}
                                    defaultValue={field.value}
                                    onValueChange={({ value }) => {
                                      calculateSummaryData();
                                      field.onChange(value);
                                    }}
                                  />
                                );
                              }}
                              name={`products.${index}.quantity`}
                              control={informationControl}
                              defaultValue={item.quantity}
                            />
                            {index != productFields.length - 1 && (
                              <FontAwesomeIcon
                                icon={faMinus}
                                onClick={() => productRemove(index)}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className={"card-content mt-4 pr-12"}>
              <Row>
                <Col md={9} lg={9} xs={12} />
                <Col md={3} lg={3} xs={12}>
                  <table className="table-summary d-flex justify-content-around">
                    <tbody>
                      <tr>
                        <td>{t("helper.global.totalItem")}</td>
                        <td>
                          <VuiNumberFormat
                            value={summaryData.total_item.get()}
                            data={summaryData.total_item.get()}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("helper.global.totalQuantity")}</td>
                        <td>
                          <VuiNumberFormat
                            value={summaryData.total_quantity.get()}
                            data={summaryData.total_quantity.get()}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("createDeliveryWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/order/sub-order")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <div className={"button-group"}>
                    <VuiButton
                      forSubmit={true}
                      label={t("button.save")}
                      loading={loading.get()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendOrderConsignmentForm;
