import OutsideClickHandler from "react-outside-click-handler";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faPlus,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import React, { MouseEvent, useState } from "react";
import { passportService } from "../../../services";
import { useDispatch, useSelector } from "react-redux";
import { updateSystem } from "../../../../stores/system/actions";
import { RootState } from "../../../../stores";
import { getAliasesName, showToast } from "../../../utilities/helpers/global";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { InformationInputs, informationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import { VuiButton } from "../VuiButton";
import useIsMounted from "../../../utilities/hooks/useIsMounted";
import AccountRepository from "../../../../repositories/AccountRepository";
import { AxiosError, AxiosResponse } from "axios";
import { formatFormData } from "../../../utilities/helpers/form";

interface VuiHeaderProfileProps {}

const VuiHeaderProfile: React.FC<VuiHeaderProfileProps> = () => {
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => {
    return state.system.user;
  });

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
  } = useForm<InformationInputs>({
    mode: "onChange",
    resolver: yupResolver(informationSchema),
  });

  const onSubmit = (data: any) => {
    if (isMounted.current) {
      setLoading(true);
    }

    const formData = formatFormData(data);

    AccountRepository.updatePassword(formData)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setLoading(false);
          setShowModal(false);
          showToast(response.data.message, "success");
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          setLoading(false);
          showToast(error.message, "error");
        }
      });
  };

  const logout = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();

    await passportService
      .logout()
      .then(() => {})
      .catch(() => {});

    dispatch(
      updateSystem({
        loggedIn: false,
        user: null,
      })
    );
  };

  return (
    <div className={"dropdown-wrapper"}>
      <Modal
        show={showModal}
        id="change-password-layout"
        className={"modal-product-detail"}
        onHide={() => setShowModal(false)}
      >
        <div className={"modal-product-body"}>
          <div className={"close-btn"}>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => setShowModal(false)}
            />
          </div>

          <div className={"title"}>Ganti Kata Sandi</div>

          <form
            className="form-wrapper mt-4"
            onSubmit={informationHandleSubmit(onSubmit)}
          >
            <div className={"form-group"}>
              <div className={"form-label"}>Kata Sandi Saat Ini</div>
              <input
                type={showOldPassword ? "text" : "password"}
                className={"form-control"}
                {...informationRegister("old_password")}
              />
              <FontAwesomeIcon
                icon={showOldPassword ? faEye : faEyeSlash}
                className={"form-icon"}
                onClick={() => setShowOldPassword(!showOldPassword)}
              />
              {informationErrors.old_password && (
                <span className={"text-danger"}>
                  {informationErrors.old_password?.message}
                </span>
              )}
            </div>

            <div className={"form-group"}>
              <div className={"form-label"}>Kata Sandi Baru</div>
              <input
                type={showPassword ? "text" : "password"}
                className={"form-control"}
                {...informationRegister("new_password")}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEye : faEyeSlash}
                className={"form-icon"}
                onClick={() => setShowPassword(!showPassword)}
              />
              {informationErrors.new_password && (
                <span className={"text-danger"}>
                  {informationErrors.new_password?.message}
                </span>
              )}
            </div>

            <div className={"form-group"}>
              <div className={"form-label"}>Konfirmasi Kata Sandi</div>
              <input
                type={showPasswordConfirmation ? "text" : "password"}
                className={"form-control"}
                {...informationRegister("new_password_confirmation")}
              />
              <FontAwesomeIcon
                icon={showPasswordConfirmation ? faEye : faEyeSlash}
                className={"form-icon"}
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
              />
              {informationErrors.new_password_confirmation && (
                <span className={"text-danger"}>
                  {informationErrors.new_password_confirmation?.message}
                </span>
              )}
            </div>

            <div
              className={
                "display-flex align-items-center justify-content-center mt-4"
              }
            >
              <div className={"button-group"}>
                <div
                  className={"btn btn-light btn-sm mr-4"}
                  onClick={() => setShowModal(false)}
                >
                  Batal
                </div>
              </div>
              <div className={"button-group"}>
                <VuiButton
                  forSubmit={true}
                  customClass={"btn-small"}
                  label={"Simpan"}
                  loading={loading}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <div
          className={"profile-wrapper"}
          onClick={() => {
            setShow(!show);
          }}
        >
          {getAliasesName(user.name)}
        </div>

        <div className={clsx(["dropdown-content", { show: show }])}>
          <label className={"dropdown-title"}>{user?.name}</label>
          <ul className={"dropdown-list"}>
            {/*<li className={"dropdown-item"}>*/}
            {/*  <Link*/}
            {/*    className={"dropdown-link"}*/}
            {/*    to={"/account/profile"}*/}
            {/*    onClick={() => setShow(false)}*/}
            {/*  >*/}
            {/*    <span>{t("Profile")}</span>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className={"dropdown-item"}>
              <div
                className={"dropdown-link"}
                onClick={() => setShowModal(true)}
              >
                <span>Ganti Kata Sandi</span>
              </div>
            </li>
          </ul>

          <hr />

          <ul className={"dropdown-list"}>
            <li className={"dropdown-item"}>
              <span className={"dropdown-link"} onClick={logout}>
                <span>Keluar</span>
                <FontAwesomeIcon
                  className={"dropdown-icon"}
                  icon={faSignOutAlt}
                />
              </span>
            </li>
          </ul>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

export default VuiHeaderProfile;
