import { Navigate } from "react-router-dom";
import LayoutApp from "../layouts/App";
import Dashboard from "../pages/App/Dashboard";
import Order from "../pages/App/Order";
import Report from "../pages/App/Report";
import Setting from "../pages/App/Setting";
import SendOrder from "../pages/App/Order/SendOrder";
import OrderForm from "../pages/App/Order/Form";
import StockProduct from "../pages/App/Stock/Product";
import StockReceiveProduct from "../pages/App/Stock/ReceiveProduct";
import SettingAccount from "../pages/App/Setting/Account";
import SettingAccountForm from "../pages/App/Setting/Account/Form";
import SettingPermission from "../pages/App/Setting/Permission";
import SettingPermissionForm from "../pages/App/Setting/Permission/Form";
import SettingBrand from "../pages/App/Setting/Brand";
import SettingBrandForm from "../pages/App/Setting/Brand/Form";
import SettingUnit from "../pages/App/Setting/Unit";
import SettingUnitForm from "../pages/App/Setting/Unit/Form";
import StockProductDetail from "../pages/App/Stock/Product/Detail";
import SendOrderProductOrderForm from "../pages/App/Order/SendOrder/ProductOrder/Form";
import SendOrderProductOrderDetail from "../pages/App/Order/SendOrder/ProductOrder/Detail";
import OrderDetail from "../pages/App/Order/Detail";
import StockReceiveProductOrderForm from "../pages/App/Stock/ReceiveProduct/ProductOrder";
import StockReceiveProductOrderDetail from "../pages/App/Stock/ReceiveProduct/ProductOrder/Detail";
import SettingCategory from "../pages/App/Setting/Category";
import SettingCategoryForm from "../pages/App/Setting/Category/Form";
import SettingColor from "../pages/App/Setting/Color";
import SettingColorForm from "../pages/App/Setting/Color/Form";
import SettingProduct from "../pages/App/Setting/Product";
import SettingProductForm from "../pages/App/Setting/Product/Form";
import SettingMaterial from "../pages/App/Setting/Material";
import SettingMaterialForm from "../pages/App/Setting/Material/Form";
import SettingProductConsignmentForm from "../pages/App/Setting/Product/FormConsignment";
import SendOrderConsignmentForm from "../pages/App/Order/SendOrder/Consignment";
import SendOrderConsignmentDetail from "../pages/App/Order/SendOrder/Consignment/Detail";
import StockReceiveProductConsignmentForm from "../pages/App/Stock/ReceiveProduct/Consignment";
import StockReceiveProductConsignmentDetail from "../pages/App/Stock/ReceiveProduct/Consignment/Detail";
import SettingAddress from "../pages/App/Setting/Address";
import SettingAddressForm from "../pages/App/Setting/Address/Form";

const appRoutes = [
  {
    path: "/",
    element: <LayoutApp />,
    children: [
      {
        path: "/",
        element: <Navigate to={"/dashboard"} />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/order",
        children: [
          { path: "/", element: <Navigate to={"/order/sub-order"} /> },
          { path: "/sub-order", element: <Order /> },
          { path: "/sub-order/create", element: <OrderForm /> },
          { path: "/sub-order/:id/edit", element: <OrderForm /> },
          { path: "/sub-order/:id", element: <OrderDetail /> },
          { path: "/send-order", element: <SendOrder /> },
          {
            path: "/send-order/product-order/create",
            element: <SendOrderProductOrderForm />,
          },
          {
            path: "/send-order/product-order/:id",
            element: <SendOrderProductOrderDetail />,
          },
          {
            path: "/send-order/product-order/:id/edit",
            element: <SendOrderProductOrderForm />,
          },
          {
            path: "/send-order/consignment/create",
            element: <SendOrderConsignmentForm />,
          },
          {
            path: "/send-order/consignment/:id",
            element: <SendOrderConsignmentDetail />,
          },
          {
            path: "/send-order/consignment/:id/edit",
            element: <SendOrderConsignmentForm />,
          },
        ],
      },
      {
        path: "/stock",
        children: [
          { path: "/", element: <Navigate to={"/stock/all-product"} /> },
          {
            path: "/all-product",
            children: [
              { path: "/", element: <StockProduct /> },
              { path: "/:id", element: <StockProductDetail /> },
            ],
          },
          {
            path: "/receive-product",
            children: [
              { path: "/", element: <StockReceiveProduct /> },
              {
                path: "/product-order",
                children: [
                  {
                    path: "/create",
                    element: <StockReceiveProductOrderForm />,
                  },
                  {
                    path: "/:id/edit",
                    element: <StockReceiveProductOrderForm />,
                  },
                  { path: "/:id", element: <StockReceiveProductOrderDetail /> },
                ],
              },
              {
                path: "/consignment",
                children: [
                  {
                    path: "/create",
                    element: <StockReceiveProductConsignmentForm />,
                  },
                  {
                    path: "/:id/edit",
                    element: <StockReceiveProductConsignmentForm />,
                  },
                  {
                    path: "/:id",
                    element: <StockReceiveProductConsignmentDetail />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/report",
        element: <Report />,
      },
      {
        path: "/setting",
        children: [
          { path: "/", element: <Setting /> },
          {
            path: "/category",
            children: [
              { path: "/", element: <SettingCategory /> },
              { path: "/create", element: <SettingCategoryForm /> },
              { path: "/:id", element: <SettingCategoryForm /> },
            ],
          },
          {
            path: "/color",
            children: [
              { path: "/", element: <SettingColor /> },
              { path: "/create", element: <SettingColorForm /> },
              { path: "/:id", element: <SettingColorForm /> },
            ],
          },
          {
            path: "/product",
            children: [
              { path: "/", element: <SettingProduct /> },
              { path: "/create", element: <SettingProductForm /> },
              { path: "/:id", element: <SettingProductForm /> },
              {
                path: "/consignment/create",
                element: <SettingProductConsignmentForm />,
              },
              {
                path: "/consignment/:id",
                element: <SettingProductConsignmentForm />,
              },
            ],
          },
          {
            path: "/material",
            children: [
              { path: "/", element: <SettingMaterial /> },
              { path: "/create", element: <SettingMaterialForm /> },
              { path: "/:id", element: <SettingMaterialForm /> },
            ],
          },
          {
            path: "/account",
            children: [
              { path: "/", element: <SettingAccount /> },
              { path: "/create", element: <SettingAccountForm /> },
              { path: "/:id", element: <SettingAccountForm /> },
            ],
          },
          {
            path: "/permission",
            children: [
              { path: "/", element: <SettingPermission /> },
              { path: "/create", element: <SettingPermissionForm /> },
              { path: "/:id", element: <SettingPermissionForm /> },
            ],
          },
          {
            path: "/brand",
            children: [
              { path: "/", element: <SettingBrand /> },
              { path: "/create", element: <SettingBrandForm /> },
              { path: "/:id", element: <SettingBrandForm /> },
            ],
          },
          {
            path: "/unit",
            children: [
              { path: "/", element: <SettingUnit /> },
              { path: "/create", element: <SettingUnitForm /> },
              { path: "/:id", element: <SettingUnitForm /> },
            ],
          },
          {
            path: "/address",
            children: [
              { path: "/", element: <SettingAddress /> },
              { path: "/create", element: <SettingAddressForm /> },
              { path: "/:id", element: <SettingAddressForm /> },
            ],
          },
        ],
      },
    ],
  },
];

export default appRoutes;
