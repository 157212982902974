import React, { useMemo } from "react";
import passportService from "../../services/passport.service";
import { useDispatch, useSelector } from "react-redux";
import { updateSystem } from "../../../stores/system/actions";
import { RootState } from "../../../stores";

const usePassportService = (disableAutoFetchUser = false) => {
  const dispatch = useDispatch();
  const { loggedIn } = useSelector((state: RootState) => {
    return {
      loggedIn: state.system.loggedIn,
    };
  });

  const [isOnSignIn, setIsOnSignIn] = React.useState(false);
  const [isOnFetchingUser, setIsOnFetchingUser] = React.useState(
    !disableAutoFetchUser
  );

  const fetchUser = async () => {
    const accessToken = passportService.getAccessToken();
    if (accessToken && !loggedIn) {
      setIsOnFetchingUser(true);
      await passportService
        .fetchUser()
        .then((response: any) => {
          dispatch(
            updateSystem({
              loggedIn: !!response.data.data,
              user: response.data.data,
            })
          );
        })
        .catch(() => {});

      setIsOnFetchingUser(false);
    }
  };

  const login = async (
    username: string,
    password: string,
    scope: string = "*"
  ) => {
    return new Promise(async (resolve, reject) => {
      setIsOnSignIn(true);

      await passportService
        .login(username, password, scope)
        .then((response) => {
          setIsOnFetchingUser(true);
          resolve(response);
        })
        .catch((error) => reject(error));

      setIsOnSignIn(false);
    });
  };

  useMemo(() => {
    (async () => {
      if (isOnFetchingUser) {
        await fetchUser()
          .then(() => {})
          .catch(() => {});
      }

      setIsOnFetchingUser(false);
    })();
  }, [isOnFetchingUser]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    isOnSignIn,
    isOnFetchingUser,
    login,
    fetchUser,
  };
};

export default usePassportService;
