/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError, AxiosResponse } from "axios";
import { useState } from "@hookstate/core/dist";

import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { validationSchema } from "./validation";
import {
  formatFormData,
  handleSaveResponseError,
  handleSaveResponseSuccess,
} from "../../../../../vodea/utilities/helpers/form";
import BrandRepository from "../../../../../repositories/BrandRepository";
import { handleResponseError } from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import RoleRepository from "../../../../../repositories/RoleRepository";
import UserRepository from "../../../../../repositories/UserRepository";
import TitleHeader from "../../../../../components/TitleHeader";
import { IAccountInputs } from "../../../../../interfaces/Account";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

const host = () => window._env_.REACT_APP_HOST_APP;

export const informationBaseModel: IAccountInputs = {
  name: "",
  email: "",
  phone_number: "",
  type: "Laksmana",
  position: "",
  role_id: null,
  brand_ids: null,
};

const SettingAccountForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const metaTitle = id
    ? t("setting.section.accountSection.account.edit.metaTitle")
    : t("setting.section.accountSection.account.create.metaTitle");
  const title = id
    ? t("setting.section.accountSection.account.edit.title")
    : t("setting.section.accountSection.account.create.title");
  const isMounted = useIsMounted();
  const roleOptions = useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
    control: informationControl,
    watch: informationWatch,
    getValues: informationGetValues,
    setError: informationSetError,
  } = useForm<IAccountInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const watchType = informationWatch("type");

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    if (!formData.brand_ids) {
      delete formData.brand_ids;
    }

    Object.assign(formData, {
      url: `${host()}/auth/reset-password`,
    });

    if (id) {
      await UserRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/account");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    } else {
      await UserRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/account");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    }
  };

  const loadData = async () => {
    const params = {
      with: ["roles", "brands"],
    };
    await UserRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        setFormData(data);
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
      });
  };

  const setFormData = (data: any) => {
    Object.keys(data).map((key: any) => {
      if (key === "brands") {
        informationSetValue("brand_ids", data[key]);
      } else if (key === "roles") {
        informationSetValue("role_id", data[key][0]);
      } else {
        informationSetValue(key, data[key]);
      }
    });
  };

  const getRole = async () => {
    const type = informationGetValues("type");
    const selectedRole = informationGetValues("role_id");
    if (selectedRole?.type !== type) {
      informationSetValue("role_id", null);
    }
    await RoleRepository.select({
      type: type,
    })
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        roleOptions.set(data);
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
      });
  };

  useEffect(() => {
    getRole();
  }, [watchType]);

  useMemo(() => {
    if (id) loadData();
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <TitleHeader title={title} withBackUrl={true} />

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className="page-content">
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className="card-paper">
                <div className="card-content">
                  <Row>
                    <Col md={12} lg={12} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.name,
                        })}
                      >
                        <div className="form-label">{t("form.name.label")}</div>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("name")}
                        />
                        {informationErrors.name && (
                          <span className="text-danger">
                            {informationErrors.name?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.email,
                        })}
                      >
                        <div className="form-label">
                          {t("form.email.label")}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("email")}
                        />
                        {informationErrors.email && (
                          <span className="text-danger">
                            {informationErrors.email?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.phone_number,
                        })}
                      >
                        <div className="form-label">
                          {t("form.phoneNumber.label")}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("phone_number")}
                        />
                        {informationErrors.phone_number && (
                          <span className="text-danger">
                            {informationErrors.phone_number?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.type,
                        })}
                      >
                        <div className="form-label">
                          {t("form.accountType.label")}
                        </div>
                        <Controller
                          render={({ field }) => {
                            return (
                              <div className="form-check">
                                <div className="d-flex align-items-center">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Laksmana"
                                    name={field.name}
                                    className="form-check-input"
                                    checked={field.value == "Laksmana"}
                                  />
                                  <label className="form-check-label">
                                    {t("form.accountType.forRadio.laksmana")}
                                  </label>
                                </div>
                                <div className="d-flex align-items-center">
                                  <input
                                    {...field}
                                    type="radio"
                                    value="Client"
                                    name={field.name}
                                    className="form-check-input"
                                    checked={field.value == "Client"}
                                  />
                                  <label className="form-check-label">
                                    {t("form.accountType.forRadio.client")}
                                  </label>
                                </div>
                              </div>
                            );
                          }}
                          name={"type"}
                          control={informationControl}
                        />
                        {informationErrors.type && (
                          <span className="text-danger">
                            {informationErrors.type?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.position,
                        })}
                      >
                        <div className="form-label">
                          {t("form.position.label")}
                        </div>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("position")}
                        />
                        {informationErrors.position && (
                          <span className="text-danger">
                            {informationErrors.position?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div
                        className={clsx({
                          "form-group": true,
                          error: informationErrors.role_id,
                        })}
                      >
                        <div className="form-label">{t("form.role.label")}</div>
                        <Controller
                          name={"role_id"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                options={roleOptions.get()}
                                defaultValue={field.value}
                                useAsync={false}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                        {informationErrors.role_id && (
                          <span className="text-danger">
                            {informationErrors.role_id?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      {watchType != "Laksmana" && (
                        <div
                          className={clsx({
                            "form-group mb-unset": true,
                            error: informationErrors.brand_ids,
                          })}
                        >
                          <div className="form-label">
                            {t("form.brand.label")}
                          </div>
                          <Controller
                            name={"brand_ids"}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={BrandRepository}
                                  defaultValue={field.value}
                                  onChange={field.onChange}
                                  isMulti
                                  clearable
                                />
                              );
                            }}
                          />
                          {informationErrors.brand_ids && (
                            <span className="text-danger">
                              {informationErrors.brand_ids?.message}
                            </span>
                          )}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>

              <div className="card-paper mt-4">
                <div className="card-content type-button">
                  <div className="button-submit-wrapper">
                    <div className="label-wrapper">
                      <div className="title">
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createAccountWithMark")}
                      </div>
                    </div>
                    <div className="button-group-wrapper">
                      <div className="button-group">
                        <div
                          className="btn btn-light btn-sm"
                          onClick={() => navigate("/order/sub-order")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingAccountForm;
