/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { useNavigate, useParams } from "react-router-dom";
import VuiUploadImage from "../../../../../vodea/@vodea-ui/components/VuiUploadImage";
import { validationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  formatFormData,
  handleSaveResponseError,
  handleSaveResponseSuccess,
} from "../../../../../vodea/utilities/helpers/form";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { handleResponseError } from "../../../../../vodea/utilities/helpers/global";
import { AxiosError, AxiosResponse } from "axios";
import BrandRepository from "../../../../../repositories/BrandRepository";
import { IBrand } from "../../../../../interfaces/Brand";
import TitleHeader from "../../../../../components/TitleHeader";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { IResource } from "../../../../../interfaces/Resource";
import { IAddress } from "../../../../../interfaces/Address";

const defaultValues = {
  name: "",
  logo_id: null,
};

const SettingBrandForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation();
  const metaTitle = id
    ? t("setting.section.masterData.brand.edit.metaTitle")
    : t("setting.section.masterData.brand.create.metaTitle");
  const title = id
    ? t("setting.section.masterData.brand.edit.title")
    : t("setting.section.masterData.brand.create.title");
  const isMounted = useIsMounted();
  const [data, setData] = useState<IAddress>(defaultValues);
  const [loading, setLoading] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await BrandRepository.show(id, {
      with: ["logo"],
    })
      .then((response: AxiosResponse<IResource<IBrand>>) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          Object.assign(responseData, {
            logo_id: responseData.logo,
          });
          setData(responseData);
        }
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
      });
  }, []);

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    setError: informationSetError,
    reset: informationReset,
  } = useForm<IBrand>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: useMemo(() => {
      (async () => {
        await loadData();
      })();
      return data;
    }, [id, loadData]),
  });

  useEffect(() => {
    informationReset(data);
  }, [data]);

  const onSubmit = async (data: IBrand) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    if (id) {
      await BrandRepository.update(id, formData)
        .then((response: AxiosResponse<IResource<IBrand>>) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/brand");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    } else {
      await BrandRepository.create(formData)
        .then((response: AxiosResponse<IResource<IBrand>>) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseSuccess(response, navigate, "/setting/brand");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            handleSaveResponseError(error, informationSetError);
          }
        });
    }
  };

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>

      <TitleHeader title={title} withBackUrl={true} />

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <div
                    className={clsx({
                      "form-group": true,
                      error: informationErrors.name,
                    })}
                  >
                    <div className={"form-label"}>{t("form.name.label")}</div>
                    <input
                      type={"text"}
                      className={"form-control"}
                      {...informationRegister("name")}
                    />
                    {informationErrors.name && (
                      <span className={"text-danger"}>
                        {informationErrors.name?.message}
                      </span>
                    )}
                  </div>

                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>{t("form.logo.label")}</div>
                    <Controller
                      render={({ field }) => {
                        return (
                          <VuiUploadImage
                            defaultValue={field.value}
                            onChange={field.onChange}
                            height={110}
                            width={110}
                          />
                        );
                      }}
                      name={"logo_id"}
                      control={informationControl}
                    />
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createBrandWithMark")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <div className={"button-group"}>
                        <div
                          className={"btn btn-light btn-sm"}
                          onClick={() => navigate("/setting/brand")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingBrandForm;
