import React from "react";
import VuiCardIcon from "../VuiCardIcon";
import VuiBarChartIcon from "../../../../assets/icon/icons8-bar-chart.svg";
import VuiDeadlineIcon from "../../../../assets/icon/icons8-deadline.svg";
import VuiSandTimerIcon from "../../../../assets/icon/icons8-sand-timer.svg";
import VuiSandWatchIcon from "../../../../assets/icon/icons8-sand-watch.svg";
import VuiTruckIcon from "../../../../assets/icon/icons8-truck.svg";
import VuiDeleteIcon from "../../../../assets/icon/icons8-product.svg";
import VuiLoadingScreen from "../VuiLoadingScreen";

interface VuiCardProps {
  label: string;
  icon?: string;
  data?: number;
  loading?: boolean;
}

const VuiCard: React.FC<VuiCardProps> = ({
  label,
  icon,
  data,
  loading = false,
}) => {
  return (
    <div className={"card-paper"}>
      <div className={"card-content"}>
        {loading ? (
          <VuiLoadingScreen className={"height-unset"} />
        ) : (
          <>
            <div className={"card-status-header"}>
              <div className={"d-block"}>
                <strong>Order</strong>
                <div className={"card-status-subtitle"}>{label}</div>
              </div>
              <VuiCardIcon
                icon={
                  icon === "bar"
                    ? VuiBarChartIcon
                    : icon === "deadline"
                    ? VuiDeadlineIcon
                    : icon === "timer"
                    ? VuiSandTimerIcon
                    : icon === "watch"
                    ? VuiSandWatchIcon
                    : icon === "truck"
                    ? VuiTruckIcon
                    : VuiDeleteIcon
                }
              />
            </div>
            <div className={"card-text-body"}>{data}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default VuiCard;
