/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  informationBaseModel,
  InformationInputs,
  validationSchema,
} from "./validation";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../../vodea/utilities/helpers/global";
import UnitRepository from "../../../../../repositories/UnitRepository";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";

const SettingUnitForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
  } = useForm<InformationInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    if (id) {
      await UnitRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/unit");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    } else {
      await UnitRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/unit");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    }
  };

  const loadData = async () => {
    await UnitRepository.show(id)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        if (isMounted.current) {
          informationSetValue("name", data.name);
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError)
          showToast(error?.response?.data.message, "error");
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, []);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("setting.section.masterData.unit.edit.metaTitle")
            : t("setting.section.masterData.unit.create.metaTitle")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/setting/unit")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>
          {id
            ? t("setting.section.masterData.unit.edit.title")
            : t("setting.section.masterData.unit.create.title")}
        </h4>
      </div>

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>{t("form.name.label")}</div>
                    <input
                      type={"text"}
                      className={"form-control"}
                      {...informationRegister("name")}
                    />
                    {informationErrors.name && (
                      <span className={"text-danger"}>
                        {informationErrors.name?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createUnitWithMark")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <div className={"button-group"}>
                        <div
                          className={"btn btn-light btn-sm"}
                          onClick={() => navigate("/setting/unit")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingUnitForm;
