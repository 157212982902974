/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VuiLoadingScreen from "../../vodea/@vodea-ui/components/VuiLoadingScreen";
import { usePassportService } from "../../vodea/utilities/hooks";
import VuiHeaderProfile from "../../vodea/@vodea-ui/components/VuiHeaderProfile";
import VuiSidebar from "../../vodea/@vodea-ui/components/VuiSidebar";

const LayoutApp: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const loggedIn = useSelector((state: RootState) => state.system.loggedIn);
  const [showSidebar, setShowSidebar] = useState(false);

  if (isOnFetchingUser) return <VuiLoadingScreen />;

  if (!loggedIn) {
    return <Navigate to={"/auth/login"} replace />;
  }

  return (
    <section id={"app-layout"}>
      <div className={"app-header"}>
        <div className={"brand-wrapper"}>
          <div
            className={"mobile-menu"}
            onClick={() => {
              setShowSidebar(!showSidebar);
            }}
          >
            <FontAwesomeIcon className={"menu-icon"} icon={faBars} />
          </div>

          <div className={"brand-icon"}>
            <img className={"logo"} src={"/logo.png"} alt={"logo"} />
          </div>
        </div>

        <div className={"navigation-wrapper"}>
          <div className={"navigation-item"}>
            <VuiHeaderProfile />
          </div>
        </div>
      </div>

      <div className={"app-header-separator"} />

      <VuiSidebar
        show={showSidebar}
        onClose={() => {
          setShowSidebar(false);
        }}
      />

      <div className={"app-content"}>
        <div className={"app-container"}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default LayoutApp;
