import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    name: yup.string().required().label(t("form.name.label")),
    email: yup.string().required().label(t("form.email.label")),
    phone_number: yup.string().required().label(t("form.phone_number.label")),
    position: yup.string().required().label(t("form.position.label")),
    role_id: yup.mixed().required().label(t("form.role.label")),
  };
  return yup.object().shape(shapes);
};
