import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    password: yup.string().required().label(t("form.password.label")),
    password_confirmation: yup
      .string()
      .oneOf(
        [yup.ref("password"), null],
        t("form.passwordConfirmation.error.notSuitable")
      )
      .label(t("form.passwordConfirmation.label")),
  };

  return yup.object().shape(shapes);
};
