import defaultLoader from "./loader.svg";

interface VuiLoadingScreenProps {
  loader?: string;
  className?: string;
  style?: any;
}

const VuiLoadingScreen: React.FC<VuiLoadingScreenProps> = ({
  loader = defaultLoader,
  className,
  style,
}) => {
  return (
    <div className={className} id="screen-loader">
      <img className="loader" src={loader} alt={"test"} style={style} />
    </div>
  );
};

export default VuiLoadingScreen;
