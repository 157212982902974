/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { formatFormData } from "../../../vodea/utilities/helpers/form";
import VuiSelect from "../../../vodea/@vodea-ui/components/VuiSelect";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import moment from "moment";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import fileDownload from "js-file-download";
import BrandRepository from "../../../repositories/BrandRepository";
import ProductRepository from "../../../repositories/ProductRepository";
import ReportRepository from "../../../repositories/ReportRepository";
import { validationSchema } from "./validation";
import TitleHeader from "../../../components/TitleHeader";
import {
  generateAllOption,
  generateReportTemplateOption,
  handleResponseError,
} from "../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import { IReport } from "../../../interfaces/Report";
import { IBrand } from "../../../interfaces/Brand";
import { useTranslation } from "react-i18next";

const Report: React.FC<any> = () => {
  const [brandOptions, setBrandOptions] = useState<IBrand[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [uniq, setUniq] = useState<number>(0);
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const {
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    watch: informationWatch,
    control: informationControl,
  } = useForm<IReport>({
    resolver: yupResolver(validationSchema()),
    defaultValues: {
      type_order: false,
      template: null,
      brand_ids: [],
      option: "all-product",
      type_consignment: false,
      product_ids: [],
      date: moment(),
    },
  });

  const watchOption = informationWatch("option");
  const watchBrands = informationWatch("brand_ids");

  const loadBrandOptions = useCallback(async () => {
    await BrandRepository.select()
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setBrandOptions(responseData);
        }
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadBrandOptions();
    })();
  }, []);

  const onSubmit = informationHandleSubmit(async (data) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    delete formData.template;
    delete formData.option;
    formData.type_order = Number(formData.type_order);
    formData.type_consignment = Number(formData.type_consignment);

    await ReportRepository.all(formData)
      .then((response: AxiosResponse) => {
        const { data: responseData } = response;
        if (isMounted.current) setLoading(false);
        fileDownload(responseData, "product-stock.xlsx");
      })
      .catch((error: AxiosError) => {
        handleResponseError(error);
        if (isMounted.current) setLoading(false);
      });
  });

  const handleBrandSelectOnChange = (selectedData: any[], onChange: any) => {
    setUniq((prevState) => prevState + 1);
    const isAllOptions = selectedData?.find((item) => item.id === "all");

    if (isAllOptions) {
      onChange(brandOptions);
    } else {
      onChange(selectedData);
    }
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("report.metaTitle")}</title>
      </Helmet>

      <TitleHeader title={t("report.title")} withBackUrl={false} />

      <form onSubmit={onSubmit}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <Row>
                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.reportTemplate.label")}
                        </div>
                        <Controller
                          name={"template"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                options={generateReportTemplateOption(t)}
                                labelKey="name"
                                defaultValue={field.value}
                                onChange={field.onChange}
                                placeholder={t(
                                  "form.reportTemplate.forSelect.placeholder"
                                )}
                              />
                            );
                          }}
                        />
                        {_.has(informationErrors, `template`) && (
                          <span className={"text-danger"}>
                            {_.get(informationErrors, `template.message`)}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.brand.label")}
                        </div>
                        <Controller
                          name={"brand_ids"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                additionalOptions={generateAllOption(t)}
                                options={brandOptions}
                                labelKey={"name"}
                                useAsync={false}
                                clearable
                                placeholder={t(
                                  "form.brand.forSelect.placeholder"
                                )}
                                defaultValue={field.value}
                                onChange={(value) =>
                                  handleBrandSelectOnChange(
                                    value,
                                    field.onChange
                                  )
                                }
                                isMulti
                              />
                            );
                          }}
                        />
                        {_.has(informationErrors, `brand_ids`) && (
                          <span className={"text-danger"}>
                            {_.get(informationErrors, `brand_ids.message`)}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.per.label")}
                        </div>
                        <Controller
                          name={"date"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                onChange={field.onChange}
                                single
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.productType.label")}
                        </div>
                        <div className={"d-flex"}>
                          <Controller
                            name={"type_order"}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <div className="form-check">
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="checkbox"
                                    name={field.name}
                                    checked={field.value}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {t("helper.global.productOrder")}
                                  </label>
                                </div>
                              );
                            }}
                          />

                          <Controller
                            name={"type_consignment"}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <div className="form-check">
                                  <input
                                    {...field}
                                    className="form-check-input"
                                    type="checkbox"
                                    name={field.name}
                                    checked={field.value}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexCheckDefault"
                                  >
                                    {t("helper.global.consignment")}
                                  </label>
                                </div>
                              );
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>
                      {t("form.product.label")}
                    </div>
                    <Controller
                      render={({ field }) => {
                        return (
                          <div className={"form-check"}>
                            <div className={"d-flex align-items-center"}>
                              <input
                                {...field}
                                type="radio"
                                value="all-product"
                                name={field.name}
                                className="form-check-input"
                                checked={field.value == "all-product"}
                              />
                              <label className={"form-check-label"}>
                                {t("options.allProduct")}
                              </label>
                            </div>
                            <div className={"d-flex align-items-center"}>
                              <input
                                {...field}
                                type="radio"
                                value="specific-product"
                                name={field.name}
                                className="form-check-input"
                                checked={field.value == "specific-product"}
                              />
                              <label className={"form-check-label"}>
                                {t("options.specificProduct")}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                      name={"option"}
                      control={informationControl}
                    />

                    {watchOption !== "all-product" && (
                      <div className={"mt-3"}>
                        <Controller
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                selectRepository={ProductRepository}
                                selectParams={{
                                  brands: watchBrands.map((brand) => brand.id),
                                }}
                                labelKey={"name"}
                                defaultValue={field.value}
                                onChange={field.onChange}
                                isMulti
                                cacheUniq={uniq}
                              />
                            );
                          }}
                          name={"product_ids"}
                          control={informationControl}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {t("helper.global.downloadReport")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <VuiButton
                        forSubmit={true}
                        label={t("button.download")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default Report;
