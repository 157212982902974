/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import moment from "moment";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { useState } from "@hookstate/core/dist";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { AxiosError, AxiosResponse } from "axios";
import VuiSelect from "../../../../vodea/@vodea-ui/components/VuiSelect";
import { sortOptions, typeProductOptions } from "../../../../constants";
import { ReactComponent as VuiStockIcon } from "../../../../assets/icon/icons8-product.svg";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import StockProductRepository from "../../../../repositories/StockProductRepository";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import BrandRepository from "../../../../repositories/BrandRepository";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useTranslation } from "react-i18next";

const StockProduct: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const [selectedSortBy, setSelectedSortBy] = React.useState<any>(null);
  const tableData = useState<any[]>([]);
  const [selectedBrand, setSelectedBrand] = React.useState<any>(null);
  const [selectedType, setSelectedType] = React.useState<any>(null);

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "desc",
    order_by: "created_at",
    total: 0,
    loading: false,
    type: null,
    brand: null,

    // additional
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableColumns = [
    {
      dataField: "brand",
      text: t("table.header.brand"),
    },
    {
      dataField: "order_number",
      text: t("table.header.orderNo"),
    },
    {
      dataField: "sku",
      text: t("table.header.sku"),
    },
    {
      dataField: "name",
      text: t("table.header.name"),
    },
    {
      dataField: "unit",
      text: t("table.header.unit"),
    },
    {
      dataField: "total_order",
      text: t("table.header.order"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_delivery",
      text: t("table.header.sent"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_available",
      text: t("table.header.available"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_process",
      text: t("table.header.inProcess"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell: any, row: any) => (
        <div className={"table-btn-wrapper"}>
          <Link className={"btn card-icon"} to={`/stock/all-product/${row.id}`}>
            <VuiStockIcon />
          </Link>
        </div>
      ),
    },
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    if (isMounted.current) changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (params.type === "all") {
      delete params.type;
    }

    if (isMounted.current) tableData.set([]);

    StockProductRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
          tableConfig.page.set(response.data.meta.current_page);

          changeTableLoading(false);
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError && isMounted.current) {
          showToast(error?.response?.data.message, "error");
          changeTableLoading(false);
        }
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortOrder, sortField }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.sorted_by.set(
      sortOrder ? sortOrder : tableConfig.sorted_by.get()
    );
    tableConfig.order_by.set(
      sortField ? sortField : tableConfig.order_by.get()
    );

    getTableData();
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("stock.product.metaTitle")}</title>
      </Helmet>

      <div className={"page-header mb-4"}>
        <h4 className={"title"}>{t("stock.product.title")}</h4>
      </div>

      <div className={"page-content"}>
        <div className="card-paper">
          <div className="card-content has-table">
            <div className={"default-filter-section type-mb-unset"}>
              <div className={"filter-item filter-special"}>
                <div className="input-group prefix-group">
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      className={"icon icon-prefix"}
                      icon={faSearch}
                    />
                  </span>
                  <input
                    defaultValue={tableConfig.search.get()}
                    type="text"
                    className="form-control"
                    placeholder={t("form.search.placeholder")}
                    onChange={handleTableSearch}
                  />
                </div>
              </div>

              <div className={"filter-item"}>
                <div className="form-select-wrapper">
                  <VuiSelect
                    placeholder={t("form.sort.placeholder")}
                    defaultValue={selectedSortBy}
                    options={sortOptions}
                    onChange={(val) => {
                      setSelectedSortBy(val);
                      tableConfig.sorted_by.set(val.id);
                      getTableData();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={"default-filter-section justify-content-start"}>
              <div className={"filter-item"}>
                <VuiSelect
                  placeholder={t("form.brand.forSelect.placeholder")}
                  defaultValue={selectedBrand}
                  // additionalOptions={[{ id: "all", name: "Pilih Semua" }]}
                  selectRepository={BrandRepository}
                  onChange={(val) => {
                    setSelectedBrand(val);
                    tableConfig.brand.set(val.id);
                    getTableData();
                  }}
                />
              </div>

              <div className={"filter-item"}>
                <VuiSelect
                  placeholder={t("form.type.forSelect.placeholder")}
                  defaultValue={selectedType}
                  options={typeProductOptions}
                  onChange={(val) => {
                    setSelectedType(val);
                    tableConfig.type.set(val.id);
                    getTableData();
                  }}
                />
              </div>
            </div>
          </div>

          {user.permissions.includes("product-stock.show") && (
            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={tableColumns}
              data={tableData.get()}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StockProduct;
