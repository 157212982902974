/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { ReactComponent as PeopleIcon } from "../../../assets/icon/setting/icon-people.svg";
import { ReactComponent as UnitIcon } from "../../../assets/icon/setting/icons8-ruler.svg";
import { ReactComponent as UserIcon } from "../../../assets/icon/setting/icons8-user.svg";
import { ReactComponent as BrandIcon } from "../../../assets/icon/setting/icons8-verified-account.svg";
import { ReactComponent as ProductIcon } from "../../../assets/icon/setting/icons8-product.svg";
import { ReactComponent as MaterialIcon } from "../../../assets/icon/setting/icons8-leather.svg";
import { ReactComponent as ColorIcon } from "../../../assets/icon/setting/icons8-paint-palette.svg";
import { ReactComponent as CategoryIcon } from "../../../assets/icon/setting/icons8-folder.svg";
import { ReactComponent as LocationIcon } from "../../../assets/icon/setting/icons8-marker.svg";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores";
import { useTranslation, TFunction } from "react-i18next";

interface SettingCardProps {
  name: string;
  link: string;
  icon: string;
  subtitle: string;
  permission: string;
}

const generateAccountCardLists = (t: TFunction) => {
  return [
    {
      name: t("card.account.title"),
      link: "/setting/account",
      icon: "account",
      permission: "user.index",
      subtitle: t("card.account.subtitle"),
    },
    {
      name: t("card.accessRole.title"),
      link: "/setting/permission",
      icon: "permission",
      permission: "role.index",
      subtitle: t("card.accessRole.subtitle"),
    },
  ];
};

const generateMasterDataCardLists = (t: TFunction) => {
  return [
    {
      name: t("card.brand.title"),
      link: "/setting/brand",
      icon: "brand",
      permission: "brand.index",
      subtitle: t("card.brand.subtitle"),
    },
    {
      name: t("card.product.title"),
      link: "/setting/product",
      icon: "product",
      permission: "product.index",
      subtitle: t("card.product.subtitle"),
    },
    {
      name: t("card.unit.title"),
      link: "/setting/unit",
      icon: "unit",
      permission: "unit.index",
      subtitle: t("card.unit.subtitle"),
    },
    {
      name: t("card.color.title"),
      link: "/setting/color",
      icon: "color",
      permission: "color.index",
      subtitle: t("card.color.subtitle"),
    },
    {
      name: t("card.category.title"),
      link: "/setting/category",
      icon: "category",
      permission: "category.index",
      subtitle: t("card.category.subtitle"),
    },
    {
      name: t("card.material.title"),
      link: "/setting/material",
      icon: "material",
      permission: "material.index",
      subtitle: t("card.material.subtitle"),
    },
    {
      name: t("card.address.title"),
      link: "/setting/address",
      icon: "address",
      permission: "address.index",
      subtitle: t("card.address.subtitle"),
    },
  ];
};

const compareSettingCardWithPermission = (menu: any, permissions: any) => {
  const response: SettingCardProps[] = [];
  menu.forEach((item: any) => {
    if (item.permission === "any") {
      response.push(item);
    } else if (permissions.includes(item.permission)) {
      response.push(item);
    }
  });
  return response;
};

const Setting: React.FC<any> = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const settingMasterDataCardLists: Array<SettingCardProps> =
    generateMasterDataCardLists(t);

  const settingAccountCardLists: Array<SettingCardProps> =
    generateAccountCardLists(t);

  const accountSections = compareSettingCardWithPermission(
    settingAccountCardLists,
    user.permissions
  );

  const masterDataSections = compareSettingCardWithPermission(
    settingMasterDataCardLists,
    user.permissions
  );
  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("setting.metaTitle")}</title>
      </Helmet>

      <div id={"setting-section"}>
        <div className={"page-header-component"}>
          <h3 className={"title"}>{t("setting.title")}</h3>
        </div>

        <div className={"card-setting-title"}>
          {t("setting.section.masterData.title")}
        </div>
        <div className={"card-setting-wrapper"}>
          {masterDataSections.map((item, key) => {
            return (
              <Link to={item.link} key={key}>
                <div className={"card-setting"}>
                  <div className={"inner-content"}>
                    {item.icon === "product" && (
                      <ProductIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "unit" && (
                      <UnitIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "brand" && (
                      <BrandIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "color" && (
                      <ColorIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "category" && (
                      <CategoryIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "material" && (
                      <MaterialIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "address" && (
                      <LocationIcon className={"card-setting-icon"} />
                    )}
                    <div className={"description"}>
                      <h3 className={"title"}>{item.name}</h3>
                      <p className={"subtitle mb-unset"}>{item.subtitle}</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>

        <div className={"card-setting-title"}>
          {t("setting.section.accountSection.title")}
        </div>
        <div className={"card-setting-wrapper"}>
          {accountSections.map((item, key) => {
            return (
              <Link to={item.link} key={key}>
                <div className={"card-setting"}>
                  <div className={"inner-content"}>
                    {item.icon === "account" && (
                      <UserIcon className={"card-setting-icon"} />
                    )}
                    {item.icon === "permission" && (
                      <PeopleIcon className={"card-setting-icon"} />
                    )}
                    <div className={"description"}>
                      <h3 className={"title"}>{item.name}</h3>
                      <p className={"subtitle mb-unset"}>{item.subtitle}</p>
                    </div>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Setting;
