import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartData,
} from "chart.js";
import { CardContent } from "../../../../components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import WidgetRepository from "../../../../repositories/WidgetRepository";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import { Moment } from "moment";
import { IBrand } from "../../../../interfaces/Brand";
import { ResponsiveContainer } from "recharts";

ChartJS.register(ArcElement, Tooltip, Legend);

interface IData {
  id: number;
  name: string;
  total_product: number;
}

const COLORS: string[] = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const defaultValues: ChartData<"pie"> = {
  labels: [],
  datasets: [{ data: [], backgroundColor: [] }],
};

interface IParams {
  date_from?: string | Moment;
  date_to?: string | Moment;
  brand?: number | null;
}

interface IProps {
  props: IParams;
  isChange: boolean;
}

const InProcessProductWidget = ({ props, isChange }: IProps) => {
  const [data, setData] = useState<ChartData<"pie">>(defaultValues);
  const { t } = useTranslation();
  const isMounted = useIsMounted();

  const loadData = useCallback(async (params: IParams) => {
    await WidgetRepository.productInProcess({ ...params })
      .then((response: AxiosResponse<IData[]>) => {
        if (isMounted.current) {
          const { data: responseData } = response;
          setData({
            labels: responseData.map((res) => res.name),
            datasets: [
              {
                data: responseData.map((res) => res.total_product),
                backgroundColor: COLORS,
              },
            ],
          });
        }
      })
      .catch((error: AxiosError) => {});
  }, []);

  useEffect(() => {
    (async () => {
      await loadData(props);
    })();
  }, [isChange]);

  return (
    <CardContent className={"mt-4"}>
      <div className={"mb-4"}>{t("chart.inProcessProduct.title")}</div>
      <Pie
        data={data}
        className={"dashboard-pie-chart"}
        height={266}
        options={{
          responsive: false,
          maintainAspectRatio: true,
          plugins: {
            legend: {
              position: "bottom",
            },
          },
        }}
      />
    </CardContent>
  );
};

export default InProcessProductWidget;
