import * as yup from "yup";
import { TFunction } from "react-i18next";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    brand_id: yup.mixed().required().label(t("form.brand.label")),
    address_id: yup.mixed().required().label(t("form.deliveryAddress.label")),
    order_id: yup.mixed().required().label(t("form.orderNumber.label")),
    sender: yup.string().required().label(t("form.sender.label")),
  };
  return yup.object().shape(shapes);
};
