import React from "react";
import clsx from "clsx";

interface IStatusLabel {
  status: string;
}

const StatusLabel: React.FC<IStatusLabel> = ({ status = "" }) => {
  const statusLowerCase: string = status.toLowerCase();
  return (
    <div
      className={clsx({
        "table-btn-status": true,
        success:
          statusLowerCase === "completed" || statusLowerCase === "arrived",
        failed: statusLowerCase === "in progress",
        processing:
          statusLowerCase === "half completed" ||
          statusLowerCase === "half sent" ||
          statusLowerCase === "in delivery",
      })}
    >
      {status}
    </div>
  );
};

export default StatusLabel;
