import * as yup from "yup";

export type InformationInputs = {
  old_password: string;
  new_password: string;
  new_password_confirmation: string;
};

export const informationSchema = yup.object().shape({
  old_password: yup.string().required().label("Kata sandi saat ini"),
  new_password: yup.string().required().label("Kata sandi baru"),
  new_password_confirmation: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      "Konfirmasi kata sandi tidak sesuai"
    )
    .label("Konfirmasi kata sandi"),
});
