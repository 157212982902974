/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { useState } from "@hookstate/core/dist";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { AxiosError, AxiosResponse } from "axios";
import VuiSelect from "../../../../vodea/@vodea-ui/components/VuiSelect";
import { sortOptions } from "../../../../constants";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import RoleRepository from "../../../../repositories/RoleRepository";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { useDeleteResource } from "../../../../vodea/utilities/hooks/useDeleteResource";
import { ReactComponent as TrashIcon } from "../../../../assets/icon/icons8-trash.svg";
import ModalDeleteItem from "../../../../components/ModalDeleteItem";
import { useTranslation } from "react-i18next";
import TitleHeader from "../../../../components/TitleHeader";
import VuiSearch from "../../../../vodea/@vodea-ui/components/VuiSearch";

const SettingPermission: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const [selectedSortBy, setSelectedSortBy] = React.useState<any>(null);
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const tableData = useState<any[]>([]);
  const [tableIdSelected, setTableIdSelected] = React.useState<number>(0);
  const { handleDelete } = useDeleteResource(RoleRepository);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "desc",
    order_by: "created_at",
    total: 0,
    loading: false,
  });

  const tableColumns = [
    {
      dataField: "name",
      text: t("table.header.role"),
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell: any, row: any) => (
        <div className={"table-btn-wrapper"}>
          {user.permissions.includes("role.update") && (
            <Link
              className={"btn btn-primary for-table mr-unset"}
              to={`/setting/permission/${row.id}`}
            >
              {t("button.detail")}
            </Link>
          )}
          {user.permissions.includes("role.destroy") && (
            <TrashIcon
              className="delete-icon ml-4"
              onClick={() => handleClickDelete(row.id)}
            />
          )}
        </div>
      ),
    },
  ];

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) tableData.set([]);

    RoleRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
          tableConfig.page.set(response.data.meta.current_page);
        }
        changeTableLoading(false);
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError && isMounted.current) {
          showToast(error?.response?.data.message, "error");
          changeTableLoading(false);
        }
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortOrder, sortField }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.sorted_by.set(
      sortOrder ? sortOrder : tableConfig.sorted_by.get()
    );
    tableConfig.order_by.set(
      sortField ? sortField : tableConfig.order_by.get()
    );

    getTableData();
  };

  const handleTableSearch = (value: string) => {
    if (isMounted.current) {
      tableConfig.search.set(value);
      getTableData(true);
    }
  };

  const handleIsOpenDeleteModal = (isOpen: boolean = true) => {
    if (isMounted.current) {
      setOpenDeleteModal(isOpen);
    }
  };

  const handleClickDelete = (rowId: number) => {
    setTableIdSelected(rowId);
    handleIsOpenDeleteModal(true);
  };

  const handleDeleteResource = () => {
    if (isMounted.current) setIsDeleteLoading(true);
    handleDelete(tableIdSelected)
      .then(() => {
        if (isMounted.current) setIsDeleteLoading(false);
        handleIsOpenDeleteModal(false);
        getTableData();
      })
      .catch(() => {
        if (isMounted.current) setIsDeleteLoading(false);
      });
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {t("setting.section.accountSection.accessRole.metaTitle")}
        </title>
      </Helmet>

      <ModalDeleteItem
        onClose={() => handleIsOpenDeleteModal(false)}
        open={openDeleteModal}
        itemName={t("helper.forItemNameDelete.accessRole")}
        isLoading={isDeleteLoading}
        callback={handleDeleteResource}
      />

      <TitleHeader
        title={t("setting.section.accountSection.accessRole.title")}
        withBackUrl={false}
        component={
          user.permissions.includes("role.store") && (
            <div className={"btn-wrapper"}>
              <Link to={"/setting/permission/create"}>
                <button type={"button"} className={"btn btn-primary"}>
                  {t("button.createAccessRole")}
                </button>
              </Link>
            </div>
          )
        }
      />

      <div className={"page-content"}>
        <div className="card-paper">
          <div className="card-content has-table">
            <div className={"default-filter-section"}>
              <div className={"filter-item filter-special"}>
                <VuiSearch
                  value={tableConfig.search.get()}
                  callback={handleTableSearch}
                />
              </div>

              <div className={"filter-item"}>
                <div className="form-select-wrapper">
                  <VuiSelect
                    placeholder={t("form.sort.placeholder")}
                    defaultValue={selectedSortBy}
                    options={sortOptions}
                    onChange={(val) => {
                      setSelectedSortBy(val);
                      tableConfig.sorted_by.set(val.id);
                      getTableData();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          {user.permissions.includes("role.show") && (
            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={tableColumns}
              data={tableData.get()}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingPermission;
