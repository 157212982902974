export const sortOptions = [
  { id: "asc", name: "Asc" },
  { id: "desc", name: "Desc" },
];

export const categoryTypeOptions = [
  { id: "all", name: "All Type" },
  { id: "category", name: "Category" },
  { id: "sub-category", name: "Sub Category" },
];

export const typeProductOptions = [
  { id: "all", name: "All Type" },
  { id: "order", name: "Order" },
  { id: "consignment", name: "Consignment" },
];

export const defaultBrandOptions = [
  { id: 1, name: "All Brand" },
  {
    id: 2,
    name: "Sunsilk",
    image_url: "https://pdbimg.choice.com.au/sunsilk-shampoo_1.jpg",
  },
];

export const dataDummyProduct = [
  {
    id: 1,
    name: "Mini Pouch",
    image:
      "https://glitzco1.sgp1.cdn.digitaloceanspaces.com/public/post/in/ABuyWXt3w4.jpg",
    stock: 20,
    sku: "SKU20X",
  },
  {
    id: 2,
    name: "Sisir",
    image:
      "https://glitzco1.sgp1.cdn.digitaloceanspaces.com/public/post/in/ABuyWXt3w4.jpg",
    stock: 20,
    sku: "SKU20X",
  },
  {
    id: 3,
    name: "Jepit Rambut",
    image:
      "https://glitzco1.sgp1.cdn.digitaloceanspaces.com/public/post/in/ABuyWXt3w4.jpg",
    stock: 20,
    sku: "SKU20X",
  },
  {
    id: 4,
    name: "Lap Tangan",
    image:
      "https://glitzco1.sgp1.cdn.digitaloceanspaces.com/public/post/in/ABuyWXt3w4.jpg",
    stock: 20,
    sku: "SKU20X",
  },
];

export const dataDummyOrder = [
  {
    id: 1,
    created_at: "2021-02-02",
    brand_name: "Sunsilk",
    name: "Lap Tangan",
    unit_name: "Pcs",
    order: 20,
    delivered: 30,
    available: 40,
    on_progress: 20,
    order_number: "PO-21001210",
    number: "DO-2107050010",
    item: "Sisir, 2+",
    status_name: "Selesai",
  },
  {
    id: 2,
    created_at: "2021-02-02",
    brand_name: "Sunsilk",
    name: "Lap Tangan",
    unit_name: "Pcs",
    order: 20,
    delivered: 30,
    available: 40,
    on_progress: 20,
    order_number: "PO-21001210",
    number: "DO-2107050010",
    item: "Sisir, 2+",
    status_name: "Dalam Proses",
  },
  {
    id: 3,
    created_at: "2021-02-02",
    brand_name: "Sunsilk",
    order_number: "PO-21001210",
    name: "Lap Tangan",
    unit_name: "Pcs",
    order: 20,
    delivered: 30,
    available: 40,
    on_progress: 20,
    number: "DO-2107050010",
    item: "Sisir, 2+",
    status_name: "Selesai Sebagian",
  },
  {
    id: 4,
    created_at: "2021-02-02",
    brand_name: "Sunsilk",
    unit_name: "Pcs",
    order: 20,
    delivered: 30,
    name: "Lap Tangan",
    available: 40,
    on_progress: 20,
    number: "DO-2107050010",
    order_number: "PO-21001210",
    item: "Sisir, 2+",
    status_name: "Selesai Sebagian",
  },
];

export const dataDummySetting = [
  {
    id: 1,
    code: "A01",
    type: "Kategori",
    name: "William",
    sku: "SKUX001",
    item_name: "TOTEBAG DREENEER",
    category_name: "Bag - TOTEBAGS",
    role_name: "telemarketing",
    unit_name: "Pcs",
    brand_name: "Sunsilk",
    brand_image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
  {
    id: 2,
    code: "A02",
    name: "Jeff",
    sku: "SKUX001",
    type: "Kategori",
    item_name: "TOTEBAG DREENEER",
    category_name: "Bag - TOTEBAGS",
    role_name: "staff",
    unit_name: "Box",
    brand_name: "Dove",
    brand_image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
  {
    id: 3,
    code: "A03",
    name: "Dollan",
    sku: "SKUX001",
    type: "Sub Kategori",
    item_name: "TOTEBAG DREENEER",
    unit_name: "Pack",
    category_name: "Bag - TOTEBAGS",
    role_name: "telemarketing",
    brand_name: "Clear",
    brand_image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
];

export const permissionOption = [
  { id: 1, name: "Create" },
  { id: 2, name: "Read" },
  { id: 3, name: "Update" },
  { id: 4, name: "Delete" },
];

export const stokProdukData = [
  {
    id: 1,
    transaction_number: "DO-21084564",
    date: "2021-02-02",
    status: "dalam pengiriman",
    qty: -20,
  },
  {
    id: 2,
    transaction_number: "DO-21084564",
    status: "sampai tujuan",
    date: "2021-03-02",
    qty: 30,
  },
  {
    id: 1,
    transaction_number: "DO-21084564",
    date: "2021-04-02",
    qty: 20,
  },
];

export const orderPengiriman = [
  {
    id: 1,
    stock: 20,
    qty: 2000,
    sku: "SKU20X",
    unit: "PCS",
    name: "Lap Tangan",
    image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
  {
    id: 2,
    stock: 20,
    qty: 3000,
    sku: "SKU20X",
    unit: "PCS",
    name: "Lap Tangan",
    image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
  {
    id: 1,
    stock: 20,
    qty: 1000,
    sku: "SKU20X",
    unit: "PCS",
    name: "Lap Tangan",
    image:
      "https://i.pinimg.com/originals/63/b3/e2/63b3e22802ea292c35f2d2b8d16b9f10.png",
  },
];
