import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../stores";
import { usePassportService } from "../../vodea/utilities/hooks";
import VuiLoadingScreen from "../../vodea/@vodea-ui/components/VuiLoadingScreen";

interface HeaderAuthState {
  title: string;
  subtitle?: string;
  logo?: boolean;
}

export const HeaderAuth: React.FC<HeaderAuthState> = ({
  title,
  subtitle,
  logo = true,
}) => {
  return (
    <div className={"auth-header"}>
      <div>
        <h4 className={"title"}>{title}</h4>
        {subtitle && <h6 className={"subtitle"}>{subtitle}</h6>}
      </div>
      {logo && (
        <div>
          <img className={"logo"} src={"/logo.png"} alt={"logo"} />
        </div>
      )}
    </div>
  );
};

const LayoutAuth: React.FC<any> = () => {
  const { isOnFetchingUser } = usePassportService();
  const loggedIn = useSelector((state: RootState) => state.system.loggedIn);

  if (isOnFetchingUser) return <VuiLoadingScreen />;

  if (loggedIn) {
    return <Navigate to={"/dashboard"} replace />;
  }

  return (
    <section id="auth-layout">
      <div className={"container"}>
        <div className={"main-wrapper"}>
          <Outlet />
        </div>
      </div>
    </section>
  );
};

export default LayoutAuth;
