/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import VuiDataTable from "../../../../vodea/@vodea-ui/components/VuiDataTable";
import moment from "moment";
import {
  ColumnDescription,
  TableChangeHandler,
} from "react-bootstrap-table-next";
import { useState } from "@hookstate/core/dist";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import _ from "lodash";
import { $clone } from "../../../../vodea/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faSearch } from "@fortawesome/free-solid-svg-icons";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import VuiSelect from "../../../../vodea/@vodea-ui/components/VuiSelect";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import { Date } from "../../../../components/data";
import GoodReceiptRepository from "../../../../repositories/GoodReceiptRepository";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import ModalDeleteItem from "../../../../components/ModalDeleteItem";
import { useDeleteResource } from "../../../../vodea/utilities/hooks/useDeleteResource";
import { ReactComponent as TrashIcon } from "../../../../assets/icon/icons8-trash.svg";
import { useTranslation } from "react-i18next";

const option = [
  { id: "asc", name: "Asc" },
  { id: "desc", name: "Desc" },
];

const StockReceiveProduct: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const [selectedSortBy, setSelectedSortBy] = React.useState<any>(null);
  const tableData = useState<any[]>([]);
  const [openOrderOptions, setOpenOrderOptions] =
    React.useState<boolean>(false);
  const [tableIdSelected, setTableIdSelected] = React.useState<number>(0);
  const { handleDelete } = useDeleteResource(GoodReceiptRepository);
  const [openDeleteModal, setOpenDeleteModal] = React.useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState<boolean>(false);
  const handleClickCreateOrder = () => setOpenOrderOptions(!openOrderOptions);

  const tableConfig = useState({
    search: "",
    page: 1,
    per_page: 10,
    sorted_by: "desc",
    order_by: "created_at",
    total: 0,
    loading: false,

    // additional
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const tableColumns: ColumnDescription[] = [
    {
      dataField: "date",
      text: t("table.header.date"),
      formatter: (cell: any) => <Date data={cell} />,
    },
    {
      dataField: "number",
      text: t("table.header.number"),
    },
    {
      dataField: "brand_name",
      text: t("table.header.brand"),
    },
    {
      dataField: "order_number",
      text: t("table.header.orderNumber"),
    },
    {
      dataField: "items",
      text: t("table.header.item"),
    },
    {
      dataField: "action",
      text: "",
      formatter: (cell: any, row: any) => {
        const route = row.order_id ? "product-order" : "consignment";
        return (
          <div className={"table-btn-wrapper"}>
            <Link
              className={"btn btn-primary for-table mr-unset"}
              to={`${route}/${row.id}`}
            >
              {t("button.detail")}
            </Link>
            {user.permissions.includes("goods-receipt.destroy") && (
              <TrashIcon
                className="delete-icon ml-4"
                onClick={() => handleClickDelete(row.id)}
              />
            )}
          </div>
        );
      },
    },
  ];

  const handleIsOpenDeleteModal = (isOpen: boolean = true) => {
    if (isMounted.current) {
      setOpenDeleteModal(isOpen);
    }
  };

  const handleClickDelete = (rowId: number) => {
    setTableIdSelected(rowId);
    handleIsOpenDeleteModal(true);
  };

  const changeTableLoading = (val: boolean) => {
    if (isMounted.current) {
      tableConfig.loading.set(val);
    }
  };

  const getTableData = (reset: boolean = false) => {
    if (reset) tableConfig.page.set(1);

    changeTableLoading(true);

    const conf = _.omit($clone(tableConfig.value), "loading");

    let params: any = {};
    Object.keys(conf).forEach((prop) => {
      if (conf[prop]) {
        params[prop] = conf[prop];
      }
    });

    if (isMounted.current) tableData.set([]);

    GoodReceiptRepository.all(params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          tableData.set(response.data.data);
          tableConfig.total.set(response.data.meta.total);
        }
        changeTableLoading(false);
      })
      .catch((e: AxiosError) => {
        if (e.isAxiosError) showToast(e.message, "error");

        if (isMounted.current) changeTableLoading(false);
      });
  };

  useEffect(() => {
    getTableData();
  }, []);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage, sortOrder, sortField }
  ) => {
    tableConfig.page.set(page);
    tableConfig.per_page.set(sizePerPage);
    tableConfig.sorted_by.set(
      sortOrder ? sortOrder : tableConfig.sorted_by.get()
    );
    tableConfig.order_by.set(
      sortField ? sortField : tableConfig.order_by.get()
    );

    getTableData();
  };

  const handleTableSearch = _.debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      tableConfig.search.set(e.target.value);
      tableConfig.page.set(1);

      getTableData();
    },
    300
  );

  const handleTableDateRange = (start: any, end: any) => {
    tableConfig.date_from.set(start.format("YYYY-MM-DD"));
    tableConfig.date_to.set(end.format("YYYY-MM-DD"));

    getTableData();
  };

  const handleDeleteResource = () => {
    if (isMounted.current) setIsDeleteLoading(true);
    handleDelete(tableIdSelected)
      .then(() => {
        if (isMounted.current) setIsDeleteLoading(false);
        handleIsOpenDeleteModal(false);
        getTableData();
      })
      .catch(() => {
        if (isMounted.current) setIsDeleteLoading(false);
      });
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("stock.goodsReceipt.metaTitle")}</title>
      </Helmet>

      <ModalDeleteItem
        onClose={() => handleIsOpenDeleteModal(false)}
        open={openDeleteModal}
        itemName={t("helper.forItemNameDelete.receipt")}
        isLoading={isDeleteLoading}
        callback={handleDeleteResource}
      />

      <div className={"page-header mb-4"}>
        <h4 className={"title"}>{t("stock.goodsReceipt.title")}</h4>
        {user.permissions.includes("goods-receipt.store") && (
          <div className={"btn-wrapper"}>
            <button
              type={"button"}
              className={"btn btn-primary"}
              onClick={handleClickCreateOrder}
            >
              {t("button.createReceipt")}
              <FontAwesomeIcon className={"ml-2"} icon={faChevronRight} />
            </button>
            <div
              className={
                openOrderOptions
                  ? "send-order-option-wrapper open"
                  : "send-order-option-wrapper"
              }
            >
              <div className={"card-paper"}>
                <Link
                  className={"card-option-item"}
                  to={"/stock/receive-product/product-order/create"}
                >
                  {t("button.productOrder")}
                </Link>
                <Link
                  className={"card-option-item"}
                  to={"/stock/receive-product/consignment/create"}
                >
                  {t("button.consignment")}
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className={"page-content"}>
        <div className="card-paper">
          <div className="card-content has-table">
            <div className={"default-filter-section type-mb-unset"}>
              <div className={"filter-item filter-special"}>
                <div className="input-group prefix-group">
                  <span className="input-group-text">
                    <FontAwesomeIcon
                      className={"icon icon-prefix"}
                      icon={faSearch}
                    />
                  </span>
                  <input
                    defaultValue={tableConfig.search.get()}
                    type="text"
                    className="form-control"
                    placeholder={t("form.search.placeholder")}
                    onChange={handleTableSearch}
                  />
                </div>
              </div>

              <div className={"filter-item"}>
                <div className="form-select-wrapper">
                  <VuiSelect
                    placeholder={t("form.sort.placeholder")}
                    defaultValue={selectedSortBy}
                    options={option}
                    onChange={(val) => {
                      setSelectedSortBy(val);
                      tableConfig.sorted_by.set(val.id);
                      getTableData();
                    }}
                  />
                </div>
              </div>
            </div>

            <div className={"default-filter-section"}>
              <div className={"filter-item type-date"}>
                <VuiDateRangePicker
                  startDate={tableConfig.date_from.get()}
                  endDate={tableConfig.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges
                />
              </div>
            </div>
          </div>

          {user.permissions.includes("goods-receipt.show") && (
            <VuiDataTable
              loading={tableConfig.loading.get()}
              columns={tableColumns}
              data={tableData.get()}
              page={tableConfig.page.get()}
              sizePerPage={tableConfig.per_page.get()}
              totalSize={tableConfig.total.get()}
              onTableChange={onTableChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StockReceiveProduct;
