import * as yup from "yup";
import { TFunction } from "react-i18next";

export type InformationInputs = {
  name: string;
  type: string;
  permission_ids: any;
  order: any;
  "order-shipment": any;
  "product-stock": any;
  user: any;
  role: any;
  brand: any;
  product: any;
  unit: any;
  color: any;
  category: any;
  material: any;
  "goods-receipt": any;
  report: any;
  widget: any;
  address: any;
};

export const informationBaseModel: InformationInputs = {
  name: "",
  type: "Laksmana",
  permission_ids: null,
  order: null,
  "order-shipment": null,
  "product-stock": null,
  user: null,
  role: null,
  brand: null,
  product: null,
  unit: null,
  color: null,
  category: null,
  material: null,
  "goods-receipt": null,
  report: null,
  widget: null,
  address: null,
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    name: yup.string().required().label(t("form.name.label")),
  };
  return yup.object().shape(shapes);
};
