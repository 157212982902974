/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import {
  informationBaseModel,
  InformationInputs,
  validationSchema,
} from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import PermissionRepository from "../../../../../repositories/PermissionRepository";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../../vodea/utilities/helpers/global";
import RoleRepository from "../../../../../repositories/RoleRepository";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const SettingPermissionForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const metaTitle = id
    ? t("setting.section.accountSection.account.edit.metaTitle")
    : t("setting.section.accountSection.account.create.metaTitle");
  const title = id
    ? t("setting.section.accountSection.account.edit.title")
    : t("setting.section.accountSection.account.create.title");
  const [loading, setLoading] = React.useState<boolean>(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    control: informationControl,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
  } = useForm<InformationInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const onSubmit = async (data: any) => {
    setLoading(false);
    const formData = formatFormData(data);
    const permissionIds: any[] = [];

    Object.keys(formData).forEach((key: any) => {
      if (key !== "name" && key !== "type" && key !== "permission_ids") {
        formData[key]?.map((item: any) => permissionIds.push(item.id));
        delete formData[key];
      }
    });

    formData.permission_ids = permissionIds;

    if (id) {
      await RoleRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/permission");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    } else {
      await RoleRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/permission");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    }
  };

  const loadData = async () => {
    const params = {
      with: ["gateSetting.permissions"],
    };
    await RoleRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        if (isMounted.current) {
          informationSetValue("name", data.name);
          informationSetValue("type", data.type);
          const permission: any = groupPermissions(
            data?.gate_setting?.permissions
          );
          Object.keys(permission).forEach((key: any) => {
            informationSetValue(key, permission[key]);
          });
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError)
          showToast(error?.response?.data.message, "error");
      });
  };

  const groupPermissions = (permissions: any[]) => {
    return _.groupBy(permissions, function (permission) {
      return permission?.name?.split(".")[0];
    });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/setting/permission")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>{title}</h4>
      </div>
      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={6} lg={6} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.name.label")}</div>
                    <input
                      type={"text"}
                      className={"form-control"}
                      {...informationRegister("name")}
                    />
                    {informationErrors.name && (
                      <span className={"text-danger"}>
                        {informationErrors.name?.message}
                      </span>
                    )}
                  </div>

                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>
                      {t("form.accountType.label")}
                    </div>
                    <Controller
                      render={({ field }) => {
                        return (
                          <div className={"form-check"}>
                            <div className={"d-flex align-items-center"}>
                              <input
                                {...field}
                                type="radio"
                                value="Laksmana"
                                name={field.name}
                                className="form-check-input"
                                checked={field.value == "Laksmana"}
                              />
                              <label className={"form-check-label"}>
                                {t("form.accountType.forRadio.laksmana")}
                              </label>
                            </div>
                            <div className={"d-flex align-items-center"}>
                              <input
                                {...field}
                                type="radio"
                                value="Client"
                                name={field.name}
                                className="form-check-input"
                                checked={field.value == "Client"}
                              />
                              <label className={"form-check-label"}>
                                {t("form.accountType.forRadio.client")}
                              </label>
                            </div>
                          </div>
                        );
                      }}
                      name={"type"}
                      control={informationControl}
                    />
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.order.label")}</div>
                    <Controller
                      name={"order"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "order" }}
                            labelKey={"method_name"}
                            defaultValue={field.value}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.deliveryOrder.label")}
                    </div>
                    <Controller
                      name={"order-shipment"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "order-shipment" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti={true}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.stockProduct.label")}
                    </div>
                    <Controller
                      name={"product-stock"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "product-stock" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti={true}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.user.label")}</div>
                    <Controller
                      name={"user"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "user" }}
                            labelKey={"method_name"}
                            defaultValue={field.value}
                            onChange={field.onChange}
                            isMulti={true}
                          />
                        );
                      }}
                    />
                  </div>

                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.accessRole.label")}
                    </div>
                    <Controller
                      name={"role"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "role" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>

                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "brand" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.product.label")}
                    </div>
                    <Controller
                      name={"product"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "product" }}
                            defaultValue={field.value}
                            onChange={field.onChange}
                            labelKey={"method_name"}
                            isMulti={true}
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.unit.label")}</div>
                    <Controller
                      name={"unit"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "unit" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.color.label")}</div>
                    <Controller
                      name={"color"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "color" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.category.label")}
                    </div>
                    <Controller
                      name={"category"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "category" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.material.label")}
                    </div>
                    <Controller
                      name={"material"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "material" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.goodsReceipt.label")}
                    </div>
                    <Controller
                      name={"goods-receipt"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "goods-receipt" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.report.label")}</div>
                    <Controller
                      name={"report"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "report" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.widget.label")}</div>
                    <Controller
                      name={"widget"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "widget" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>

                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.address.label")}
                    </div>
                    <Controller
                      name={"address"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={PermissionRepository}
                            selectParams={{ type: "address" }}
                            defaultValue={field.value}
                            labelKey={"method_name"}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className={"card-paper mt-4"}>
                <div className={"card-content type-button"}>
                  <div className={"button-submit-wrapper"}>
                    <div className={"label-wrapper"}>
                      <div className={"title"}>
                        {id
                          ? t("helper.global.saveUpdateWithMark")
                          : t("helper.global.createBrandWithMark")}
                      </div>
                    </div>
                    <div className={"button-group-wrapper"}>
                      <div className={"button-group"}>
                        <div
                          className={"btn btn-light btn-sm"}
                          onClick={() => navigate("/setting/permission")}
                        >
                          {t("button.cancel")}
                        </div>
                      </div>
                      <VuiButton
                        forSubmit
                        label={id ? t("button.save") : t("button.create")}
                        loading={loading}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
};

export default SettingPermissionForm;
