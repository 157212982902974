import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { HeaderAuth } from "../../../../layouts/Auth";
import { useTranslation } from "react-i18next";

const ForgotPasswordSuccess: React.FC<any> = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t("forgotPasswordSuccess.metaTitle")}</title>
      </Helmet>
      <HeaderAuth
        title={t("forgotPasswordSuccess.title")}
        subtitle={t("forgotPasswordSuccess.subtitle")}
        logo={false}
      />
      <div className={"d-flex align-items-center justify-content-center"}>
        <Link to={"/auth/login"}>
          <button className={"btn btn-primary"}>
            {t("forgotPasswordSuccess.login")}
          </button>
        </Link>
      </div>
      <div className={"copyright"}>© 2021 PT Laksmana Mulia Sentosa</div>
    </>
  );
};

export default ForgotPasswordSuccess;
