import { systemReducer } from "./system/reducers";
import { combineReducers } from "redux";
import { formatterReducer } from "./formatter/reducers";

export const rootReducer = combineReducers({
  system: systemReducer,
  formatter: formatterReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
