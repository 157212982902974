import { AsyncPaginate } from "react-select-async-paginate";
import loadAsyncOptions from "./loadAsyncOptions";
import React from "react";
import _ from "lodash";
import Select from "react-select";

interface VuiSelectProps {
  propKey?: any;
  isMulti?: boolean;
  defaultValue?: any;
  selectRepository?: object;
  selectParams?: object;
  options?: Array<any>;
  max?: number;
  isDisabled?: boolean;
  optionKey?: string;
  labelKey?: string;
  clearable?: boolean;
  onChange?: (e: any) => void;
  placeholder?: string;
  additionalOptions?: Array<any>;
  hideDropdownIcon?: boolean;
  useAsync?: boolean;
  cacheUniq?: number;
}

const dot = (image: any) => ({
  alignItems: "center",
  display: "flex",

  ":before": {
    borderRadius: "50%",
    content: '""',
    background: image ? `url(${image}) no-repeat` : "",
    backgroundSize: "cover",
    backgroundPosition: "center",
    display: "block",
    marginRight: 8,
    height: 25,
    width: 25,
  },
});

export const customStyles = (hideIcon: boolean) => {
  let defaultStyles: any = {
    control: (provided: any) => ({
      ...provided,
      borderRadius: 10,
    }),
    indicatorSeparator: (provided: any) => ({
      ...provided,
      width: 0,
    }),
    singleValue: (styles: any, { data }: any) => {
      if (data.logo_url) {
        return { ...styles, ...dot(data.logo_url) };
      } else if (data?.logo?.url) {
        return { ...styles, ...dot(data?.logo?.url) };
      } else {
        return { ...styles };
      }
    },
  };

  const dropdownStyles = {
    dropdownIndicator: () => (provided: any) => ({
      ...provided,
      display: "none",
    }),
    indicatorsContainer: () => (provided: any) => ({
      ...provided,
      width: 9,
    }),
  };

  if (hideIcon) {
    defaultStyles = {
      ...defaultStyles,
      ...dropdownStyles,
    };
  }

  return defaultStyles;
};

const VuiSelect: React.FC<VuiSelectProps> = ({
  propKey = "1",
  isMulti = false,
  defaultValue = null,
  useAsync = true,
  selectRepository = null,
  selectParams = {},
  options = [],
  max = 0,
  hideDropdownIcon = false,
  isDisabled = false,
  optionKey = "id",
  labelKey = "name",
  clearable = false,
  onChange,
  placeholder = "Select..",
  additionalOptions = [],
  cacheUniq = 0,
}) => {
  const handleChange = (val: any) => {
    if (
      !Boolean(max) ||
      (max ? _.get(defaultValue, "length", 0) < max : true)
    ) {
      if (onChange) {
        onChange(val);
      }
    }
  };

  const loadOptions = async (
    search: string,
    prevOptions: any,
    { page }: any
  ) => {
    if (options?.length) {
      let filteredOptions;
      if (!search) {
        filteredOptions = options;
      } else {
        const searchLower = search.toLowerCase();

        filteredOptions = options.filter(({ label }) =>
          label.toLowerCase().includes(searchLower)
        );
      }

      return await {
        options: filteredOptions,
        hasMore: false,
      };
    } else {
      return await loadAsyncOptions(
        search,
        prevOptions,
        page,
        selectRepository,
        selectParams,
        additionalOptions
      );
    }
  };

  return (
    <div className={"form-select-wrapper"}>
      {useAsync ? (
        <AsyncPaginate
          key={JSON.stringify(propKey)}
          classNamePrefix="select"
          isClearable={clearable}
          isMulti={isMulti}
          value={defaultValue}
          placeholder={placeholder}
          getOptionValue={(options: any) => options[optionKey]}
          getOptionLabel={(options: any) => options[labelKey]}
          loadOptions={loadOptions}
          onChange={handleChange}
          styles={customStyles(hideDropdownIcon)}
          menuPortalTarget={document.body}
          isDisabled={isDisabled}
          cacheUniqs={[cacheUniq]}
          additional={{
            page: 1,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#2B2C6D",
            },
          })}
        />
      ) : (
        <Select
          key={JSON.stringify(propKey)}
          classNamePrefix="select"
          isClearable={clearable}
          isMulti={isMulti}
          value={defaultValue}
          placeholder={placeholder}
          options={[...additionalOptions, ...options]}
          getOptionValue={(options: any) => options[optionKey]}
          getOptionLabel={(options: any) => options[labelKey]}
          onChange={handleChange}
          styles={customStyles(hideDropdownIcon)}
          menuPortalTarget={document.body}
          isDisabled={isDisabled}
          additional={{
            page: 1,
          }}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#2B2C6D",
            },
          })}
        />
      )}
    </div>
  );
};

export default VuiSelect;
