/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { AxiosError, AxiosResponse } from "axios";
import { VuiButton } from "../../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../../vodea/utilities/helpers/form";
import {
  mapHookErrors,
  showToast,
} from "../../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../../vodea/utilities/hooks/useIsMounted";
import moment from "moment";
import VuiNumberFormat from "../../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import EditIcon from "../../../../../../assets/icon/icons8-edit.svg";
import VuiCardIcon from "../../../../../../vodea/@vodea-ui/components/VuiCardIcon";
import VuiUploadImage from "../../../../../../vodea/@vodea-ui/components/VuiUploadImage";
import SendOrderRepository from "../../../../../../repositories/SendOrderRepository";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores";
import StatusLabel from "../../../../../../components/StatusLabel";

const SendOrderConsignmentDetail: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const data = useState<any[]>([]);
  const loading = useState(false);
  const tableData = useState<any[]>([]);
  const status = _.get(data.get(), "status");
  const statusLowerCase = status?.toLowerCase();
  const isCompleted = statusLowerCase === "sampai tujuan";

  const { register, handleSubmit, control, setValue } = useForm({});

  const summaryData = useState({
    totalItems: 0,
    totalQuantity: 0,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const params = {
      with: [
        "photo",
        "brand.logo",
        "orderShipmentDetails.orderDetail",
        "orderShipmentDetails.product",
        "orderShipmentDetails.unit",
        "shipmentAddress",
      ],
    };
    await SendOrderRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          data.set(responseData);
          tableData.set(responseData.order_shipment_details);
          setValue("recipient", responseData.recipient);
          setValue("photo_id", responseData.photo);
          summaryData.batch((s) => {
            s.totalItems.set(responseData.total_items);
            s.totalQuantity.set(responseData.total_quantity);
          });
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const onSubmit = handleSubmit(async (data) => {
    if (isMounted.current) loading.set(true);
    const formData = formatFormData(data);

    await SendOrderRepository.confirm(id, formData)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          loading.set(false);
          showToast(response.data.message, "success");
          setOpenModal(false);
          loadData();
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          loading.set(false);
          showToast(error?.response?.data.message, "error");
          if (error?.response?.data?.errors.length > 0) {
            const errors = mapHookErrors(error?.response?.data?.errors);
            Object.keys(errors).forEach((key: any) => errors(key, errors[key]));
          }
        }
      });
  });

  const renderStatus = () => {
    if (Boolean(status)) {
      return <StatusLabel status={status} />;
    }

    return null;
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("deliveryOrder.detailDelivery.metaTitle")}</title>
      </Helmet>

      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/order/send-order")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {t("deliveryOrder.delivery")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("deliveryOrder.consignment.title")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{_.get(data.get(), "number")}</span>}
        </h4>
        {Boolean(id) && renderStatus()}

        <div className={"ml-auto d-flex align-items-center"}>
          {user.permissions.includes("order-shipment.update") && (
            <Link to={"edit"}>
              <VuiCardIcon icon={EditIcon} primary={false} />
            </Link>
          )}

          <button
            className={"btn btn-primary ml-4"}
            onClick={() => setOpenModal(true)}
          >
            {isCompleted ? t("button.deliveryDetail") : t("button.productSent")}
          </button>
        </div>
      </div>

      <div className="page-content">
        <div className={"card-paper"}>
          <div className={"card-content"}>
            <div className={"default-filter-section align-items-unset"}>
              <div className={"filter-item type-input-select mb-unset"}>
                <div className={"form-group"}>
                  <div className={"form-label"}>{t("form.brand.label")}</div>
                  <div className={"display-flex align-items-center"}>
                    {_.get(data.get(), "brand.logo.url") && (
                      <img
                        className={"image-wrap mr-2"}
                        src={_.get(data.get(), "brand.logo.url")}
                        alt={_.get(data.get(), "brand.logo.name")}
                      />
                    )}
                    <strong>{_.get(data.get(), "brand.name")}</strong>
                  </div>
                </div>
                <div className={"form-group mb-unset"}>
                  <div className={"form-label"}>
                    {t("form.deliveryAddress.label")}
                  </div>
                  <div>
                    <strong>
                      {_.get(data.get(), "shipment_address.name") ||
                        _.get(data.get(), "address")}
                    </strong>
                  </div>
                </div>
              </div>

              <div className={"filter-item type-half-full mb-unset"}>
                <Row>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.deliveryNumber.label")}
                      </label>
                      <div>
                        <strong>{_.get(data.get(), "number")}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.deliveryDate.label")}
                      </label>
                      <div>
                        <strong>
                          {moment(_.get(data.get(), "date")).format(
                            "DD MMMM YYYY"
                          )}
                        </strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.sender.label")}
                      </label>
                      <div>
                        <strong>{_.get(data.get(), "sender")}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.note.label")}
                      </label>
                      <div>
                        <strong>{_.get(data.get(), "note")}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group mb-unset"}>
                      <label className={"form-label"}>
                        {t("form.receiver.label")}
                      </label>
                      <div>
                        <strong>
                          {_.get(data.get(), "recipient", "-") || "-"}
                        </strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className={"card-paper mt-4"}>
          <div id={"table-custom-wrapper"}>
            <table className={"table table-custom"}>
              <thead>
                <tr>
                  <th>{t("form.img.label")}</th>
                  <th>{t("form.sku.label")}</th>
                  <th>{t("form.product.label")}</th>
                  <th>{t("form.description.label")}</th>
                  <th>{t("form.qty.label")}</th>
                  <th>{t("form.unit.label")}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.value.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item?.product?.photo?.url && (
                          <img
                            className={"table-product-image"}
                            src={item?.product?.photo?.url}
                            alt={item?.product?.photo?.name}
                          />
                        )}
                      </td>
                      <td>{item?.product?.sku}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.description}</td>
                      <td>
                        <VuiNumberFormat
                          hasPrefix={false}
                          data={item?.quantity}
                          value={item?.quantity}
                        />
                      </td>
                      <td>{item?.unit?.name}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={"card-content mt-4 pr-12"}>
            <Row>
              <Col md={9} lg={9} xs={12} />
              <Col md={3} lg={3} xs={12}>
                <table className="table-summary d-flex justify-content-around">
                  <tbody>
                    <tr>
                      <td>{t("helper.global.totalItem")}</td>
                      <td>
                        <VuiNumberFormat
                          value={summaryData.totalItems.get()}
                          data={summaryData.totalItems.get()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("helper.global.totalQuantity")}</td>
                      <td>
                        <VuiNumberFormat
                          value={summaryData.totalQuantity.get()}
                          data={summaryData.totalQuantity.get()}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        show={openModal}
        centered
        className={"modal-product-detail"}
        onHide={() => setOpenModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className={"modal-product-body"}>
          <div className={"close-btn"}>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => setOpenModal(false)}
            />
          </div>

          <div className={"title"}>
            {isCompleted
              ? t("deliveryOrder.detailDelivery.title")
              : t("modal.deliveryOrder.title")}
          </div>
          <div className={"subtitle"}>
            <strong>{_.get(data.get(), "number")}</strong>
          </div>
          <form className={"mt-4"}>
            <div className={"form-group"}>
              <div className={"form-label"}>{t("form.receiverName.label")}</div>
              <input
                type="text"
                disabled={isCompleted}
                className={"form-control"}
                {...register("recipient")}
              />
            </div>
            <div className={"form-group"}>
              <div className={"form-label"}>
                {t("form.uploadDeliveryNote.label")}
              </div>
              <Controller
                name={"photo_id"}
                control={control}
                render={({ field }) => {
                  return (
                    <VuiUploadImage
                      height={80}
                      disabled={isCompleted}
                      width={100}
                      className={"full-template"}
                      defaultValue={field.value}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
            </div>

            {!isCompleted && (
              <div
                className={
                  "display-flex align-items-center justify-content-center"
                }
              >
                <div className={"button-group"}>
                  <div
                    className={"btn btn-light btn-sm mr-4"}
                    onClick={() => setOpenModal(false)}
                  >
                    {t("button.cancel")}
                  </div>
                </div>
                <div className={"button-group"}>
                  <VuiButton
                    onClick={onSubmit}
                    customClass={"btn-small"}
                    label={t("button.save")}
                    loading={loading.get()}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SendOrderConsignmentDetail;
