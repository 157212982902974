import * as yup from "yup";
import { TFunction } from "react-i18next";
import { IAddress } from "../../../../../interfaces/Address";

export type SendOrderKonsinyasiInputs = {
  brand_id: object | null;
  address: string;
  date: any;
  products: Products[];
  sender: string;
  address_id: IAddress | number | null;
  note: string;
};

export type Products = {
  image?: any;
  sku: object | null;
  description: string | null;
  product_id: object | null;
  quantity: number | null;
  available?: number | null;
  unit_name?: string | null;
};

export const productBaseModel: Products = {
  sku: null,
  product_id: null,
  quantity: null,
  description: "",
  image: null,
  available: null,
  unit_name: null,
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    brand_id: yup.mixed().required().label(t("form.brand.label")),
    address_id: yup.mixed().required().label(t("form.deliveryAddress.label")),
    sender: yup.string().required().label(t("form.sender.label")),
  };
  return yup.object().shape(shapes);
};
