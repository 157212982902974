import * as validator from "yup";
import { TFunction } from "react-i18next";

export type InformationInputs = {
  name: string;
  sku: string;
  description: string;
  brand_id: any;
};

export const informationBaseModel: InformationInputs = {
  name: "",
  sku: "",
  description: "",
  brand_id: null,
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    name: validator.string().required().label(t("form.name.label")),
    sku: validator.string().required().label(t("form.sku.label")),
    brand_id: validator.mixed().required().label(t("form.brand.label")),
  };
  return validator.object().shape(shapes);
};
