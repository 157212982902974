import LayoutAuth from "../layouts/Auth";
import Login from "../pages/Auth/Login";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import ForgotPasswordSuccess from "../pages/Auth/ForgotPassword/Success";
import ResetPassword from "../pages/Auth/ResetPassword";

const authRoutes = [
  {
    path: "/auth",
    element: <LayoutAuth />,
    children: [
      { path: "/login", element: <Login /> },
      {
        path: "/forgot-password",
        children: [
          { path: "/", element: <ForgotPassword /> },
          { path: "/success", element: <ForgotPasswordSuccess /> },
        ],
      },
      { path: "/reset-password", element: <ResetPassword /> },
    ],
  },
];

export default authRoutes;
