import * as yup from "yup";
import { TFunction } from "react-i18next";

export type StockReceiveProductKonsinyasiInputs = {
  brand_id: object | null;
  date: any;
  receipt_items: Products[];
  description: string;
};

export type Products = {
  photo_id: any;
  sku: any;
  product_id: any;
  description: string;
  quantity: number | null;
  unit_id: object | null;
};

export const StockReceiveProductKonsinyasiBaseModel: Products = {
  photo_id: null,
  sku: null,
  product_id: null,
  description: "",
  quantity: null,
  unit_id: null,
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    brand_id: yup.mixed().required().label(t("form.brand.label")),
    date: yup.mixed().required().label(t("form.deliveryDate.label")),
    description: yup.string().required().label(t("form.note.label")),
  };
  return yup.object().shape(shapes);
};
