/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo } from "react";
import { useState } from "@hookstate/core/dist";
import { Helmet } from "react-helmet-async";
import VuiSelect from "../../../vodea/@vodea-ui/components/VuiSelect";
import VuiDateRangePicker from "../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import moment, { Moment } from "moment";
import { Button, Col, Row } from "react-bootstrap";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import VuiCard from "../../../vodea/@vodea-ui/components/VuiCard";
import BootstrapTable from "react-bootstrap-table-next";
import clsx from "clsx";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import VuiCardIcon from "../../../vodea/@vodea-ui/components/VuiCardIcon";
import VuiBarChartIcon from "../../../assets/icon/icons8-bar-chart.svg";
import BrandRepository from "../../../repositories/BrandRepository";
import VuiLoadingScreen from "../../../vodea/@vodea-ui/components/VuiLoadingScreen";
import WidgetRepository from "../../../repositories/WidgetRepository";
import { $clone } from "../../../vodea/utilities";
import { AxiosResponse } from "axios";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import { useSelector } from "react-redux";
import {
  LoadingIndication,
  NoDataIndication,
} from "../../../vodea/@vodea-ui/components/VuiDataTable";
import VuiNumberFormat from "../../../vodea/@vodea-ui/components/VuiNumberFormat";
import ModalImage from "../../../components/ModalImage";
import { RootState } from "../../../stores";
import { useTranslation } from "react-i18next";
import InProcessProductWidget from "./InProcessProductWidget";
import { IBrand } from "../../../interfaces/Brand";

interface ChartDataInterface {
  label?: string;
  items_in?: number | string;
  items_out?: number | string;
  Masuk?: number | string;
  Keluar?: number | string;
}

interface ChartTotalDataInterface {
  items_in: number | string;
  items_out: number | string;
}

interface IConfig {
  date_from: string | Moment;
  date_to: string | Moment;
}

//todo: jika sempat buat semua interface form, detail sama index
const Dashboard: React.FC<any> = () => {
  const [brand, setBrand] = React.useState<IBrand | null>(null);
  const isMounted = useIsMounted();
  const { t } = useTranslation();
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [imageURL, setImageURL] = React.useState<string>("");
  const { date } = useSelector(({ formatter }: any) => ({
    date: formatter.date,
  }));

  const { user } = useSelector((state: RootState) => {
    return state.system;
  });

  // loading
  const [tableKonsinyasiLoading, setTableKonsinyasiLoading] =
    React.useState<boolean>(false);
  const [tableProductOrderLoading, setTableProductOrderLoading] =
    React.useState<boolean>(false);
  const [cardOrderFinishLoading, setCardOrderFinishLoading] =
    React.useState<boolean>(false);
  const [cardOrderDeliveryLoading, setCardOrderDeliveryLoading] =
    React.useState<boolean>(false);
  const [cardOrderHalfFinishLoading, setCardOrderHalfFinishLoading] =
    React.useState<boolean>(false);
  const [cardOrderProcessLoading, setCardOrderProcessLoading] =
    React.useState<boolean>(false);
  const [chartMovementProductLoading, setChartMovementProductLoading] =
    React.useState<boolean>(false);

  // data
  const [tableKonsinyasiData, setTableKonsinyasiData] = React.useState<any[]>(
    []
  );
  const [tableProductOrderData, setTableProductOrderData] = React.useState<
    any[]
  >([]);
  const [cardOrderFinish, setCardOrderFinish] = React.useState<number>(0);
  const [cardOrderProcess, setCardOrderProcess] = React.useState<number>(0);
  const [cardOrderHalfFinish, setCardOrderHalfFinish] =
    React.useState<number>(0);
  const [cardOrderDelivery, setCardOrderDelivery] = React.useState<number>(0);
  const [chartMovementProductData, setChartMovementProductData] =
    React.useState<ChartDataInterface[]>([]);
  const [totalChartMovementProductData, setTotalChartMovementProductData] =
    React.useState<ChartTotalDataInterface>({
      items_in: 0,
      items_out: 0,
    });

  const tableColumns = [
    {
      dataField: "sku",
      text: t("table.header.sku"),
    },
    {
      dataField: "name",
      text: t("table.header.product"),
    },
    {
      dataField: "total",
      text: t("table.header.stock"),
      formatter: (cell: any) => (
        <VuiNumberFormat data={cell} value={cell} hasPrefix={false} />
      ),
    },
  ];

  const config = useState<IConfig>({
    date_from: moment().subtract(7, "days").format("YYYY-MM-DD"),
    date_to: moment().format("YYYY-MM-DD"),
  });

  const handleTableDateRange = (start: any, end: any) => {
    config.date_from.set(start.format("YYYY-MM-DD"));
    config.date_to.set(end.format("YYYY-MM-DD"));

    loadData();
  };

  const loadData = useCallback(() => {
    if (isMounted.current) {
      // loading
      setTableKonsinyasiLoading(true);
      setTableProductOrderLoading(true);
      setChartMovementProductLoading(true);
      setCardOrderDeliveryLoading(true);
      setCardOrderProcessLoading(true);
      setCardOrderHalfFinishLoading(true);
      setCardOrderFinishLoading(true);

      // reset value
      setTableKonsinyasiData([]);
      setTableProductOrderData([]);
      setChartMovementProductData([]);
    }

    let params: any = {};
    const conf = $clone(config.get());

    Object.keys(conf).forEach((key: any) => {
      if (conf[key]) {
        params[key] = conf[key];
      }
    });

    Object.assign(params, {
      brand: brand?.id,
    });

    WidgetRepository.cardOrderFinish(params).then((response: AxiosResponse) => {
      if (isMounted.current) {
        setCardOrderFinishLoading(false);
        setCardOrderFinish(response.data);
      }
    });

    WidgetRepository.cardOrderHalfFinish(params).then(
      (response: AxiosResponse) => {
        if (isMounted.current) {
          setCardOrderHalfFinishLoading(false);
          setCardOrderHalfFinish(response.data);
        }
      }
    );

    WidgetRepository.cardOrderProcess(params).then(
      (response: AxiosResponse) => {
        if (isMounted.current) {
          setCardOrderProcessLoading(false);
          setCardOrderProcess(response.data);
        }
      }
    );

    WidgetRepository.cardOrderDelivery(params).then(
      (response: AxiosResponse) => {
        if (isMounted.current) {
          setCardOrderDeliveryLoading(false);
          setCardOrderDelivery(response.data);
        }
      }
    );

    WidgetRepository.mostStockOrder({
      ...params,
      per_page: 5,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        setTableProductOrderLoading(false);
        setTableProductOrderData(response.data.data);
      }
    });

    WidgetRepository.mostStockConsignment({
      ...params,
      per_page: 5,
    }).then((response: AxiosResponse) => {
      if (isMounted.current) {
        setTableKonsinyasiLoading(false);
        setTableKonsinyasiData(response.data.data);
      }
    });

    WidgetRepository.productMovement(params).then((response: AxiosResponse) => {
      const data = response.data.data;
      if (isMounted.current) {
        setChartMovementProductLoading(false);
        setTotalChartMovementProductData({
          items_in: data.items_in,
          items_out: data.items_out,
        });
        const chartData: ChartDataInterface[] = [];
        data.items_per_day.forEach((item: any) => {
          chartData.push({
            label: moment(item.label).format(date),
            items_in: item.items_in,
            items_out: item.items_out,
            Masuk: item.items_in,
            Keluar: item.items_out,
          });
        });
        setChartMovementProductData(chartData);
      }
    });
  }, [brand]);

  useMemo(() => {
    loadData();
  }, []);

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  const [isChange, setIsChange] = React.useState<boolean>(false);

  return (
    <div className={"page-layout"} id={"dashboard-section"}>
      <Helmet>
        <title>{t("dashboard.metaTitle")}</title>
      </Helmet>

      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <h4 className={"title"}>{t("dashboard.title")}</h4>
      <h5 className={"subtitle"}>
        {t("dashboard.greeting")}, {user.name}
      </h5>
      <div className={"page-content"}>
        <div className={"card-paper mt-4"}>
          <div className={"card-content not-full"}>
            <div className={"default-filter-section justify-content-start"}>
              <div className={"filter-item mr-4"}>
                <div className={"mb-2"}>{t("form.brand.label")}</div>
                <VuiSelect
                  selectRepository={BrandRepository}
                  defaultValue={brand}
                  onChange={(value) => {
                    setBrand(value);
                  }}
                  additionalOptions={[{ id: "All", name: "All Brand" }]}
                />
              </div>
              <div className={"filter-item filter-special"}>
                <div className={"mb-2"}>{t("form.dateRange.label")}</div>
                <VuiDateRangePicker
                  startDate={config.date_from.get()}
                  endDate={config.date_to.get()}
                  callback={handleTableDateRange}
                  useRanges
                />
              </div>
              <div className={"filter-item type-button ml-4"}>
                <Button variant={"light"}>{t("button.reset")}</Button>
              </div>
              <div className={"filter-item type-button-full"}>
                <VuiButton
                  label={t("button.apply")}
                  onClick={() => {
                    loadData();
                    setIsChange(!isChange);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={"card-status-section-wrapper mt-4"}>
          {user.permissions.includes("widget.order-finish") && (
            <VuiCard
              label={t("card.completed")}
              icon={"timer"}
              data={cardOrderFinish}
              loading={cardOrderFinishLoading}
            />
          )}
          {user.permissions.includes("widget.order-process") && (
            <VuiCard
              label={t("card.inProcess")}
              icon={"watch"}
              data={cardOrderProcess}
              loading={cardOrderProcessLoading}
            />
          )}
          {user.permissions.includes("widget.order-half-finish") && (
            <VuiCard
              label={t("card.halfCompleted")}
              icon={"deadline"}
              data={cardOrderHalfFinish}
              loading={cardOrderHalfFinishLoading}
            />
          )}
          {user.permissions.includes("widget.order-delivery") && (
            <VuiCard
              label={t("card.halfSent")}
              icon={"truck"}
              data={cardOrderDelivery}
              loading={cardOrderDeliveryLoading}
            />
          )}
        </div>

        <Row>
          {user.permissions.includes("widget.most-stock-order") && (
            <Col md={6} xs={12} lg={6}>
              <div className={"card-paper mt-4"}>
                <div className={"card-content"}>
                  <strong>{t("table.title.productOrder")}</strong>
                  <span> {t("table.subtitle.withHighestStock")}</span>
                </div>
                <BootstrapTable
                  keyField={"id"}
                  wrapperClasses={"table-responsive"}
                  bodyClasses={clsx({
                    loading: tableProductOrderLoading,
                  })}
                  headerClasses={"table-header"}
                  columns={tableColumns}
                  data={tableProductOrderData}
                  bordered={false}
                  noDataIndication={() =>
                    tableProductOrderLoading ? (
                      <LoadingIndication />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                />
              </div>
            </Col>
          )}
          {user.permissions.includes("widget.most-stock-consignment") && (
            <Col md={6} xs={12} lg={6}>
              <div className={"card-paper mt-4"}>
                <div className={"card-content"}>
                  <strong>{t("table.title.productConsignment")}</strong>
                  <span> {t("table.subtitle.withHighestStock")}</span>
                </div>
                <BootstrapTable
                  keyField={"id"}
                  wrapperClasses={"table-responsive"}
                  bodyClasses={clsx({
                    loading: tableKonsinyasiLoading,
                  })}
                  headerClasses={"table-header"}
                  columns={tableColumns}
                  data={tableKonsinyasiData}
                  bordered={false}
                  noDataIndication={() =>
                    tableKonsinyasiLoading ? (
                      <LoadingIndication />
                    ) : (
                      <NoDataIndication />
                    )
                  }
                />
              </div>
            </Col>
          )}
        </Row>

        <Row>
          {user.permissions.includes("widget.product-summary") && (
            <Col md={6} xs={12}>
              <div className={"card-paper mt-4"}>
                <div className={"card-content"}>
                  {chartMovementProductLoading ? (
                    <VuiLoadingScreen className={"height-unset"} />
                  ) : (
                    <>
                      <div className={"d-flex justify-content-between mb-4"}>
                        <div>
                          <p className={"mb-2"}>
                            {t("chart.movementProduct.title")}
                          </p>
                          <p className={"text-warning mb-2 text-bold"}>
                            {t("chart.movementProduct.in")}
                            <span className={"text-warning text-bold ml-4"}>
                              {totalChartMovementProductData.items_in}
                            </span>
                          </p>
                          <p className={"text-primary mb-2 text-bold"}>
                            {t("chart.movementProduct.out")}
                            <span className={"text-primary text-bold ml-4"}>
                              {totalChartMovementProductData.items_out}
                            </span>
                          </p>
                        </div>
                        <VuiCardIcon icon={VuiBarChartIcon} />
                      </div>
                      <ResponsiveContainer width={"100%"} height={200}>
                        <LineChart
                          margin={{ left: -20 }}
                          data={chartMovementProductData}
                        >
                          <XAxis dataKey="label" />
                          <YAxis />
                          <Tooltip />
                          <Line
                            type="monotone"
                            dataKey={`${t("chart.movementProduct.in")}`}
                            stroke="#f4901c"
                          />
                          <Line
                            type="monotone"
                            dataKey={`${t("chart.movementProduct.out")}`}
                            stroke="#2B2C6D"
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    </>
                  )}
                </div>
              </div>
            </Col>
          )}

          {user.permissions.includes("widget.product-in-process") && (
            <Col md={6} xs={12}>
              <InProcessProductWidget
                props={{
                  ...$clone(config.get()),
                  brand: brand?.id,
                }}
                isChange={isChange}
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default Dashboard;
