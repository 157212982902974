import React, { useCallback, useMemo } from "react";
import { Helmet } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import VuiDataTable from "../../../../../vodea/@vodea-ui/components/VuiDataTable";
import NumberFormat from "react-number-format";
import VuiNumberFormat from "../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import StockProductRepository from "../../../../../repositories/StockProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  handleResponseError,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { useState } from "@hookstate/core/dist";
import _ from "lodash";
import { TableChangeHandler } from "react-bootstrap-table-next";
import { DataInterface, TableInterface } from "./interface";
import { $clone } from "../../../../../vodea/utilities";
import { Date, DateTime } from "../../../../../components/data";
import ModalImage from "../../../../../components/ModalImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../stores";
import TitleHeader from "../../../../../components/TitleHeader";
import fileDownload from "js-file-download";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { useTranslation } from "react-i18next";

const CardDetailStock = ({ label, total }: any) => {
  return (
    <div className={"card-paper"}>
      <div className={"card-content"}>
        <div className={"card-detail-stock"}>
          <h3 style={{ fontSize: 12, paddingRight: 10 }}>{label}</h3>
          <VuiNumberFormat
            className={"card-stock-number"}
            data={total}
            hasPrefix={false}
            value={total}
          />
        </div>
      </div>
    </div>
  );
};

const dataDefaultValues: DataInterface = {
  id: 0,
  sku: "",
  name: "",
  type: "Consignment",
  description: "",
  photo_id: null,
  photo: null,
  total_order: 0,
  total_delivery: 0,
  total_receipt: 0,
  total_available: 0,
  total_process: 0,
};

const StockProductDetail = () => {
  const { id } = useParams();
  const isMounted = useIsMounted();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const [exportLoading, setExportLoading] = React.useState<boolean>(false);
  const [data, setData] = React.useState<DataInterface>(dataDefaultValues);
  const [activeTab, setActiveTab] = React.useState<string | null>("movement");
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [imageURL, setImageURL] = React.useState<string>("");
  const tableMovementConfig = useState({
    page: 1,
    per_page: 5,
    total: 0,
    loading: false,
  });

  const tableOrderConfig = useState({
    page: 1,
    per_page: 5,
    total: 0,
    loading: false,
  });

  const [tableOrderData, setTableOrderData] = React.useState<TableInterface[]>(
    []
  );
  const [tableMovementData, setTableMovementData] = React.useState<any[]>([]);

  const tableMovementColumns = [
    {
      dataField: "date",
      text: t("table.header.date"),
      formatter: (cell: any) => <Date data={cell} />,
    },
    {
      dataField: "number",
      text: t("table.header.transactionNumber"),
      formatter: (cell: any, row: any) => {
        const statusLowerCase = row?.status?.toLowerCase();
        return (
          <div className={"display-flex align-items-center"}>
            <div className={"mr-2"}>{cell}</div>
            {row?.status ? (
              <div
                className={
                  statusLowerCase === "sampai tujuan"
                    ? "table-btn-status success"
                    : statusLowerCase === "dalam pengiriman"
                    ? "table-btn-status processing"
                    : "table-btn-status failed"
                }
              >
                {row?.status}
              </div>
            ) : null}
          </div>
        );
      },
    },
    {
      dataField: "quantity",
      text: t("table.header.qty"),
      formatter: (cell: any) => {
        return cell > 0 ? (
          <NumberFormat
            className={"text-plus-stock"}
            prefix={"+"}
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        ) : cell == 0 ? (
          <NumberFormat
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        ) : (
          <NumberFormat
            className={"text-minus-stock"}
            value={cell}
            defaultValue={cell}
            displayType={"text"}
            thousandSeparator={","}
            decimalSeparator={"."}
            decimalScale={0}
          />
        );
      },
    },
  ];

  const tableOrderColumns = [
    {
      dataField: "date",
      text: t("table.header.date"),
      formatter: (cell: any) => <DateTime data={cell} />,
    },
    {
      dataField: "number",
      text: t("table.header.orderNo"),
    },
    {
      dataField: "brand",
      text: t("table.header.brand"),
    },
    {
      dataField: "unit",
      text: t("table.header.unit"),
    },
    {
      dataField: "total_order",
      text: t("table.header.order"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_delivery",
      text: t("table.header.sent"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_available",
      text: t("table.header.available"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
    {
      dataField: "total_process",
      text: t("table.header.inProcess"),
      formatter: (cell: any) =>
        cell ? (
          <VuiNumberFormat hasPrefix={false} data={cell} value={cell} />
        ) : (
          0
        ),
    },
  ];

  const getOrder = () => {
    if (isMounted.current) tableOrderConfig.loading.set(true);

    let params: any = {};
    const conf = _.omit($clone(tableOrderConfig.get()), "loading");

    Object.keys(conf).forEach((key: any) => {
      if (conf[key]) {
        params[key] = conf[key];
      }
    });

    StockProductRepository.showOrder(id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setTableOrderData(response.data.data);
          tableOrderConfig.total.set(response.data.meta.total);
          tableOrderConfig.loading.set(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error.message, "error");
          tableOrderConfig.loading.set(false);
        }
      });
  };

  const getProduct = () => {
    if (isMounted.current) tableMovementConfig.loading.set(true);

    let params: any = {};
    const conf = _.omit($clone(tableMovementConfig.get()), "loading");

    Object.keys(conf).forEach((key: any) => {
      if (conf[key]) {
        params[key] = conf[key];
      }
    });

    StockProductRepository.showTracking(id, params)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const tempTableData: TableInterface[] = [];
          response.data.data.forEach((item: TableInterface, index: number) => {
            tempTableData.push({
              id: index,
              date: item.date,
              number: item.number,
              status: item.status,
              quantity: item.quantity,
            });
          });
          setTableMovementData(tempTableData);
          tableMovementConfig.loading.set(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          tableMovementConfig.loading.set(false);
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const loadData = () => {
    StockProductRepository.show(id, {
      with: ["photo"],
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setData(response.data.data);
        }
      })
      .catch((error: AxiosError) => {
        showToast(error?.response?.data?.message, "error");
      });
  };

  useMemo(() => {
    getOrder();
    getProduct();
    loadData();
  }, [id]);

  const onTableChange: TableChangeHandler<any> = (
    type,
    { page, sizePerPage }
  ) => {
    if (activeTab === "order") {
      tableOrderConfig.page.set(page);
      tableOrderConfig.per_page.set(sizePerPage);
      getOrder();
    } else {
      tableMovementConfig.page.set(page);
      tableMovementConfig.per_page.set(sizePerPage);
      getProduct();
    }
  };

  const openImage = (url: string) => {
    setImageURL(url);
    setShowImage(true);
  };

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  const handleChangeTab = (key: string | null) => {
    setActiveTab(key);
  };

  const handleExport = async () => {
    if (isMounted.current) setExportLoading(true);
    if (activeTab === "movement") {
      await StockProductRepository.exportMovement(id)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setExportLoading(false);
          fileDownload(response.data, t("export.movementProduct"));
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setExportLoading(false);
          handleResponseError(error);
        });
    } else {
      await StockProductRepository.exportOrder(id)
        .then((response: AxiosResponse) => {
          if (isMounted.current) setExportLoading(false);
          fileDownload(response.data, t("export.orderProduct"));
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) setExportLoading(false);
          handleResponseError(error);
        });
    }
  };

  return (
    <div className={"page-layout"}>
      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <Helmet>
        <title>{t("stock.product.detail.metaTitle")}</title>
      </Helmet>

      <TitleHeader
        title={t("stock.product.detail.title")}
        withBackUrl
        component={
          <VuiButton
            loading={exportLoading}
            label={t("button.export")}
            onClick={handleExport}
            customClass={"ml-auto"}
          />
        }
      />

      <Row id={"stock-product-section"}>
        <Col md={4} lg={4} xs={12}>
          <div className={"stock-product-header"}>
            {data.photo_id && (
              <img
                src={_.get(data, "photo.url")}
                className={"image-hover-cursor"}
                onClick={() => openImage(_.get(data, "photo.url"))}
                alt="a"
              />
            )}
            <div>
              <p className={"title"}>{data.sku}</p>
              <h4 className={"product-name"}>{data.name}</h4>
              <p className={"subtitle"}>{data.description}</p>
            </div>
          </div>
          <Row className={"mb-4"}>
            <Col md={12} xs={12} lg={12}>
              <div className={"card-paper"}>
                <div className={"card-content"}>
                  <div className={"card-text-wrapper text-primary"}>
                    <p className={"card-text"}>{t("card.stockAvailable")}</p>
                    <VuiNumberFormat
                      className={"card-number"}
                      data={data.total_available}
                      value={data.total_available}
                    />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row className={"mb-4"}>
            <Col md={6} xs={12} lg={6}>
              <CardDetailStock
                label={t("card.orderQuantity")}
                total={data.total_order}
              />
            </Col>
            <Col md={6} xs={12} lg={6}>
              <CardDetailStock
                label={t("card.sent")}
                total={data.total_delivery}
              />
            </Col>
          </Row>
          <Row className={"mb-4"}>
            <Col md={6} xs={12} lg={6}>
              <CardDetailStock
                label={t("card.completed")}
                total={data.total_receipt}
              />
            </Col>
            <Col md={6} xs={12} lg={6}>
              <CardDetailStock
                label={t("card.inProcess")}
                total={data.total_process}
              />
            </Col>
          </Row>
        </Col>

        <Col md={8} lg={8} xs={12}>
          <Tab.Container
            onSelect={handleChangeTab}
            id="left-tabs-example"
            defaultActiveKey={
              data.type === "Consignment" ? "movement" : "order"
            }
          >
            <Row>
              <Col sm={12}>
                <Nav variant="pills" className="nav-tab-wrapper">
                  {data.type !== "Consignment" &&
                    user.permissions.includes(
                      "product-stock.stock-per-order"
                    ) && (
                      <Nav.Item className="nav-tab-item">
                        <Nav.Link eventKey="order">{t("tabs.order")}</Nav.Link>
                      </Nav.Item>
                    )}
                  {user.permissions.includes(
                    "product-stock.stock-tracking"
                  ) && (
                    <Nav.Item className="nav-tab-item">
                      <Nav.Link eventKey="movement">
                        {t("tabs.movement")}
                      </Nav.Link>
                    </Nav.Item>
                  )}
                </Nav>
              </Col>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="order">
                    {user.permissions.includes(
                      "product-stock.stock-per-order"
                    ) && (
                      <div className={"card-paper"}>
                        <VuiDataTable
                          headerClass={"table-header-custom"}
                          columns={tableOrderColumns}
                          data={tableOrderData}
                          loading={tableOrderConfig.loading.get()}
                          page={tableOrderConfig.page.get()}
                          sizePerPage={tableOrderConfig.per_page.get()}
                          totalSize={tableOrderConfig.total.get()}
                          onTableChange={onTableChange}
                        />
                      </div>
                    )}
                  </Tab.Pane>
                  <Tab.Pane eventKey="movement">
                    {user.permissions.includes(
                      "product-stock.stock-tracking"
                    ) && (
                      <div className={"card-paper"}>
                        <VuiDataTable
                          headerClass={"table-header-custom"}
                          columns={tableMovementColumns}
                          data={tableMovementData}
                          loading={tableMovementConfig.loading.get()}
                          page={tableMovementConfig.page.get()}
                          sizePerPage={tableMovementConfig.per_page.get()}
                          totalSize={tableMovementConfig.total.get()}
                          onTableChange={onTableChange}
                        />
                      </div>
                    )}
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Col>
      </Row>
    </div>
  );
};

export default StockProductDetail;
