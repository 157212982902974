/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faMinus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { VuiButton } from "../../../../vodea/@vodea-ui/components/VuiButton";
import {
  formatFormData,
  formattedModelValue,
} from "../../../../vodea/utilities/helpers/form";
import useIsMounted from "../../../../vodea/utilities/hooks/useIsMounted";
import VuiSelect from "../../../../vodea/@vodea-ui/components/VuiSelect";
import VuiDateRangePicker from "../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import VuiUploadImage from "../../../../vodea/@vodea-ui/components/VuiUploadImage";
import VuiNumberFormat from "../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import { validationSchema } from "./validation";
import BrandRepository from "../../../../repositories/BrandRepository";
import ProductRepository from "../../../../repositories/ProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../vodea/utilities/helpers/global";
import OrderRepository from "../../../../repositories/OrderRepository";
import { IOrder } from "../../../../interfaces/Order";
import moment from "moment";
import UserRepository from "../../../../repositories/UserRepository";
import { IResource } from "../../../../interfaces/Resource";

const defaultValues = {
  order_items: [],
  ppn: false,
  date: moment(),
  brand_id: null,
  marketing_id: null,
  number: "",
};

const orderItemBaseModel = {
  photo_id: null,
  sku: "",
  product_id: "",
  description: "",
  quantity: null,
  unit_id: null,
  price: null,
  total: 0,
};

const OrderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const [loading, setLoading] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    getValues: informationGetValues,
    setValue: informationSetValue,
    watch: informationWatch,
  } = useForm<IOrder>({
    mode: "onChange",
    resolver: yupResolver(validationSchema(t)),
    defaultValues: defaultValues,
  });

  const {
    fields: productFields,
    append: productAppend,
    remove: productRemove,
  } = useFieldArray({
    control: informationControl,
    name: "order_items",
  });

  const watchOrderItems = informationWatch("order_items");

  const summaryData = useState({
    subtotal: 0,
    ppn: 0,
    total: 0,
  });

  const loadData = async () => {
    await OrderRepository.show(id, {
      with: [
        "brand.logo",
        "orderDetails.photo",
        "orderDetails.unit",
        "orderDetails.product.unit",
        "marketingName",
      ],
    })
      .then((response: AxiosResponse<IResource<IOrder>>) => {
        const responseData = response.data.data;
        const orderItems: any[] = [];
        const formattedData = formattedModelValue(defaultValues, responseData);

        formattedData.order_details.forEach((item: any) => {
          orderItems.push({
            photo_id: item.photo,
            sku: item.product,
            product_id: item.product,
            description: item.product,
            quantity: item.quantity,
            unit_id: item.product.unit.name,
            price: item.price,
            total: item.quantity * item.price,
          });
        });

        _.forEach(formattedData, (value, name: any) => {
          if (name === "brand_id") {
            informationSetValue(name, formattedData.brand);
          } else if (name === "marketing_id") {
            informationSetValue(name, responseData.marketing_name);
          } else if (name === "order_details") {
            informationSetValue("order_items", orderItems);
          } else {
            informationSetValue(name, value);
          }
        });

        calculateSummaryData(true);
      })
      .catch((error: AxiosError) => {
        if (isMounted.current && error.isAxiosError) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  const calculateSummaryData = (direct: boolean = false) => {
    setTimeout(() => {
      if (productFields.length || direct) {
        const tempSummary = {
          subtotal: 0,
          total: 0,
        };

        const orderProduct = informationGetValues("order_items");
        const hasPPN = informationGetValues("ppn");

        orderProduct.forEach((value: any) => {
          tempSummary.subtotal +=
            _.get(value, "price", 0) * _.get(value, "quantity", 0);
        });

        tempSummary.total = tempSummary.subtotal;

        summaryData.subtotal.set(tempSummary.subtotal);
        summaryData.total.set(tempSummary.total);

        if (hasPPN) {
          calculateTotal(true);
        }
      }
    }, 200);
  };

  const calculateTotal = (value: boolean = false) => {
    if (isMounted.current) {
      if (value) {
        summaryData.ppn.set((11 / 100) * summaryData.subtotal.get());
        setTimeout(() => {
          summaryData.total.set(
            summaryData.ppn.get() + summaryData.subtotal.get()
          );
        }, 200);
      } else {
        summaryData.ppn.set(0);
        summaryData.total.set(summaryData.subtotal.get());
      }
    }
  };

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    formData?.order_items.splice(-1);
    const orderItems: any[] = [];

    formData?.order_items.forEach((item: any) => {
      orderItems.push({
        photo_id: _.get(item, "photo_id.id"),
        product_id: _.get(item, "product_id.id"),
        description: _.get(item, "product_id.description", ""),
        quantity: _.get(item, "quantity", 0),
        price: _.get(item, "price", 0),
      });
    });

    formData.order_items = orderItems;

    if (id) {
      await OrderRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/order/sub-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
          }
          if (error?.response?.data.errors) {
            const flattenError: any[] = Object.values(
              error?.response.data.errors
            ).flat();
            if (flattenError) {
              showToast(flattenError[0], "error");
            }
          }
        });
    } else {
      await OrderRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/order/sub-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
          }
          if (error?.response?.data.errors) {
            const flattenError: any[] = Object.values(
              error?.response.data.errors
            ).flat();
            if (flattenError) {
              showToast(flattenError[0], "error");
            }
          }
        });
    }
  };

  const addProductColumns = (index: number) => {
    if (productFields.length - 1 == index) {
      productAppend(orderItemBaseModel);
    }
  };

  useEffect(() => {
    if (!id) {
      productAppend(orderItemBaseModel);
      calculateSummaryData();
    }
  }, []);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id ? t("editOrder.metaTitle") : t("createOrder.metaTitle")}
        </title>
      </Helmet>

      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/order/sub-order")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>
          {id ? t("editOrder.title") : t("createOrder.title")}
        </h4>
      </div>

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className="page-content">
          <div className={"card-paper"}>
            <div className={"card-content"}>
              <div className={"default-filter-section align-items-unset"}>
                <div className={"filter-item filter-special"}>
                  <div className={"mb-2"}>{t("form.brand.label")}</div>
                  <Controller
                    name={"brand_id"}
                    control={informationControl}
                    render={({ field }) => {
                      return (
                        <VuiSelect
                          selectRepository={BrandRepository}
                          defaultValue={field.value}
                          onChange={field.onChange}
                        />
                      );
                    }}
                  />
                  {informationErrors.brand_id && (
                    <span className={"text-danger"}>
                      {informationErrors.brand_id?.message}
                    </span>
                  )}
                </div>

                <div
                  className={"filter-item filter-special type-search mb-unset"}
                >
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.orderNumber.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("number")}
                        />
                        {informationErrors.number && (
                          <span className={"text-danger"}>
                            {informationErrors.number?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.orderDate.label")}
                        </label>
                        <Controller
                          name={"date"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                single
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <div className={"form-group mb-unset"}>
                      <label className={"form-label"}>
                        {t("form.marketing.label")}
                      </label>
                      <Controller
                        name={"marketing_id"}
                        control={informationControl}
                        render={({ field }) => {
                          return (
                            <VuiSelect
                              selectRepository={UserRepository}
                              selectParams={{ only: "client" }}
                              defaultValue={field.value}
                              onChange={field.onChange}
                            />
                          );
                        }}
                      />
                      {informationErrors.marketing_id && (
                        <span className={"text-danger"}>
                          {informationErrors.marketing_id?.message}
                        </span>
                      )}
                    </div>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className={"card-paper overflow-hidden mt-4"}>
            <div id={"table-custom-wrapper"}>
              <table className={"table table-custom"}>
                <thead>
                  <tr>
                    <th>{t("form.img.label")}</th>
                    <th style={{ width: "10vw" }}>{t("form.sku.label")}</th>
                    <th style={{ width: "11vw" }}>{t("form.product.label")}</th>
                    <th style={{ width: "11vw" }}>
                      {t("form.description.label")}
                    </th>
                    <th>{t("form.qty.label")}</th>
                    <th>{t("form.unit.label")}</th>
                    <th>{t("form.price.label")}</th>
                    <th>{t("form.total.label")}</th>
                  </tr>
                </thead>
                <tbody>
                  {productFields.map((item: any, index: number) => {
                    return (
                      <tr key={item.id}>
                        <td>
                          <Controller
                            name={`order_items.${index}.photo_id`}
                            control={informationControl}
                            defaultValue={item.photo_id}
                            render={({ field }) => {
                              return (
                                <VuiUploadImage
                                  defaultValue={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    addProductColumns(index);
                                  }}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`order_items.${index}.sku`}
                            control={informationControl}
                            defaultValue={item.sku}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    type: "order",
                                    with: ["unit"],
                                  }}
                                  defaultValue={field.value}
                                  hideDropdownIcon={true}
                                  labelKey="sku"
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `order_items.${index}.product_id`,
                                      value
                                    );
                                    informationSetValue(
                                      `order_items.${index}.description`,
                                      value.description
                                    );
                                    informationSetValue(
                                      `order_items.${index}.unit_id`,
                                      value.unit.name
                                    );
                                    informationSetValue(
                                      `order_items.${index}.price`,
                                      value.price
                                    );
                                    addProductColumns(index);
                                  }}
                                  placeholder=""
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            name={`order_items.${index}.product_id`}
                            control={informationControl}
                            defaultValue={item.product_id}
                            render={({ field }) => {
                              return (
                                <VuiSelect
                                  selectRepository={ProductRepository}
                                  selectParams={{
                                    type: "order",
                                    with: ["unit"],
                                  }}
                                  defaultValue={field.value}
                                  onChange={(value) => {
                                    field.onChange(value);
                                    informationSetValue(
                                      `order_items.${index}.sku`,
                                      value
                                    );
                                    informationSetValue(
                                      `order_items.${index}.description`,
                                      value.description
                                    );
                                    informationSetValue(
                                      `order_items.${index}.unit_id`,
                                      value.unit.name
                                    );
                                    informationSetValue(
                                      `order_items.${index}.price`,
                                      value.price
                                    );
                                    addProductColumns(index);
                                  }}
                                  hideDropdownIcon
                                  placeholder={""}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            control={informationControl}
                            name={`order_items.${index}.description`}
                            defaultValue={item.description}
                            render={({ field }) => {
                              return (
                                <input
                                  type="text"
                                  className="form-control"
                                  name={field.name}
                                  onChange={(e) => {
                                    field.onChange(e.target.value);
                                    addProductColumns(index);
                                  }}
                                  value={field.value}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <Controller
                            control={informationControl}
                            name={`order_items.${index}.quantity`}
                            defaultValue={item.quantity}
                            render={({ field }) => {
                              return (
                                <NumberFormat
                                  className="form-control"
                                  value={field.value}
                                  allowNegative={false}
                                  thousandSeparator={","}
                                  decimalSeparator={"."}
                                  placeholder={"0"}
                                  decimalScale={0}
                                  onValueChange={({ value }) => {
                                    field.onChange(value);
                                    const price =
                                      watchOrderItems[index]?.price || 0;
                                    const val = value ? parseInt(value) : 0;
                                    informationSetValue(
                                      `order_items.${index}.total`,
                                      price * val
                                    );
                                    calculateSummaryData();
                                    addProductColumns(index);
                                  }}
                                />
                              );
                            }}
                          />
                        </td>
                        <td>
                          <div>{watchOrderItems[index]?.unit_id || "-"}</div>
                          {/*<Controller*/}
                          {/*  name={`order_items.${index}.unit_id`}*/}
                          {/*  defaultValue={item.unit_id}*/}
                          {/*  control={informationControl}*/}
                          {/*  render={({ field }) => {*/}
                          {/*    return (*/}
                          {/*      <VuiSelect*/}
                          {/*        selectRepository={UnitRepository}*/}
                          {/*        defaultValue={field.value}*/}
                          {/*        onChange={(value) => {*/}
                          {/*          field.onChange(value);*/}
                          {/*          addProductColumns(index);*/}
                          {/*        }}*/}
                          {/*      />*/}
                          {/*    );*/}
                          {/*  }}*/}
                          {/*/>*/}
                        </td>
                        <td>
                          <Controller
                            name={`order_items.${index}.price`}
                            control={informationControl}
                            defaultValue={item.price}
                            render={({ field }) => (
                              <NumberFormat
                                className="form-control"
                                value={field.value}
                                allowNegative={false}
                                thousandSeparator={","}
                                decimalSeparator={"."}
                                placeholder={"0"}
                                onValueChange={({ value }) => {
                                  field.onChange(value);
                                  const qty =
                                    watchOrderItems[index]?.quantity || 0;
                                  const val = value ? parseInt(value) : 0;
                                  informationSetValue(
                                    `order_items.${index}.total`,
                                    qty * val
                                  );
                                  calculateSummaryData();
                                  addProductColumns(index);
                                }}
                              />
                            )}
                          />
                        </td>
                        <td className="position-relative">
                          <div
                            className={
                              "display-flex align-items-center justify-content-between"
                            }
                          >
                            <VuiNumberFormat
                              prefixBold={true}
                              data={watchOrderItems[index]?.total}
                              value={watchOrderItems[index]?.total}
                              prefix={"Rp"}
                              className={"order-price mr-5"}
                            />
                            {index != productFields.length - 1 && (
                              <FontAwesomeIcon
                                className={"order-icon-delete"}
                                icon={faMinus}
                                onClick={() => {
                                  productRemove(index);
                                  calculateSummaryData();
                                }}
                              />
                            )}
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className={"card-content pr-12 mt-4"}>
              <Row className="overflow-hidden">
                <Col md={8} lg={8} xs={12} />
                <Col md={4} lg={4} xs={12}>
                  <table className="table-summary type-one">
                    <tbody>
                      <tr>
                        <td>{t("helper.global.subtotal")}</td>
                        <td>
                          <VuiNumberFormat
                            className={"display-flex justify-content-between"}
                            value={summaryData.subtotal.get()}
                            data={summaryData.subtotal.get()}
                            prefix={"Rp"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <Controller
                            name={"ppn"}
                            control={informationControl}
                            render={({ field }) => {
                              return (
                                <input
                                  type="checkbox"
                                  name={field.name}
                                  className={"form-check-input mr-2"}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    calculateTotal(e.target.checked);
                                  }}
                                />
                              );
                            }}
                          />
                          {t("helper.global.ppn")}
                        </td>
                        <td>
                          <VuiNumberFormat
                            className={"display-flex justify-content-between"}
                            value={summaryData.ppn.get()}
                            data={summaryData.ppn.get()}
                            prefix={"Rp"}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("helper.global.total")}</td>
                        <td>
                          <VuiNumberFormat
                            className={"display-flex justify-content-between"}
                            value={summaryData.total.get()}
                            data={summaryData.total.get()}
                            prefix={"Rp"}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createOrderWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/order/sub-order")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <div className={"button-group"}>
                    <VuiButton
                      forSubmit
                      label={t("button.save")}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default OrderForm;
