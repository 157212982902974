import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";

const loadAsyncOptions = async (
  search: string,
  prevOptions: any,
  page: number,
  selectRepository: any,
  selectParams = {},
  additionalOptions: any
) => {
  let data: any = {};

  if (selectRepository !== null) {
    await selectRepository
      .select({
        ...selectParams,
        per_page: 10,
        search: search !== "" ? search : null,
        page: page,
      })
      .then((response: AxiosResponse) => {
        if (additionalOptions && additionalOptions?.length > 0) {
          data = {
            data: [...additionalOptions, ...response.data.data],
          };
        } else {
          data = response.data;
        }
      })
      .catch((e: AxiosError) => {
        console.log(e);
      });
  }

  return {
    options: _.get(data, "data", []),
    hasMore: _.get(data, "meta.current_page") !== _.get(data, "meta.last_page"),
    additional: {
      page: page + 1,
    },
  };
};

export default loadAsyncOptions;
