/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faPlus } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { Col, Modal, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../../vodea/utilities/helpers/form";
import useIsMounted from "../../../../../../vodea/utilities/hooks/useIsMounted";
import VuiNumberFormat from "../../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import EditIcon from "../../../../../../assets/icon/icons8-edit.svg";
import VuiCardIcon from "../../../../../../vodea/@vodea-ui/components/VuiCardIcon";
import VuiUploadImage from "../../../../../../vodea/@vodea-ui/components/VuiUploadImage";
import SendOrderRepository from "../../../../../../repositories/SendOrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import {
  ItemInterface,
  SendOrderConfirmInterface,
  SendOrderInterface,
} from "./interface";
import { Date } from "../../../../../../components";
import VuiLoadingScreen from "../../../../../../vodea/@vodea-ui/components/VuiLoadingScreen";
import { showToast } from "../../../../../../vodea/utilities/helpers/global";
import { yupResolver } from "@hookform/resolvers/yup";
import ModalImage from "../../../../../../components/ModalImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores";
import { useTranslation } from "react-i18next";
import StatusLabel from "../../../../../../components/StatusLabel";
import { validationSchema } from "./validation";

const sendOrderConfirmBaseModel: SendOrderConfirmInterface = {
  recipient: "",
  photo_id: null,
};

const sendOrderBaseModel: SendOrderInterface = {
  ...sendOrderConfirmBaseModel,
  brand_name: "",
  brand_logo: "",
  address: "",
  note: "",
  address_id: null,
  delivery_number: "",
  order_number: "",
  delivery_date: "",
  sender: "",
  total_item: 0,
  total_quantity: 0,
  status: "",
  shipment_address: null,
};

const SendOrderProductOrderDetail: React.FC<any> = () => {
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [showImage, setShowImage] = useState<boolean>(false);
  const [imageURL, setImageURL] = useState<string>("");
  const [data, setData] = useState<SendOrderInterface>(sendOrderBaseModel);
  const [items, setItems] = useState<ItemInterface[]>([]);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    reset: informationReset,
  } = useForm<SendOrderConfirmInterface>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: sendOrderConfirmBaseModel,
  });

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }
    if (isMounted.current) setIsFetchingData(true);

    await SendOrderRepository.show(id, {
      with: [
        "photo",
        "order",
        "brand.logo",
        "orderShipmentDetails.orderDetail.photo",
        "orderShipmentDetails.orderDetail.product.unit",
        "orderShipmentDetails.orderDetail.unit",
        "shipmentAddress",
      ],
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          const formattedData: any = {};
          const formattedItemsData: ItemInterface[] = [];
          Object.assign(formattedData, {
            shipment_address: responseData.shipment_address || null,
            brand_name: responseData.brand?.name || "",
            brand_logo: responseData.brand?.logo?.url || null,
            address: responseData?.address || "",
            address_id: responseData?.address_id || "",
            delivery_number: responseData?.number || "",
            order_number: responseData?.order?.number || "",
            delivery_date: responseData?.date || "",
            sender: responseData?.sender || "",
            recipient: responseData?.recipient || "",
            total_item: responseData?.total_items || 0,
            total_quantity: responseData?.total_quantity || 0,
            status: responseData?.status || "",
            photo_id: responseData?.photo || null,
            note: responseData?.note || "",
          });

          responseData?.order_shipment_details.forEach((item: any) => {
            formattedItemsData.push({
              photo_url: item.order_detail?.photo?.url || null,
              sku: item.order_detail?.product?.sku || "",
              description: item.order_detail?.product?.description || "",
              product_name: item.order_detail?.product?.name || "",
              quantity: item?.quantity || 0,
              unit_name: item.order_detail?.product?.unit?.name || "",
            });
          });

          setData(formattedData);
          setItems(formattedItemsData);
          setIsFetchingData(false);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          if (error.isAxiosError) {
            showToast(error.message, "error");
          }
          setIsFetchingData(false);
        }
      });
  }, [false]);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  useEffect(() => {
    informationReset(data);
  }, [data]);

  const onSubmit = informationHandleSubmit((data) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    SendOrderRepository.confirm(id, formData)
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          setLoading(false);
        }
        showToast(response.data.message, "success");
        navigate("/order/send-order");
      })
      .catch((error: AxiosError) => {
        if (isMounted.current && error?.response?.data?.errors) {
          setLoading(false);
          showToast(error.response.data.message, "error");
        }
      });
  });

  const openImage = (url: string) => {
    setImageURL(url);
    setShowImage(true);
  };

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>{t("deliveryOrder.detailDelivery.title")}</title>
      </Helmet>

      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <Modal
        size="lg"
        show={openModal}
        centered
        className={"modal-product-detail"}
        onHide={() => setOpenModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className={"modal-product-body"}>
          <div className={"close-btn"}>
            <FontAwesomeIcon
              icon={faPlus}
              onClick={() => setOpenModal(false)}
            />
          </div>

          <div className={"title"}>{t("modal.deliveryOrder.title")}</div>
          <div className={"subtitle"}>
            <strong>{data.delivery_number}</strong>
          </div>
          <form className={"form-wrapper mt-4"} onSubmit={onSubmit}>
            <div className={"form-group"}>
              <div className={"form-label"}>{t("form.receiverName.label")}</div>
              <input
                type="text"
                className={"form-control"}
                {...informationRegister("recipient")}
              />
              {informationErrors.recipient && (
                <span className={"text-danger"}>
                  {informationErrors.recipient.message}
                </span>
              )}
            </div>
            <div className={"form-group"}>
              <div className={"form-label"}>
                {t("form.uploadDeliveryNote.label")}
              </div>
              <Controller
                name={"photo_id"}
                control={informationControl}
                render={({ field }) => {
                  return (
                    <VuiUploadImage
                      height={80}
                      width={"100%"}
                      className={"full-template"}
                      defaultValue={field.value}
                      onChange={field.onChange}
                    />
                  );
                }}
              />
              {informationErrors.photo_id && (
                <span className={"text-danger"}>
                  {informationErrors.photo_id.message}
                </span>
              )}
            </div>

            <div
              className={
                "display-flex align-items-center justify-content-center"
              }
            >
              <div className={"button-group"}>
                <div
                  className={"btn btn-light btn-sm mr-4"}
                  onClick={() => setOpenModal(false)}
                >
                  {t("button.cancel")}
                </div>
              </div>
              <div className={"button-group"}>
                <VuiButton
                  customClass={"btn-small"}
                  forSubmit={true}
                  label={t("button.save")}
                  loading={loading}
                />
              </div>
            </div>
          </form>
        </div>
      </Modal>

      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/order/send-order")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("deliveryOrder.delivery")
              : t("deliveryOrder.createDelivery")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("deliveryOrder.productOrder.title")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{data.delivery_number}</span>}
        </h4>

        {id && <StatusLabel status={data.status} />}

        <div className={"ml-auto d-flex align-items-center"}>
          {user.permissions.includes("order-shipment.update") && (
            <Link to={"edit"}>
              <VuiCardIcon icon={EditIcon} primary={false} />
            </Link>
          )}
          <button
            className={"btn btn-primary ml-4"}
            onClick={() => setOpenModal(true)}
          >
            {t("button.productSent")}
          </button>
        </div>
      </div>

      <div className="page-content">
        <div className={"card-paper"}>
          <div className={"card-content"}>
            <div className={"default-filter-section align-items-unset"}>
              <div className={"filter-item type-input-select mb-unset"}>
                <div className={"form-group"}>
                  <div className={"form-label"}>{t("form.brand.label")}</div>
                  <div className={"display-flex align-items-center"}>
                    {data.brand_logo && (
                      <img
                        className={"image-wrap image-hover-cursor mr-2"}
                        onClick={() => openImage(data.brand_logo)}
                        src={data.brand_logo}
                        alt={data.brand_logo}
                      />
                    )}
                    <strong>{data.brand_name || "-"}</strong>
                  </div>
                </div>
                <div className={"form-group mb-unset"}>
                  <div className={"form-label"}>
                    {t("form.deliveryAddress.label")}
                  </div>
                  <div>
                    <strong>
                      {data?.shipment_address?.name || data.address}
                    </strong>
                  </div>
                </div>
              </div>

              <div className={"filter-item type-half-full mb-unset"}>
                <Row>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.deliveryNumber.label")}
                      </label>
                      <div>
                        <strong>{data.delivery_number || "-"}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.deliveryDate.label")}
                      </label>
                      <div>
                        <strong>
                          <Date data={data.delivery_date} />
                        </strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} lg={6}>
                    <div className={"form-group"}>
                      <div className={"form-label"}>
                        {t("form.orderNumber.label")}
                      </div>
                      <div>
                        <strong>{data.order_number || "-"}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} lg={6}>
                    <div className={"form-group"}>
                      <div className={"form-label"}>{t("form.note.label")}</div>
                      <div>
                        <strong>{data.note || "-"}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group mb-unset"}>
                      <label className={"form-label"}>
                        {t("form.sender.label")}
                      </label>
                      <div>
                        <strong>{data.sender || "-"}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} lg={6} xs={12}>
                    <div className={"form-group mb-unset"}>
                      <label className={"form-label"}>
                        {t("form.receiver.label")}
                      </label>
                      <div>
                        <strong>{data.recipient || "-"}</strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className={"card-paper mt-4"}>
          {isFetchingData ? (
            <VuiLoadingScreen className={"height-unset"} />
          ) : (
            <>
              <div id={"table-custom-wrapper"}>
                <table className={"table table-custom width-unset"}>
                  <thead>
                    <th>{t("form.img.label")}</th>
                    <th>{t("form.sku.label")}</th>
                    <th>{t("form.product.label")}</th>
                    <th>{t("form.description.label")}</th>
                    <th>{t("form.qty.label")}</th>
                    <th>{t("form.unit.label")}</th>
                  </thead>
                  <tbody>
                    {items.map((item: any, index: number) => {
                      return (
                        <tr key={index}>
                          <td>
                            {item.photo_url && (
                              <img
                                className={
                                  "table-product-image image-hover-cursor"
                                }
                                onClick={() => openImage(item.photo_url)}
                                src={item.photo_url}
                                alt={item.photo_url}
                              />
                            )}
                          </td>
                          <td>{item.sku || "-"}</td>
                          <td>{item.product_name || "-"}</td>
                          <td>{item.description || "-"}</td>
                          <td>
                            <VuiNumberFormat
                              hasPrefix={false}
                              data={item.quantity}
                              value={item.quantity}
                            />
                          </td>
                          <td>{item.unit_name || "-"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>

              <div className={"card-content pr-12 mt-4"}>
                <Row>
                  <Col md={9} lg={9} xs={12} />
                  <Col md={3} lg={3} xs={12}>
                    <table className="table-summary d-flex justify-content-around">
                      <tbody>
                        <tr>
                          <td>{t("helper.global.totalItem")}</td>
                          <td>
                            <VuiNumberFormat
                              value={data.total_item}
                              data={data.total_item}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>{t("helper.global.totalQuantity")}</td>
                          <td>
                            <VuiNumberFormat
                              value={data.total_quantity}
                              data={data.total_quantity}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendOrderProductOrderDetail;
