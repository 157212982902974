/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { VuiButton } from "../../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../../vodea/utilities/helpers/form";
import useIsMounted from "../../../../../../vodea/utilities/hooks/useIsMounted";
import VuiSelect from "../../../../../../vodea/@vodea-ui/components/VuiSelect";
import VuiDateRangePicker from "../../../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import VuiNumberFormat from "../../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import { validationSchema } from "./validation";
import BrandRepository from "../../../../../../repositories/BrandRepository";
import OrderRepository from "../../../../../../repositories/OrderRepository";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import VuiLoadingScreen from "../../../../../../vodea/@vodea-ui/components/VuiLoadingScreen";
import {
  mapHookErrors,
  showToast,
} from "../../../../../../vodea/utilities/helpers/global";
import SendOrderRepository from "../../../../../../repositories/SendOrderRepository";
import PermissionRepository from "../../../../../../repositories/PermissionRepository";
import AddressRepository from "../../../../../../repositories/AddressRepository";
import { IDeliveryProductOrder } from "../../../../../../interfaces/DeliveryProductOrder";
import moment from "moment";

interface OrderDetailInterface {
  id: number | string;
  photo: object;
  product: object;
  unit: object;
  available: number | string;
  view_stock: any;
  view_item: any;
}

const defaultValues = {
  date: moment(),
  sender: "",
  note: "",
  order_id: null,
  address_id: null,
  brand_id: null,
  shipment_items: [],
};

const SendOrderProductOrderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [tableDataLoading, setTableDataLoading] =
    React.useState<boolean>(false);
  const [hasInitialize, setHasInitialize] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any>({});
  const [uniq, setUniq] = React.useState<number>(0);

  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    control: informationControl,
    getValues: informationGetValues,
    setValue: informationSetValue,
    watch: informationWatch,
    setError: informationSetErrors,
  } = useForm<IDeliveryProductOrder>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: defaultValues,
  });

  const watchBrand = informationWatch("brand_id");

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    await SendOrderRepository.show(id, {
      with: [
        "order",
        "orderShipmentDetails.orderDetail.photo",
        "brand",
        "shipmentAddress",
      ],
    })
      .then((response: AxiosResponse) => {
        if (isMounted.current) {
          const { data: responseData } = response.data;
          setData(responseData);
          const formattedOrder: any[] = [];
          responseData.order_shipment_details.forEach((item: any) => {
            formattedOrder.push({
              quantity: item.quantity,
            });
          });
          informationSetValue("date", responseData.date);
          informationSetValue("sender", responseData.sender);
          informationSetValue("order_id", responseData.order);
          informationSetValue("address_id", responseData.shipment_address);
          informationSetValue("brand_id", responseData.brand);
          informationSetValue("note", responseData.note);
          setTableData(formattedOrder);
        }
      })
      .catch((error: AxiosError) => {});
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  const watchOrder = informationWatch("order_id");

  const getOrderItems = async () => {
    const orderId = informationGetValues("order_id");
    if (isMounted.current) {
      if (!hasInitialize) {
        tableData.forEach((data: any, index: number) => {
          informationSetValue(`shipment_items.${index}.quantity`, 0);
        });
      }
      setTableDataLoading(true);
      setTableData([]);
      summaryData.set({
        total_quantity: 0,
        total_item: 0,
      });
    }
    if (orderId) {
      await OrderRepository.show(_.get(orderId, "id"), {
        with: [
          "orderDetails.product.unit",
          "orderDetails.photo",
          "orderDetails.unit",
        ],
      })
        .then((response: AxiosResponse) => {
          const formattedOrderDetails: OrderDetailInterface[] = [];
          response.data.data.order_details.forEach((item: any) => {
            formattedOrderDetails.push({
              id: item?.id,
              photo: item?.photo,
              product: item?.product,
              unit: item?.unit,
              available: item?.quantity,
              view_stock: item?.view_stock,
              view_item: item?.view_item,
            });
          });
          if (isMounted.current) {
            setTableDataLoading(false);
            setTableData(formattedOrderDetails);
            setHasInitialize(false);
          }
        })
        .catch((error: AxiosError) => {
          showToast(error.message, "error");
        });
    } else {
      if (isMounted.current) {
        setTableDataLoading(false);
        setHasInitialize(false);
      }
    }
  };

  useEffect(() => {
    getOrderItems();
  }, [watchOrder]);

  const summaryData = useState({
    total_quantity: 0,
    total_item: 0,
  });

  const calculateSummaryData = () => {
    setTimeout(() => {
      if (tableData.length) {
        const tempSummaryData = {
          total_quantity: 0,
          total_item: 0,
        };

        const orderProductsValue = informationGetValues("shipment_items");

        orderProductsValue.forEach((item: any, index: number) => {
          if (item.quantity) {
            tempSummaryData.total_quantity += parseInt(item.quantity);
          }
          if (
            parseInt(item.quantity) > 0 &&
            tableData[index].view_stock.total_available > 0
          ) {
            tempSummaryData.total_item += 1;
          }
        });

        if (Number.isNaN(tempSummaryData.total_quantity)) {
          tempSummaryData.total_quantity = 0;
        }

        summaryData.set(tempSummaryData);
      }
    }, 200);
  };

  useEffect(() => {
    calculateSummaryData();
  }, [tableData]);

  const onInformationSubmit = informationHandleSubmit(async (data) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);
    const shipmentItems: any[] = [];
    formData.shipment_items.forEach((item: any, index: number) => {
      const itemQuantity: number =
        typeof item?.quantity === "string"
          ? parseInt(item?.quantity)
          : item?.quantity;
      if (itemQuantity > 0) {
        shipmentItems.push({
          order_detail_id: tableData[index]?.id,
          product_id: tableData[index]?.product?.id,
          quantity:
            typeof item?.quantity === "string"
              ? parseInt(item?.quantity)
              : item?.quantity,
        });
      }
    });

    formData.shipment_items = shipmentItems;

    if (id) {
      await SendOrderRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/order/send-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    } else {
      await SendOrderRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/order/send-order");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    }
  });

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id ? t("deliveryOrder.delivery") : t("deliveryOrder.createDelivery")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div className={"icon-wrapper icon-back"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("deliveryOrder.delivery")
              : t("deliveryOrder.createDelivery")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("deliveryOrder.productOrder.title")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{_.get(data, "number")}</span>}
        </h4>
      </div>

      <form onSubmit={onInformationSubmit}>
        <div className="page-content">
          <div className={"card-paper"}>
            <div className={"card-content"}>
              <div className={"default-filter-section align-items-unset"}>
                <div className={"filter-item type-input-select mb-unset"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={BrandRepository}
                            selectParams={{ with: ["logo"] }}
                            defaultValue={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              setUniq((prevState) => prevState + 1);
                              informationSetValue("order_id", null);
                            }}
                          />
                        );
                      }}
                    />
                    {informationErrors.brand_id && (
                      <span className={"text-danger"}>
                        {informationErrors.brand_id?.message}
                      </span>
                    )}
                  </div>
                  <div className={"form-group mb-unset"}>
                    <div className={"form-label"}>
                      {t("form.deliveryAddress.label")}
                    </div>
                    <Controller
                      name={"address_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={AddressRepository}
                            defaultValue={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                    {informationErrors.address_id && (
                      <span className={"text-danger"}>
                        {informationErrors.address_id?.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className={"filter-item type-half-full mb-unset"}>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryNumber.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={"[Auto Generate]"}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.deliveryDate.label")}
                        </label>
                        <Controller
                          name={"date"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                single={true}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} lg={6}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.orderNumber.label")}
                        </div>
                        <Controller
                          name={"order_id"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                selectRepository={OrderRepository}
                                selectParams={{
                                  type: "Order",
                                  brand: _.get(watchBrand, "id"),
                                }}
                                labelKey={"number"}
                                isDisabled={!watchBrand}
                                defaultValue={field.value}
                                onChange={field.onChange}
                                cacheUniq={uniq}
                              />
                            );
                          }}
                        />
                        {informationErrors.order_id && (
                          <span className={"text-danger"}>
                            {informationErrors.order_id?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group mb-unset"}>
                        <label className={"form-label"}>
                          {t("form.note.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("note")}
                        />
                        {informationErrors.note && (
                          <span className={"text-danger"}>
                            {informationErrors.note?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group mb-unset"}>
                        <label className={"form-label"}>
                          {t("form.sender.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          {...informationRegister("sender")}
                        />
                        {informationErrors.sender && (
                          <span className={"text-danger"}>
                            {informationErrors.sender?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            {tableDataLoading ? (
              <VuiLoadingScreen className={"height-unset"} />
            ) : (
              <>
                <div id={"table-custom-wrapper"}>
                  <table className={"table table-custom"}>
                    <thead>
                      <tr>
                        <th>{t("form.img.label")}</th>
                        <th>{t("form.sku.label")}</th>
                        <th>{t("form.product.label")}</th>
                        <th>{t("form.description.label")}</th>
                        <th>{t("form.unit.label")}</th>
                        <th>{t("form.available.label")}</th>
                        <th style={{ width: "17vw" }}>{t("form.qty.label")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableData.map((item: any, index: number) => {
                        const quantityLimit = ({ floatValue }: any) => {
                          if (floatValue) {
                            return (
                              floatValue <= Number(item?.view_item?.available)
                            );
                          } else {
                            return true;
                          }
                        };
                        return (
                          <tr key={index}>
                            <td>
                              {_.get(item, "photo.url", null) && (
                                <img
                                  className={"table-product-image"}
                                  src={_.get(item, "photo.url", null)}
                                  alt={_.get(item, "photo.url", null)}
                                />
                              )}
                            </td>
                            <td>{_.get(item, "product.sku", "-")}</td>
                            <td>{_.get(item, "product.name", "-")}</td>
                            <td>{_.get(item, "product.description", "-")}</td>
                            <td>{_.get(item, "product.unit.name", "-")}</td>
                            <td>
                              <VuiNumberFormat
                                hasPrefix={false}
                                data={_.get(item, "view_item.available", 0)}
                                value={_.get(item, "view_item.available", 0)}
                              />
                            </td>
                            <td>
                              {_.get(item, "view_item.available", 0) > 0 && (
                                <Controller
                                  render={({ field }) => {
                                    return (
                                      <NumberFormat
                                        className={"form-control"}
                                        allowNegative={false}
                                        thousandSeparator={","}
                                        decimalSeparator={"."}
                                        decimalScale={0}
                                        placeholder={"0"}
                                        value={field.value}
                                        isAllowed={quantityLimit}
                                        onValueChange={({ value }) => {
                                          field.onChange(value);
                                          calculateSummaryData();
                                        }}
                                      />
                                    );
                                  }}
                                  name={`shipment_items.${index}.quantity`}
                                  control={informationControl}
                                  defaultValue={item.quantity}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>

                <div className={"card-content pr-12 mt-4"}>
                  <Row>
                    <Col md={9} lg={9} xs={12} />
                    <Col md={3} lg={3} xs={12}>
                      <table className="table-summary d-flex justify-content-around">
                        <tbody>
                          <tr>
                            <td>{t("helper.global.totalItem")}</td>
                            <td>
                              <VuiNumberFormat
                                value={summaryData.total_item.get()}
                                data={summaryData.total_item.get()}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td>{t("helper.global.totalQuantity")}</td>
                            <td>
                              <VuiNumberFormat
                                value={summaryData.total_quantity.get()}
                                data={summaryData.total_quantity.get()}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </div>
              </>
            )}
          </div>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createDeliveryWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/order/sub-order")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <div className={"button-group"}>
                    <VuiButton
                      forSubmit={true}
                      label={t("button.save")}
                      loading={loading}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SendOrderProductOrderForm;
