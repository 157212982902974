import _ from "lodash";
import moment from "moment";
import { AxiosError, AxiosResponse } from "axios";
import { mapHookErrors, showToast } from "./global";

const defaultStatusOptions = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Suspend",
  },
];

export const formatSetValueForm = (model: any, data: any) => {
  let returnData: any = {};

  Object.keys(model).forEach(function (key) {
    if (key === "is_active") {
      returnData[key] = _.find(defaultStatusOptions, (option) => {
        return Number(option.id) === Number(data[key]);
      });
    } else if (key.substr(key.length - 3) === "_id") {
      returnData[key] = data[key.substr(0, key.length - 3)];
    } else if (key.substr(key.length - 4) === "_ids") {
      returnData[key] = data[key.substr(0, key.length - 4) + "s"];
    } else {
      returnData[key] = data[key];
    }
  });

  return returnData;
};

export const formattedModelValue = (model: any, data: any) => {
  let returnData: any = {};
  const specialKeys: any[] = ["order_details", "id", "unit", "brand"];

  Object.keys(data).forEach(function (key) {
    if (key in model) {
      returnData[key] = data[key];
    } else if (specialKeys.includes(key)) {
      returnData[key] = data[key];
    }
  });

  return returnData;
};

export const formatFormData = (data: any) => {
  let returnData: any = {};

  Object.keys(data).forEach(function (key) {
    returnData[key] = data[key];

    if (data[key] !== null) {
      if (key === "is_active") {
        returnData[key] = Number(_.get(data[key], "id", 0));
      } else if (key.substr(key.length - 3) === "_id") {
        returnData[key] = _.get(data[key], "id", data[key]);
      } else if (key.substr(key.length - 4) === "_ids") {
        returnData[key] = _.map(data[key], "id");
      } else if (moment.isMoment(data[key])) {
        returnData[key] = data[key].format("YYYY-MM-DD");
      } else if (key.substr(key.length - 6) === "_range") {
        let fromKey = key.substr(0, key.length - 6) + "_from";
        let toKey = key.substr(0, key.length - 6) + "_to";

        returnData[fromKey] = data[key]["start"];
        returnData[toKey] = data[key]["end"];

        if (moment.isMoment(data[key]["start"])) {
          returnData[fromKey] = data[key]["start"].format("YYYY-MM-DD");
        }

        if (moment.isMoment(data[key]["end"])) {
          returnData[toKey] = data[key]["end"].format("YYYY-MM-DD");
        }
      }
    }
  });

  return returnData;
};

export const handleSaveResponseError = (error: AxiosError, setError: any) => {
  const tempErrors: any[] = error?.response?.data?.errors;
  if (tempErrors && !_.isEmpty(tempErrors)) {
    const errors = mapHookErrors(tempErrors);
    const flattenError: any[] = Object.values(tempErrors).flat();
    Object.keys(errors).forEach((key: any) => setError(key, errors[key]));
    if (flattenError.length > 0) showToast(flattenError[0], "error");
  } else {
    showToast(error?.response?.data.message, "error");
  }
};

export const handleSaveResponseSuccess = (
  response: AxiosResponse,
  navigate: any,
  url: string | number = -1
) => {
  showToast(response.data.message, response.data.success ? "success" : "error");
  if (response.data.success) navigate(url);
};
