import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface IModalImage {
  open: boolean;
  url: string;
  onClose: () => void;
}

const ModalImage: React.FC<IModalImage> = ({ open, url, onClose }) => {
  return (
    <Modal className="modal-image-body" show={open} centered onHide={onClose}>
      <Modal.Body>
        <div className="close-icon-wrapper" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} className="close-icon" />
        </div>
        <img className={"image-default-size"} src={url} alt={url} />
      </Modal.Body>
    </Modal>
  );
};

export default ModalImage;
