/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  informationBaseModel,
  InformationInputs,
  validationSchema,
} from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import BrandRepository from "../../../../../repositories/BrandRepository";
import _ from "lodash";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../../vodea/utilities/helpers/form";
import ProductRepository from "../../../../../repositories/ProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import { useTranslation } from "react-i18next";

const SettingProductConsignmentForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
    control: informationControl,
  } = useForm<InformationInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    if (id) {
      await ProductRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    } else {
      await ProductRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    }
  };

  const loadData = async () => {
    let params: any = {};
    Object.assign(params, {
      with: ["productBrand"],
    });

    await ProductRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(informationBaseModel, data);
        if (isMounted.current) {
          _.forEach(formattedData, (value, name: any) => {
            if (name === "brand_id") {
              informationSetValue(name, data.product_brand);
            } else {
              informationSetValue(name, value);
            }
          });
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError)
          showToast(error?.response?.data.message, "error");
      });
  };

  useMemo(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("setting.section.masterData.product.consignment.edit.metaTitle")
            : t(
                "setting.section.masterData.product.consignment.create.metaTitle"
              )}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/setting/product")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>
          {id
            ? t("setting.section.masterData.product.consignment.edit.title")
            : t("setting.section.masterData.product.consignment.create.title")}
        </h4>
      </div>

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={8} lg={8} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-header"}>
                  <p className={"card-header-title"}>
                    {t("helper.global.productInformation")}
                  </p>
                </div>
                <div className={"card-content"}>
                  <Row>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.sku.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("sku")}
                        />
                        {informationErrors.sku && (
                          <span className={"text-danger"}>
                            {informationErrors.sku?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.name.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("name")}
                        />
                        {informationErrors.name && (
                          <span className={"text-danger"}>
                            {informationErrors.name?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.description.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("description")}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col md={4} lg={4} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-header"}>
                  <p className={"card-header-title"}>
                    {t("helper.global.group")}
                  </p>
                </div>
                <div className={"card-content"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={BrandRepository}
                            defaultValue={field.value}
                            onChange={field.onChange}
                          />
                        );
                      }}
                    />
                    {informationErrors.brand_id && (
                      <span className={"text-danger"}>
                        {informationErrors.brand_id?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createProductConsignmentWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/setting/product")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <VuiButton
                    forSubmit={true}
                    label={id ? t("button.save") : t("button.create")}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingProductConsignmentForm;
