import { TFunction } from "react-i18next";
import * as yup from "yup";

export const validationSchema = (t: TFunction) => {
  let shapes = {
    recipient: yup.string().required().label(t("form.receiverName.label")),
    photo_id: yup.mixed().required().label(t("form.uploadDeliveryNote.label")),
  };

  return yup.object().shape(shapes);
};
