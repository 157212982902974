import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  select: function (params: any = null) {
    return api.get(`${endPoint()}/api/select/permission`, {
      params,
      cancelToken:
        cancelTokenHandlerObject[this.select.name].handleRequestCancellation()
          .token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
