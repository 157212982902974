/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useMemo, useState } from "react";
import { Helmet } from "react-helmet-async";
import { Col, Row } from "react-bootstrap";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import { Controller, useForm } from "react-hook-form";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { modelValidationSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import ColorRepository from "../../../../../repositories/ColorRepository";
import MaterialRepository from "../../../../../repositories/MaterialRepository";
import CategoryRepository from "../../../../../repositories/CategoryRepository";
import _ from "lodash";
import {
  formatFormData,
  formatSetValueForm,
} from "../../../../../vodea/utilities/helpers/form";
import CountryRepository from "../../../../../repositories/CountryRepository";
import ProductRepository from "../../../../../repositories/ProductRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../../../vodea/utilities/helpers/global";
import NumberFormat from "react-number-format";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import UnitRepository from "../../../../../repositories/UnitRepository";
import { useTranslation } from "react-i18next";
import { IProduct } from "../../../../../interfaces/Product";

const informationBaseModel = {
  name: "",
  sku: "",
  description: "",
  other_description: "",
  dimension: "",
  unit_id: null,
  weight: 0,
  category_id: null,
  sub_category_id: null,
  country_id: null,
  brand: "",
  has_sub_category: false,
  price: 0,
  color_ids: [],
  material_ids: [],
};

const SettingProductForm: React.FC<any> = () => {
  const navigate = useNavigate();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [categoryChildOptions, setCategoryChildOptions] = useState<any[]>([]);
  const {
    register: informationRegister,
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
    control: informationControl,
    watch: informationWatch,
  } = useForm<IProduct>({
    resolver: yupResolver(modelValidationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const watchCategory = informationWatch("category_id");

  const onSubmit = async (data: any) => {
    if (isMounted.current) setLoading(true);
    const formData = formatFormData(data);

    if (formData.sub_category_id) {
      formData.category_id = formData.sub_category_id;
    }

    delete formData.sub_category_id;
    delete formData.has_sub_category;

    if (id) {
      await ProductRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    } else {
      await ProductRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(response.data.message, "success");
            navigate("/setting/product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            setLoading(false);
            showToast(error?.response?.data.message, "error");
          }
        });
    }
  };

  const loadData = useCallback(async () => {
    if (!id) {
      return;
    }

    let params: any = {};
    Object.assign(params, {
      with: ["colors", "materials", "category.parent", "country", "unit"],
    });

    await ProductRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const data = response.data.data;
        const formattedData = formatSetValueForm(informationBaseModel, data);
        if (isMounted.current) {
          _.forEach(formattedData, (value, name: any) => {
            if (name === "category_id") {
              if (formattedData.category_id.parent) {
                informationSetValue(name, formattedData.category_id.parent);
                if (formattedData.category_id) {
                  CategoryRepository.selectWithId(
                    formattedData.category_id?.parent?.id,
                    {
                      with: ["children"],
                    }
                  ).then((response: AxiosResponse) => {
                    setCategoryChildOptions(response.data.data.children);
                  });
                }
                setTimeout(() => {
                  informationSetValue(
                    "sub_category_id",
                    formattedData.category_id
                  );
                }, 200);
              } else {
                informationSetValue(name, formattedData.category_id);
              }
            } else {
              informationSetValue(name, value);
            }
          });
        }
      })
      .catch((error: AxiosError) => {
        if (error.isAxiosError)
          showToast(error?.response?.data.message, "error");
      });
  }, []);

  useMemo(() => {
    (async () => {
      await loadData();
    })();
  }, [id]);

  return (
    <div className={"page-layout"}>
      <Helmet>
        <title>
          {id
            ? t("setting.section.masterData.product.order.edit.metaTitle")
            : t("setting.section.masterData.product.order.create.metaTitle")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/setting/product")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title"}>
          {id
            ? t("setting.section.masterData.product.order.edit.title")
            : t("setting.section.masterData.product.order.create.title")}
        </h4>
      </div>

      <form onSubmit={informationHandleSubmit(onSubmit)}>
        <div className={"page-content"}>
          <Row>
            <Col md={8} lg={8} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-header"}>
                  <p className={"card-header-title"}>
                    {t("helper.global.productInformation")}
                  </p>
                </div>
                <div className={"card-content"}>
                  <Row>
                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.sku.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("sku")}
                        />
                        {informationErrors.sku && (
                          <span className={"text-danger"}>
                            {informationErrors.sku?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={6} lg={6} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.name.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("name")}
                        />
                        {informationErrors.name && (
                          <span className={"text-danger"}>
                            {informationErrors.name?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.price.label")}
                        </div>
                        <Controller
                          name={"price"}
                          control={informationControl}
                          render={({ field }) => (
                            <NumberFormat
                              className="form-control"
                              value={field.value}
                              allowNegative={false}
                              thousandSeparator={","}
                              decimalSeparator={"."}
                              decimalScale={3}
                              placeholder={"0"}
                              onValueChange={({ value }) => {
                                field.onChange(value);
                              }}
                            />
                          )}
                        />
                        {informationErrors.price && (
                          <span className={"text-danger"}>
                            {informationErrors.price?.message}
                          </span>
                        )}
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.description.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("description")}
                        />
                      </div>
                    </Col>

                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.otherDescription.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("other_description")}
                        />
                      </div>
                    </Col>

                    <Col md={4} lg={4} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.size.label")}
                        </div>
                        <input
                          type={"text"}
                          className={"form-control"}
                          {...informationRegister("dimension")}
                        />
                      </div>
                    </Col>

                    <Col md={4} lg={4} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.unit.label")}
                        </div>
                        <Controller
                          name={"unit_id"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                selectRepository={UnitRepository}
                                defaultValue={field.value}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                        {informationErrors.unit_id && (
                          <span className={"text-danger"}>
                            {informationErrors.unit_id?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                    <Col md={4} lg={4} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.weightGr.label")}
                        </div>
                        <Controller
                          render={({ field }) => {
                            return (
                              <NumberFormat
                                defaultValue={field.value}
                                value={field.value}
                                onValueChange={(value) => {
                                  field.onChange(value.floatValue);
                                }}
                                thousandSeparator={"."}
                                decimalSeparator={","}
                                className={"form-control"}
                              />
                            );
                          }}
                          name={"weight"}
                          control={informationControl}
                        />
                      </div>
                    </Col>
                    <Col md={12} lg={12} xs={12}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.madeIn.label")}
                        </div>
                        <Controller
                          name={"country_id"}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                selectRepository={CountryRepository}
                                defaultValue={field.value}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                        {informationErrors.country_id && (
                          <span className={"text-danger"}>
                            {informationErrors.country_id?.message}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>

            <Col md={4} lg={4} xs={12}>
              <div className={"card-paper"}>
                <div className={"card-header"}>
                  <p className={"card-header-title"}>
                    {t("helper.global.group")}
                  </p>
                </div>
                <div className={"card-content"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.category.label")}
                    </div>
                    <Controller
                      name={"category_id"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={CategoryRepository}
                            selectParams={{
                              with: ["children"],
                              type: "category",
                            }}
                            defaultValue={field.value}
                            onChange={(value) => {
                              informationSetValue("has_sub_category", false);
                              informationSetValue("sub_category_id", null);
                              if (value.has_sub_category) {
                                setCategoryChildOptions(value.children);
                                informationSetValue("has_sub_category", true);
                              }
                              field.onChange(value);
                            }}
                          />
                        );
                      }}
                    />
                    {informationErrors.category_id && (
                      <span className={"text-danger"}>
                        {informationErrors.category_id?.message}
                      </span>
                    )}
                  </div>

                  {_.get(watchCategory, "has_sub_category") && (
                    <div className={"form-group"}>
                      <div className={"form-label"}>
                        {t("form.subCategory.label")}
                      </div>
                      <Controller
                        name={"sub_category_id"}
                        control={informationControl}
                        render={({ field }) => {
                          return (
                            <VuiSelect
                              options={categoryChildOptions}
                              defaultValue={field.value}
                              onChange={field.onChange}
                              useAsync
                            />
                          );
                        }}
                      />
                      {informationErrors.sub_category_id && (
                        <span className={"text-danger"}>
                          {informationErrors.sub_category_id?.message}
                        </span>
                      )}
                    </div>
                  )}

                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.color.label")}</div>
                    <Controller
                      name={"color_ids"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={ColorRepository}
                            defaultValue={field.value}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                    {informationErrors.color_ids && (
                      <span className={"text-danger"}>
                        {informationErrors.color_ids?.message}
                      </span>
                    )}
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>
                      {t("form.material.label")}
                    </div>
                    <Controller
                      name={"material_ids"}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={MaterialRepository}
                            defaultValue={field.value}
                            onChange={field.onChange}
                            isMulti
                          />
                        );
                      }}
                    />
                    {informationErrors.material_ids && (
                      <span className={"text-danger"}>
                        {informationErrors.material_ids?.message}
                      </span>
                    )}
                  </div>
                  <div className={"form-group"}>
                    <div className={"form-label"}>Brand</div>
                    <input
                      type={"text"}
                      className={"form-control"}
                      {...informationRegister("brand")}
                    />
                    {informationErrors.brand && (
                      <span className={"text-danger"}>
                        {informationErrors.brand?.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
          </Row>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createProductWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/setting/product")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <VuiButton
                    forSubmit
                    label={id ? t("button.save") : t("button.create")}
                    loading={loading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SettingProductForm;
