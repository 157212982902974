import React from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { VuiButton } from "../../vodea/@vodea-ui/components/VuiButton";
import { useTranslation } from "react-i18next";

interface IModal {
  itemName?: string;
  onClose: any;
  open: boolean;
  callback?: () => void;
  isLoading?: boolean;
  subtitle?: string | null;
}

const ModalDeleteItem: React.FC<IModal> = ({
  itemName = "",
  onClose,
  open = false,
  callback,
  isLoading = false,
  subtitle = null,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="modal-delete-wrapper"
      show={open}
      onHide={onClose}
      centered
    >
      <Modal.Body className="d-block">
        <div className="modal-delete-header">
          <div className="modal-delete-header-title">
            {t("modal.delete.title")}
          </div>
          <div className="close-icon-wrapper" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} className="close-icon" />
          </div>
        </div>
        <div className="modal-delete-body">
          <div className="description">
            {`${t("modal.delete.subtitle.label")} ${itemName}${t(
              "modal.delete.subtitle.this"
            )}?`}
          </div>
          {subtitle && <div className={"subtitle"}>{subtitle}</div>}
        </div>
        <div className="modal-delete-footer">
          <button className="btn btn-sm mr-4" onClick={onClose}>
            {t("button.cancel")}
          </button>
          <VuiButton
            label={t("button.delete")}
            onClick={callback}
            loading={isLoading}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalDeleteItem;
