import { Helmet } from "react-helmet-async";
import { HeaderAuth } from "../../../layouts/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { validationSchema } from "./validation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import AuthRepository from "../../../repositories/AuthRepository";
import { AxiosError, AxiosResponse } from "axios";
import { showToast } from "../../../vodea/utilities/helpers/global";
import { useNavigate } from "react-router-dom";
import { IResetPassword } from "../../../interfaces/ResetPassword";

const ResetPassword: React.FC<any> = () => {
  const url = new URL(window.location.href);
  const { t } = useTranslation();
  const token = url.searchParams.get("token");
  const email = url.searchParams.get("email");
  const type = url.searchParams.get("type");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IResetPassword>({
    resolver: yupResolver(validationSchema(t)),
  });

  const onSubmit = handleSubmit((data) => {
    if (isMounted.current) setLoading(true);
    const formData = {
      email,
      token,
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    if (type === "create") {
      AuthRepository.createPassword(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            showToast(response.data.message, "success");
            setLoading(false);
            navigate("/auth/login");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            showToast(error.message, "error");
            setLoading(false);
          }
        });
    } else {
      AuthRepository.resetPassword(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            showToast(response.data.message, "success");
            setLoading(false);
            navigate("/auth/login");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            showToast(error.message, "error");
            setLoading(false);
          }
        });
    }
  });

  return (
    <>
      <Helmet>
        <title>{t("resetPassword.metaTitle")}</title>
      </Helmet>

      <HeaderAuth title={t("resetPassword.title")} />
      <form className={"form-wrapper"} onSubmit={onSubmit}>
        <div
          className={clsx({
            "form-group": true,
            error: errors.password,
          })}
        >
          <label className={"form-label"}>{t("form.password.label")}</label>
          <input
            type={show ? "text" : "password"}
            className="form-control"
            {...register("password")}
            placeholder={t("form.password.placeholder")}
          />
          <FontAwesomeIcon
            icon={show ? faEye : faEyeSlash}
            className={"form-icon"}
            onClick={() => setShow(!show)}
          />
          <label className={"label-help"}>{errors.password?.message}</label>
        </div>
        <div
          className={clsx({
            "form-group": true,
            error: errors.password_confirmation,
          })}
        >
          <label className={"form-label"}>
            {t("form.passwordConfirmation.label")}
          </label>
          <input
            type={show ? "text" : "password"}
            className="form-control"
            {...register("password_confirmation")}
            placeholder={t("form.passwordConfirmation.placeholder")}
          />
          <FontAwesomeIcon
            icon={show ? faEye : faEyeSlash}
            className={"form-icon"}
            onClick={() => setShow(!show)}
          />
          <label className={"label-help"}>
            {errors.password_confirmation?.message}
          </label>
        </div>

        <VuiButton
          label={t("button.send")}
          loading={loading}
          forSubmit={true}
        />
      </form>
      <div className={"copyright"}>© 2021 PT Laksmana Mulia Sentosa</div>
    </>
  );
};

export default ResetPassword;
