/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosError, AxiosResponse } from "axios";
import _ from "lodash";
import { showToast } from "../../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../../vodea/utilities/hooks/useIsMounted";
import moment from "moment";
import VuiNumberFormat from "../../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import EditIcon from "../../../../../../assets/icon/icons8-edit.svg";
import VuiCardIcon from "../../../../../../vodea/@vodea-ui/components/VuiCardIcon";
import GoodReceiptRepository from "../../../../../../repositories/GoodReceiptRepository";
import ModalImage from "../../../../../../components/ModalImage";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../../stores";

const StockReceiveProductOrderDetail: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const data = useState<any[]>([]);
  const tableData = useState<any[]>([]);
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [imageURL, setImageURL] = React.useState<string>("");
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });

  const summaryData = useState({
    totalItems: 0,
    totalQuantity: 0,
  });

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const params = {
      with: [
        "brand.logo",
        "order",
        "goodsReceiptDetails.product.unit",
        "goodsReceiptDetails.photo",
        "goodsReceiptDetails.unit",
      ],
    };
    await GoodReceiptRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          data.set(responseData);
          tableData.set(responseData.goods_receipt_details);
          summaryData.batch((s) => {
            s.totalItems.set(responseData.total_items);
            s.totalQuantity.set(responseData.total_quantity);
          });
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const openImage = (url: string) => {
    setImageURL(url);
    setShowImage(true);
  };

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  return (
    <div className={"page-layout"}>
      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <Helmet>
        <title>{t("stock.goodsReceipt.receipt")}</title>
      </Helmet>

      <div className={"page-header type-form mb-4"}>
        <div
          className={"icon-wrapper icon-back"}
          onClick={() => navigate("/stock/receive-product")}
        >
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("stock.goodsReceipt.receipt")
              : t("stock.goodsReceipt.create.title")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("helper.global.productOrder")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{_.get(data.get(), "number")}</span>}
        </h4>

        <div className={"ml-auto d-flex align-items-center"}>
          {user.permissions.includes("goods-receipt.update") && (
            <Link to={"edit"}>
              <VuiCardIcon icon={EditIcon} primary={false} />
            </Link>
          )}
        </div>
      </div>

      <div className="page-content">
        <div className={"card-paper"}>
          <div className={"card-content"}>
            <div className={"default-filter-section align-items-unset"}>
              <div className={"filter-item type-input-select mb-unset"}>
                <div className={"form-group"}>
                  <div className={"form-label"}>{t("form.brand.label")}</div>
                  <div className={"display-flex align-items-center"}>
                    {_.get(data.get(), "brand.logo.url") && (
                      <img
                        className={"image-wrap mr-2 image-hover-cursor"}
                        onClick={() =>
                          openImage(_.get(data.get(), "brand.logo.url"))
                        }
                        src={_.get(data.get(), "brand.logo.url")}
                        alt={_.get(data.get(), "brand.logo.name")}
                      />
                    )}
                    <strong>{_.get(data.get(), "brand.name")}</strong>
                  </div>
                </div>
              </div>

              <div className={"filter-item type-half-full mb-unset"}>
                <Row>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.receiptNumber.label")}
                      </label>
                      <div>
                        <strong>{_.get(data.get(), "number")}</strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} sm={12}>
                    <div className={"form-group"}>
                      <label className={"form-label"}>
                        {t("form.receiptDate.label")}
                      </label>
                      <div>
                        <strong>
                          {moment(_.get(data.get(), "date")).format(
                            "DD MMMM YYYY"
                          )}
                        </strong>
                      </div>
                    </div>
                  </Col>
                  <Col md={6} xs={12} lg={6}>
                    <div className={"form-group"}>
                      <div className={"form-label"}>
                        {t("form.orderNumber.label")}
                      </div>
                      <div>
                        <strong>{_.get(data.get(), "order.number")}</strong>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </div>

        <div className={"card-paper mt-4"}>
          <div id={"table-custom-wrapper"}>
            <table className={"table table-custom"}>
              <thead>
                <tr>
                  <th>{t("form.img.label")}</th>
                  <th>{t("form.sku.label")}</th>
                  <th>{t("form.product.label")}</th>
                  <th>{t("form.unit.label")}</th>
                  <th>{t("form.qty.label")}</th>
                </tr>
              </thead>
              <tbody>
                {tableData.value.map((item: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td>
                        {item?.photo?.url && (
                          <img
                            className={"table-product-image image-hover-cursor"}
                            onClick={() => openImage(item?.photo?.url)}
                            src={item?.photo?.url}
                            alt={item?.photo?.name}
                          />
                        )}
                      </td>
                      <td>{item?.product?.sku}</td>
                      <td>{item?.product?.name}</td>
                      <td>{item?.product?.unit?.name}</td>
                      <td>
                        <VuiNumberFormat
                          hasPrefix={false}
                          data={item.quantity}
                          value={item.quantity}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className={"card-content pr-12 mt-4"}>
            <Row>
              <Col md={9} lg={9} xs={12} />
              <Col md={3} lg={3} xs={12}>
                <table className="table-summary d-flex justify-content-around">
                  <tbody>
                    <tr>
                      <td>{t("helper.global.totalItem")}</td>
                      <td>
                        <VuiNumberFormat
                          value={summaryData.totalItems.get()}
                          data={summaryData.totalItems.get()}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>{t("helper.global.totalQuantity")}</td>
                      <td>
                        <VuiNumberFormat
                          value={summaryData.totalQuantity.get()}
                          data={summaryData.totalQuantity.get()}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StockReceiveProductOrderDetail;
