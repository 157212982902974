import React from "react";
import NumberFormat from "react-number-format";

interface INumber {
  data: string | number;
  value?: string | number;
  suffix?: string;
  prefix?: string;
  className?: string;
  decimalScale?: number;
  hasPrefix?: boolean;
  displayType?: any;
  onValueChange?: any;
  prefixBold?: boolean;
}

//todo: kalau sempat hapus defaultValue

const VuiNumberFormat: React.FC<INumber> = ({
  displayType = "text",
  decimalScale = 0,
  className,
  data,
  hasPrefix = true,
  prefix,
  suffix,
  onValueChange,
  value,
  prefixBold = false,
}) => {
  const defaultValue = typeof data === "number" ? data : parseFloat(data);
  return (
    <div className={className}>
      {hasPrefix &&
        (prefixBold ? (
          <strong className={"mr-4"}>{prefix || ""}</strong>
        ) : (
          <span className={"mr-4"}>{prefix || ""}</span>
        ))}
      <NumberFormat
        value={value}
        defaultValue={defaultValue}
        displayType={displayType}
        thousandSeparator={","}
        decimalSeparator={"."}
        decimalScale={decimalScale}
        onValueChange={onValueChange}
      />
      {suffix || ""}
    </div>
  );
};

export default VuiNumberFormat;
