/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { useState } from "@hookstate/core/dist";
import { Col, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { VuiButton } from "../../../../../vodea/@vodea-ui/components/VuiButton";
import { formatFormData } from "../../../../../vodea/utilities/helpers/form";
import { AxiosError, AxiosResponse } from "axios";
import {
  mapHookErrors,
  showToast,
} from "../../../../../vodea/utilities/helpers/global";
import useIsMounted from "../../../../../vodea/utilities/hooks/useIsMounted";
import VuiSelect from "../../../../../vodea/@vodea-ui/components/VuiSelect";
import moment from "moment";
import VuiDateRangePicker from "../../../../../vodea/@vodea-ui/components/VuiDateRangePicker";
import VuiNumberFormat from "../../../../../vodea/@vodea-ui/components/VuiNumberFormat";
import NumberFormat from "react-number-format";
import {
  informationBaseModel,
  InformationInputs,
  validationSchema,
} from "./validation";
import BrandRepository from "../../../../../repositories/BrandRepository";
import OrderRepository from "../../../../../repositories/OrderRepository";
import GoodReceiptRepository from "../../../../../repositories/GoodReceiptRepository";
import ModalImage from "../../../../../components/ModalImage";

const StockReceiveProductOrderForm: React.FC<any> = () => {
  const { t } = useTranslation();
  const isMounted = useIsMounted();
  const { id } = useParams();
  const navigate = useNavigate();
  const tableData = useState<any[]>([]);
  const [showImage, setShowImage] = React.useState<boolean>(false);
  const [imageURL, setImageURL] = React.useState<string>("");
  const loading = useState<boolean>(false);
  const [receiveNumber, setReceiveNumber] = React.useState<string>("");
  const [uniq, setUniq] = React.useState<number>(0);

  const {
    handleSubmit: informationHandleSubmit,
    formState: { errors: informationErrors },
    setValue: informationSetValue,
    control: informationControl,
    watch: informationWatch,
    setError: informationSetErrors,
  } = useForm<InformationInputs>({
    resolver: yupResolver(validationSchema(t)),
    mode: "onChange",
    defaultValues: informationBaseModel,
  });

  const order = informationWatch("order_id");
  const watchBrand = informationWatch("brand_id");

  useEffect(() => {
    if (order) {
      if (order?.order_details) {
        tableData.set(order.order_details);
      }
    } else {
      tableData.set([]);
    }
  }, [order]);

  useEffect(() => {
    if (id) {
      loadData();
    }
  }, [id]);

  const loadData = async () => {
    const params = {
      with: [
        "brand.logo",
        "order",
        "goodsReceiptDetails.product.unit",
        "goodsReceiptDetails.photo",
        "goodsReceiptDetails.unit",
      ],
    };
    await GoodReceiptRepository.show(id, params)
      .then((response: AxiosResponse) => {
        const responseData = response.data.data;
        if (isMounted.current) {
          informationSetValue("brand_id", responseData.brand);
          informationSetValue("order_id", responseData.order);
          informationSetValue("date", moment(responseData.date));
          setReceiveNumber(responseData.number);
          const orderDetails = responseData?.goods_receipt_details;
          const orderDetailsFormatted = orderDetails.map(
            (item: any, i: number) => {
              const details = { ...item };
              details.id = item?.order_detail_id;
              details.view_item = item?.order_detail.view_item;
              details.quantity = item?.order_detail.quantity;
              informationSetValue(`receipt_items.${i}.quantity`, item.quantity);

              return details;
            }
          );
          tableData.set(orderDetailsFormatted);
        }
      })
      .catch((error: AxiosError) => {
        if (isMounted.current) {
          showToast(error?.response?.data?.message, "error");
        }
      });
  };

  const onInformationSubmit = informationHandleSubmit(async (data) => {
    loading.set(true);

    const formData = formatFormData(data);
    const receiptItems: any[] = [];
    formData.receipt_items.forEach((item: any, index: number) => {
      receiptItems.push({
        order_detail_id: tableData[index].get()?.id,
        photo_id: tableData[index]?.get().photo?.id,
        product_id: tableData[index]?.get().product?.id,
        quantity:
          typeof item.quantity === "string"
            ? parseInt(item.quantity)
            : item.quantity,
      });
    });

    formData.receipt_items = receiptItems;

    if (id) {
      await GoodReceiptRepository.update(id, formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/stock/receive-product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    } else {
      await GoodReceiptRepository.create(formData)
        .then((response: AxiosResponse) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(response.data.message, "success");
            navigate("/stock/receive-product");
          }
        })
        .catch((error: AxiosError) => {
          if (isMounted.current) {
            loading.set(false);
            showToast(error?.response?.data.message, "error");
            if (error?.response?.data?.errors.length > 0) {
              const errors = mapHookErrors(error?.response?.data?.errors);
              Object.keys(errors).forEach((key: any) =>
                informationSetErrors(key, errors[key])
              );
            }
          }
        });
    }
  });

  const getTotalReceiptItemm = () => {
    let totalQuantity = 0;
    let totalItem = 0;
    tableData.get().map((item: any, i: number) => {
      const remaining = Number(item?.view_item?.remaining);
      const quantity = informationWatch(`receipt_items.${i}.quantity`) || 0;
      totalQuantity += Number(quantity);
      if (quantity > 0 && remaining > 0) {
        totalItem += 1;
      }
    });
    return {
      totalItem,
      totalQuantity,
    };
  };

  const openImage = (url: string) => {
    setImageURL(url);
    setShowImage(true);
  };

  const closeImage = () => {
    setShowImage(false);
    setTimeout(() => {
      setImageURL("");
    }, 300);
  };

  return (
    <div className={"page-layout"}>
      <ModalImage open={showImage} url={imageURL} onClose={closeImage} />

      <Helmet>
        <title>
          {id
            ? t("stock.goodsReceipt.edit.metaTitle")
            : t("stock.goodsReceipt.create.metaTitle")}
        </title>
      </Helmet>
      <div className={"page-header type-form mb-4"}>
        <div className={"icon-wrapper icon-back"} onClick={() => navigate(-1)}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            className={"icon icon-prefix"}
          />
        </div>

        <h4 className={"title mr-4"}>
          <span className={id ? "not-active" : ""}>
            {id
              ? t("stock.goodsReceipt.receipt")
              : t("stock.goodsReceipt.create.title")}
          </span>
          <span className={"not-active ml-2 mr-2"}>|</span>
          <span className={"not-active"}>
            {t("helper.global.productOrder")}
          </span>
          {id && <span className={"not-active ml-2 mr-2"}>|</span>}
          {id && <span>{receiveNumber}</span>}
        </h4>
      </div>

      <form onSubmit={onInformationSubmit}>
        <div className="page-content">
          <div className={"card-paper"}>
            <div className={"card-content"}>
              <div className={"default-filter-section align-items-unset"}>
                <div className={"filter-item type-input-select mb-unset"}>
                  <div className={"form-group"}>
                    <div className={"form-label"}>{t("form.brand.label")}</div>
                    <Controller
                      name={"brand_id"}
                      defaultValue={null}
                      control={informationControl}
                      render={({ field }) => {
                        return (
                          <VuiSelect
                            selectRepository={BrandRepository}
                            selectParams={{ with: ["logo"] }}
                            defaultValue={field.value}
                            onChange={(value) => {
                              field.onChange(value);
                              setUniq((prevState) => prevState + 1);
                              informationSetValue("order_id", null);
                            }}
                            clearable={true}
                          />
                        );
                      }}
                    />
                    {_.has(informationErrors, `brand_id`) && (
                      <span className={"text-danger"}>
                        {_.get(informationErrors, `brand_id.message`)}
                      </span>
                    )}
                  </div>
                </div>

                <div className={"filter-item type-half-full mb-unset"}>
                  <Row>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.receiptNumber.label")}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={id ? receiveNumber : "[Auto Generate]"}
                          disabled={true}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} sm={12}>
                      <div className={"form-group"}>
                        <label className={"form-label"}>
                          {t("form.receiptDate.label")}
                        </label>
                        <Controller
                          name={"date"}
                          defaultValue={moment()}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiDateRangePicker
                                startDate={field.value}
                                single={true}
                                onChange={field.onChange}
                              />
                            );
                          }}
                        />
                      </div>
                    </Col>
                    <Col md={6} xs={12} lg={6}>
                      <div className={"form-group"}>
                        <div className={"form-label"}>
                          {t("form.orderNumber.label")}
                        </div>
                        <Controller
                          name={"order_id"}
                          defaultValue={null}
                          control={informationControl}
                          render={({ field }) => {
                            return (
                              <VuiSelect
                                selectRepository={OrderRepository}
                                selectParams={{
                                  brand: watchBrand?.id,
                                }}
                                defaultValue={field.value}
                                onChange={field.onChange}
                                labelKey="number"
                                isDisabled={!watchBrand}
                                cacheUniq={uniq}
                              />
                            );
                          }}
                        />
                        {_.has(informationErrors, `order_id`) && (
                          <span className={"text-danger"}>
                            {_.get(informationErrors, `order_id.message`)}
                          </span>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
          <div className={"card-paper mt-4"}>
            <div id={"table-custom-wrapper"}>
              <table className={"table table-custom"}>
                <thead>
                  <tr>
                    <th>{t("form.img.label")}</th>
                    <th>{t("form.sku.label")}</th>
                    <th>{t("form.product.label")}</th>
                    <th>{t("form.unit.label")}</th>
                    <th>{t("form.order.label")}</th>
                    <th>{t("form.received.label")}</th>
                    <th>{t("form.remains.label")}</th>
                    <th style={{ width: "15vw" }}>{t("form.qty.label")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.value.map((item: any, index: number) => {
                    const quantityLimit = ({ floatValue }: any) => {
                      if (floatValue) {
                        return floatValue <= Number(item?.view_item?.remaining);
                      } else {
                        return true;
                      }
                    };

                    return (
                      <tr key={index}>
                        <td>
                          {item?.photo?.url && (
                            <img
                              className={
                                "table-product-image image-hover-cursor"
                              }
                              onClick={() => openImage(item?.photo?.url)}
                              src={item?.photo?.url}
                              alt={item?.photo?.name}
                            />
                          )}
                        </td>
                        <td>{item?.product?.sku}</td>
                        <td>{item?.product?.name}</td>
                        <td>{item?.product?.unit?.name}</td>
                        <td>
                          <VuiNumberFormat
                            hasPrefix={false}
                            data={item?.quantity}
                            value={item?.quantity}
                          />
                        </td>
                        <td>
                          <VuiNumberFormat
                            hasPrefix={false}
                            data={item?.view_item?.receipt}
                            value={item?.view_item?.receipt}
                          />
                        </td>
                        <td>
                          <VuiNumberFormat
                            hasPrefix={false}
                            data={item?.view_item?.remaining}
                            value={item?.view_item?.remaining}
                          />
                        </td>
                        <td>
                          {Number(item?.view_item?.remaining) > 0 && (
                            <Controller
                              render={({ field }) => {
                                return (
                                  <NumberFormat
                                    className={"form-control"}
                                    allowEmptyFormatting={true}
                                    allowNegative={false}
                                    thousandSeparator={","}
                                    decimalSeparator={"."}
                                    decimalScale={0}
                                    placeholder={"0"}
                                    value={field.value}
                                    defaultValue={field.value}
                                    isAllowed={quantityLimit}
                                    onValueChange={({ value }) => {
                                      field.onChange(value);
                                    }}
                                  />
                                );
                              }}
                              name={`receipt_items.${index}.quantity`}
                              control={informationControl}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            <div className={"card-content pr-12 mt-4"}>
              <Row>
                <Col md={9} lg={9} xs={12} />
                <Col md={3} lg={3} xs={12}>
                  <table className="table-summary d-flex justify-content-around">
                    <tbody>
                      <tr>
                        <td>{t("helper.global.totalItem")}</td>
                        <td>
                          <VuiNumberFormat
                            value={getTotalReceiptItemm().totalItem}
                            data={getTotalReceiptItemm().totalItem}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>{t("helper.global.totalQuantity")}</td>
                        <td>
                          <VuiNumberFormat
                            value={getTotalReceiptItemm().totalQuantity}
                            data={getTotalReceiptItemm().totalQuantity}
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </div>
          </div>

          <div className={"card-paper mt-4"}>
            <div className={"card-content type-button"}>
              <div className={"button-submit-wrapper"}>
                <div className={"label-wrapper"}>
                  <div className={"title"}>
                    {id
                      ? t("helper.global.saveUpdateWithMark")
                      : t("helper.global.createReceiptWithMark")}
                  </div>
                </div>
                <div className={"button-group-wrapper"}>
                  <div className={"button-group"}>
                    <div
                      className={"btn btn-light btn-sm"}
                      onClick={() => navigate("/stock/receive-product")}
                    >
                      {t("button.cancel")}
                    </div>
                  </div>
                  <div className={"button-group"}>
                    <VuiButton
                      forSubmit={true}
                      label={t("button.save")}
                      loading={loading.get()}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default StockReceiveProductOrderForm;
