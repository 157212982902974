/* eslint-disable react-hooks/exhaustive-deps */
import clsx from "clsx";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import {
  VuiSidebarProps,
  MenuChildrenProps,
  MenuStructureProps,
} from "./types";
import _ from "lodash";
import { useSelector } from "react-redux";
import { RootState } from "../../../../stores";
import { ReactComponent as VuiHomeIcon } from "../../../../assets/icon/icons8-home.svg";
import { ReactComponent as VuiProductIcon } from "../../../../assets/icon/icons8-product.svg";
import { ReactComponent as VuiSettingIcon } from "../../../../assets/icon/icons8-settings.svg";
import { ReactComponent as VuiShoppingBagIcon } from "../../../../assets/icon/icons8-shopping-bag.svg";
import { ReactComponent as VuiTestResultIcon } from "../../../../assets/icon/icons8-test-results.svg";
import { TFunction, useTranslation } from "react-i18next";

const compareMenuWithPermission = (sections: any, permissions: any) => {
  const response: Array<MenuStructureProps> = [];
  sections.forEach((menu: any) => {
    const menus: Array<MenuStructureProps> = [];
    if (menu.children) {
      const children: MenuChildrenProps[] = [];

      menu.children.forEach((child: any) => {
        menu.permissions = [
          ...(Array.isArray(menu.permissions)
            ? menu.permissions
            : [menu.permissions]),
          ...(Array.isArray(child.permissions)
            ? child.permissions
            : [child.permissions]),
        ];

        if (
          child.permissions === "any" ||
          (typeof child.permissions === "string" &&
            permissions.includes(child.permissions)) ||
          (Array.isArray(child.permissions) &&
            child.permissions.filter((x: string) => permissions.includes(x))
              .length)
        ) {
          children.push(child);
        }
      });

      menu.children = children;
    }

    if (menu.permissions === "any") {
      menus.push(menu);
    } else if (
      typeof menu.permissions === "string" &&
      permissions.includes(menu.permissions)
    ) {
      menus.push(menu);
    } else if (
      Array.isArray(menu.permissions) &&
      menu.permissions.filter((x: string) => permissions.includes(x)).length
    ) {
      menus.push(menu);
    }

    if (menus.length) {
      response.push(menu);
    }
  });

  return response;
};

export const generateMenuStructure = (t: TFunction) => {
  return [
    {
      name: t("helper.sidebar.dashboard"),
      link: "/dashboard",
      icon: "home",
      activeRoutes: ["dashboard"],
      permissions: "any",
    },
    {
      name: t("helper.sidebar.order"),
      link: "/order",
      icon: "order",
      activeRoutes: ["sub-order", "send-order"],
      permissions: [],
      children: [
        {
          name: t("helper.sidebar.allOrder"),
          link: "/order/sub-order",
          activeRoutes: ["sub-order"],
          permissions: "order.index",
        },
        {
          name: t("helper.sidebar.deliveryOrder"),
          link: "/order/send-order",
          activeRoutes: ["send-order"],
          permissions: "order-shipment.index",
        },
      ],
    },
    {
      name: t("helper.sidebar.stock"),
      link: "/stock",
      icon: "stock",
      activeRoutes: ["all-product", "receive-product"],
      permissions: [],
      children: [
        {
          name: t("helper.sidebar.product"),
          link: "/stock/all-product",
          activeRoutes: ["all-product"],
          permissions: "product-stock.index",
        },
        {
          name: t("helper.sidebar.goodsReceipt"),
          link: "/stock/receive-product",
          activeRoutes: ["receive-product"],
          permissions: "goods-receipt.index",
        },
      ],
    },
    {
      name: t("helper.sidebar.report"),
      link: "/report",
      icon: "report",
      activeRoutes: ["report"],
      permissions: "report.product-stock",
    },
    {
      name: t("helper.sidebar.setting"),
      link: "/setting",
      icon: "setting",
      activeRoutes: ["setting"],
      permissions: [
        "material.index",
        "category.index",
        "color.index",
        "unit.index",
        "product.index",
        "brand.index",
        "user.index",
        "role.index",
      ],
    },
  ];
};

const VuiSidebar: React.FC<VuiSidebarProps> = ({ show, onClose }) => {
  const location = useLocation();
  const pathname = _.split(location.pathname, "/");
  const { user } = useSelector((state: RootState) => {
    return state.system;
  });
  const { t } = useTranslation();
  const menuStructure: Array<MenuStructureProps> = generateMenuStructure(t);
  const sections = compareMenuWithPermission(menuStructure, user.permissions);

  const [sidebarHeaderHeight, setSidebarHeaderHeight] = useState(0);
  const [sidebarFooterHeight, setSidebarFooterHeight] = useState(0);
  const sidebarHeaderRef = useRef<HTMLHeadingElement>(null);
  const sidebarFooterRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (sidebarHeaderRef.current) {
      setSidebarHeaderHeight(sidebarHeaderRef?.current?.offsetHeight);
    }

    if (sidebarFooterRef.current) {
      setSidebarFooterHeight(sidebarFooterRef?.current?.offsetHeight);
    }
  }, [sidebarHeaderRef, sidebarFooterRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const checkActiveRoutes = (routes: Array<string>) => {
    return _.difference(routes, pathname).length !== routes.length;
  };

  return (
    <>
      <div
        className={clsx([
          "app-backdrop",
          {
            show: show,
          },
        ])}
        onClick={onClose}
      />

      <div
        className={clsx([
          "app-sidebar",
          {
            "show-mobile": show,
          },
        ])}
      >
        <div className={"sidebar-main-wrapper"}>
          <div
            className={"app-sidebar-content"}
            style={{
              height: `calc(100% - ${sidebarFooterHeight}px - ${sidebarHeaderHeight}px)`,
            }}
          >
            <ul className={"main-nav-list"}>
              {sections.map((item, key) => {
                const isActive = checkActiveRoutes(item.activeRoutes);

                return (
                  <li
                    className={clsx([
                      "nav-item",
                      {
                        opened: isActive && item.children?.length,
                      },
                    ])}
                    key={key}
                  >
                    <Link
                      to={item.link}
                      className={clsx([
                        "nav-link",
                        {
                          active: isActive && !item.children?.length,
                        },
                      ])}
                    >
                      <span className={"icon-wrapper"}>
                        {item.icon === "home" && <VuiHomeIcon />}
                        {item.icon === "setting" && <VuiSettingIcon />}
                        {item.icon === "order" && <VuiShoppingBagIcon />}
                        {item.icon === "stock" && <VuiProductIcon />}
                        {item.icon === "report" && <VuiTestResultIcon />}
                      </span>
                      <span className={"nav-text"}>{item.name}</span>
                    </Link>

                    {item.children?.length ? (
                      <div className={"nav-dropdown"}>
                        <ul className={"nav-dropdown-list"}>
                          {item.children.map((child, childKey) => {
                            return (
                              <li
                                className={"nav-dropdown-item"}
                                key={childKey}
                              >
                                <Link
                                  to={child.link}
                                  className={clsx([
                                    "nav-dropdown-link",
                                    {
                                      active: checkActiveRoutes(
                                        child.activeRoutes
                                      ),
                                    },
                                  ])}
                                >
                                  <span className={"nav-text"}>
                                    {child.name}
                                  </span>
                                  {child.badge && (
                                    <label className={"nav-badge"}>
                                      {child.badge}
                                    </label>
                                  )}
                                </Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={"app-sidebar-footer"}>
            © 2021 PT Laksmana Mulia Sentosa
          </div>
        </div>
      </div>
    </>
  );
};

export default VuiSidebar;
