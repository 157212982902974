import BootstrapTable, {
  ColumnDescription,
  TableChangeState,
  TableChangeType,
} from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory from "react-bootstrap-table2-filter";
import React from "react";
import clsx from "clsx";

// @ts-ignore
import overlayFactory from "react-bootstrap-table2-overlay";
import { useTranslation } from "react-i18next";

interface VuiDataTableProps {
  keyField?: string;
  loading?: boolean;
  columns: ColumnDescription[];
  data: Array<any>;
  page?: number;
  sizePerPage?: number;
  totalSize?: number;
  className?: string;
  onTableChange?: (
    type: TableChangeType,
    newState: TableChangeState<any>
  ) => void;
  headerClass?: string;
}

export const NoDataIndication = () => {
  const { t } = useTranslation();
  return <div className="table-indication-section">{t("table.noData")}</div>;
};

export const LoadingIndication = () => (
  <div className="table-loading-section">
    <div className="spinner-border text-secondary" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  </div>
);

export const CustomTotal = (from: any, to: any, size: any) => {
  const { t } = useTranslation();
  return (
    <span className="react-bootstrap-table-pagination-total">
      {`${t("table.pagination.showingRows")} ${from} ${t(
        "table.pagination.to"
      )} ${to} ${t("table.pagination.of")} ${size}`}
    </span>
  );
};

const VuiDataTable: React.FC<VuiDataTableProps> = ({
  keyField = "id",
  loading,
  columns,
  data,
  page,
  sizePerPage = 10,
  className = "",
  totalSize,
  onTableChange,
  headerClass = "table-header",
}) => {
  return (
    <>
      <BootstrapTable
        wrapperClasses={"table-responsive"}
        classes={className}
        keyField={keyField}
        remote
        data={data}
        columns={columns}
        overlay={overlayFactory()}
        filter={filterFactory()}
        bordered={false}
        onTableChange={onTableChange}
        bodyClasses={clsx({
          loading: loading,
        })}
        pagination={paginationFactory({
          sizePerPage,
          page,
          totalSize,
          paginationTotalRenderer: CustomTotal,
          showTotal: true,
        })}
        noDataIndication={() =>
          loading ? <LoadingIndication /> : <NoDataIndication />
        }
        headerClasses={headerClass}
      />
    </>
  );
};

export default VuiDataTable;
