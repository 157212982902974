import { handleResponseError, showToast } from "../helpers/global";
import { AxiosError, AxiosResponse } from "axios";

export const useDeleteResource = (repository: any) => {
  const handleDelete = (id: string | number) => {
    return new Promise((resolve, reject) => {
      repository
        .delete(id)
        .then((response: AxiosResponse) => {
          showToast(
            response.data.message,
            response.data?.success ? "success" : "error"
          );
          resolve(response);
        })
        .catch((error: AxiosError) => {
          handleResponseError(error);
          reject(error);
        });
    });
  };

  return { handleDelete };
};
