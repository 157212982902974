import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { HeaderAuth } from "../../../layouts/Auth";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import clsx from "clsx";
import { validationSchema } from "./validation";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VuiButton } from "../../../vodea/@vodea-ui/components/VuiButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { usePassportService } from "../../../vodea/utilities/hooks";
import useIsMounted from "../../../vodea/utilities/hooks/useIsMounted";
import {
  mapHookErrors,
  showToast,
} from "../../../vodea/utilities/helpers/global";
import { ILogin } from "../../../interfaces/Login";

const Login: React.FC<any> = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { login } = usePassportService(true);
  const isMounted = useIsMounted();
  const [show, setShow] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<ILogin>({
    resolver: yupResolver(validationSchema(t)),
  });

  const isUnileverEmail = (email: string): boolean => {
    const pattern = /\b[A-Za-z0-9._%+-]+@unilever\.com\b/;
    return pattern.test(email);
  }

  const onSubmit = handleSubmit((data) => {
    setLoading(true);

    login(data.username, data.password).catch((e) => {
      setLoading(false);

      if (isMounted.current && e?.response?.data?.error) {
        if (isUnileverEmail(data.username)) {
          showToast(t("helper.global.loginFailedSpecificEmail"), "error");
        } else  {
          const errors = mapHookErrors(e.response.data.error);
          Object.keys(errors).forEach((key: any) => setError(key, errors[key]));

          showToast(t("login.response.error"), "error");
        }
      }
    });
  });

  return (
    <>
      <Helmet>
        <title>{t("login.metaTitle")}</title>
      </Helmet>
      <HeaderAuth title={t("login.title")} />
      <form className={"form-wrapper"} onSubmit={onSubmit}>
        <div
          className={clsx({
            "form-group": true,
            error: errors.username,
          })}
        >
          <label className={"form-label"}>{t("form.email.label")}</label>
          <input
            type="username"
            className="form-control"
            {...register("username")}
            placeholder={t("form.email.placeholder")}
          />
          <label className={"label-help"}>{errors.username?.message}</label>
        </div>
        <div
          className={clsx({
            "form-group": true,
            error: errors.password,
          })}
        >
          <label className={"form-label"}>{t("form.password.label")}</label>
          <input
            type={show ? "text" : "password"}
            className="form-control"
            {...register("password")}
            placeholder={t("form.password.placeholder")}
          />
          <FontAwesomeIcon
            icon={show ? faEye : faEyeSlash}
            className={"form-icon"}
            onClick={() => setShow(!show)}
          />
          <label className={"label-help"}>{errors.password?.message}</label>
        </div>

        <div className={"d-flex align-items-center justify-content-between"}>
          <Link to={"/auth/forgot-password"}>{t("login.forgotPassword")}</Link>
          <VuiButton
            label={t("button.login")}
            loading={loading}
            forSubmit={true}
          />
        </div>
      </form>
      <div className={"copyright"}>© 2021 PT Laksmana Mulia Sentosa</div>
    </>
  );
};

export default Login;
