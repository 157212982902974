import * as yup from "yup";
import { TFunction } from "react-i18next";

export type InformationInputs = {
  name: string;
};

export const informationBaseModel: InformationInputs = {
  name: "",
};

export const validationSchema = (t: TFunction) => {
  let shapes = {
    name: yup.string().required().label(t("form.name.label")),
  };
  return yup.object().shape(shapes);
};
