import { api, createCancelTokenHandler } from "../vodea/services";

const endPoint = () => window._env_.REACT_APP_PASSPORT_END_POINT;

const Repository = {
  resetPassword: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/reset-password`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.resetPassword.name
        ].handleRequestCancellation().token,
    });
  },
  createPassword: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/create-password`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.createPassword.name
        ].handleRequestCancellation().token,
    });
  },
  forgotPassword: function (payload: any, params: any = null) {
    return api.post(`${endPoint()}/api/forgot-password`, payload, {
      params,
      cancelToken:
        cancelTokenHandlerObject[
          this.forgotPassword.name
        ].handleRequestCancellation().token,
    });
  },
};

export default Repository;

const cancelTokenHandlerObject = createCancelTokenHandler(Repository);
