import React, { useCallback, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useIsMounted from "../../../utilities/hooks/useIsMounted";
import _ from "lodash";
import { IVuiSearch } from "../../../../interfaces/VuiSearch";
import { useTranslation } from "react-i18next";

const VuiSearch: React.FC<IVuiSearch> = ({
  value = "",
  callback = null,
  placeholder = "form.search.placeholder",
  delay = 300,
}) => {
  const [inputValue, setInputValue] = useState<string>(value);
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const handleChange = useCallback(
    _.debounce((event: React.ChangeEvent<HTMLInputElement>) => {
      if (isMounted.current) {
        setInputValue(event.target.value);
        if (callback) {
          callback(event.target.value);
        }
      }
    }, delay),
    []
  );

  return (
    <div className="input-group prefix-group">
      <span className="input-group-text">
        <FontAwesomeIcon className={"icon icon-prefix"} icon={faSearch} />
      </span>
      <input
        defaultValue={inputValue}
        type="text"
        className="form-control"
        placeholder={t(placeholder)}
        onChange={handleChange}
      />
    </div>
  );
};

export default React.memo(VuiSearch);
